import {
  CardContent,
  Grid,
  Typography,
  TableHead,
  Chip,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  AdminTitleHolder,
  CustomDocumentLink,
} from "../../../common/sharedComponents/styles/card.style";
import { getLotDetails } from "../redux/userLotsSlice";
import { styled as materialStyles } from "@mui/material/styles";
import Card from "@mui/material/Card";
import styled from "styled-components";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import InfoIcon from "@mui/icons-material/Info";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import UpdateIcon from "@mui/icons-material/Update";
import moment from "moment";

function UserLotsViewContent() {
  const { lot_details } = useSelector((state) => state.userLots);
  const params = useParams();
  const dispatch = useDispatch();

  const lot_id = params.lotid;
  const viewLotDetails = (lot_id) => {
    dispatch(getLotDetails(lot_id));
  };

  useEffect(() => {
    viewLotDetails(lot_id);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            Lot#
            {lot_details.lot_number ? lot_details.lot_number : "Not Applicable"}
          </Grid>
          <Grid
            item
            xs={6}
            style={{ justifyContent: "flex-end", display: "flex" }}
          >
            <Tooltip title="Update Lot Status">
              <Link
                style={{ textDecoration: "none" }}
                // eslint-disable-next-line
                to={`/app/user-lots-list-edit/${lot_id}`}
              >
                <UpdateIcon
                  style={{
                    fill: "royalblue",
                    height: "35px",
                    width: "35px",
                    marginRight: "15px",
                  }}
                />
              </Link>
            </Tooltip>
          </Grid>
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">LOT NUMBER</Typography>
              <VendorCardLabel>
                {lot_details.lot_number
                  ? lot_details.lot_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">EXPECTED ARRIVAL DATE</Typography>
              <VendorCardLabel>
                {lot_details.expected_arrival_date
                  ? moment(lot_details.expected_arrival_date).format(
                      "DD MMM YY"
                    )
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">CARRIER NAME</Typography>
              <VendorCardLabel>
                {lot_details.carrier_name
                  ? lot_details.carrier_name
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">TRACKING NUMBER</Typography>
              <VendorCardLabel>
                {lot_details.tracking_number
                  ? lot_details.tracking_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">STATUS</Typography>
              <VendorCardLabel>
                {lot_details.lot_status ? (
                  <Chip
                    label={
                      lot_details.lot_status
                        ? lot_details.lot_status.toUpperCase()
                        : "Not Applicable"
                    }
                    color={
                      lot_details.lot_status === "Pending"
                        ? "warning"
                        : "success"
                    }
                    size="small"
                    style={{ fontSize: "9px" }}
                  />
                ) : (
                  "Not Applicable"
                )}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <br />
          <VendorCardTitle>Items in Lot</VendorCardTitle>
          <CustomTableContainer component={Card}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#e1ebfa",
                  }}
                >
                  <TableCell width="10%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Sr. No.</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Item Name</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Packaging Type</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Quantity</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Unit Price</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Total Price</TableHeaderFont>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lot_details?.item_list ? (
                  lot_details.item_list.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          width="10%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.item_name !== null
                            ? row.item_name
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.packaging_type
                            ? row.packaging_type
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.quantity !== null
                            ? row.quantity
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.unit_price !== null
                            ? row.unit_price
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {parseFloat(row.quantity * row.unit_price).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>
          <br />
          <br />
          <VendorCardTitle>Documents</VendorCardTitle>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Lot Document</Typography>
              <VendorCardLabel>
                <CustomDocumentLink
                  href={lot_details?.lot_document}
                  target="_blank"
                >
                  <InsertDriveFileOutlinedIcon
                    style={{
                      marginTop: "-1px",
                      marginRight: "3px",
                      height: "12px",
                      width: "12px",
                    }}
                  />
                  Lot document
                </CustomDocumentLink>
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Supporting Documents</Typography>
              {lot_details?.supporting_document !== undefined &&
              lot_details?.supporting_document !== null &&
              lot_details?.supporting_document.length > 0 ? (
                lot_details?.supporting_document.map((item, index) => (
                  <VendorCardLabel key={index}>
                    <CustomDocumentLink href={item} target="_blank">
                      <InsertDriveFileOutlinedIcon
                        style={{
                          marginTop: "-1px",
                          marginRight: "3px",
                          height: "12px",
                          width: "12px",
                        }}
                      />
                      Supporting document
                    </CustomDocumentLink>
                  </VendorCardLabel>
                ))
              ) : (
                <Typography className="label">
                  <InfoIcon
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />{" "}
                  There are no supporting documents
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    </>
  );
}

export default UserLotsViewContent;

export const StyledCard = materialStyles(Card)`
      box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
      flex-direction: column;
      border: none;
      width: 100%;
      height: auto;
      border-radius: 10px;
      margin-bottom: 1em;
      `;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;
