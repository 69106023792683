import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  get_asns,
  get_asn_order,
  grns,
  get_grns,
  view_AdminGrnDetails,
  add_excel_data,
} from "./adminGrnsAPI";

const initialState = {
  loading: false,
  asns: [],
  asnorder: [],
  grnsList: [],
  adminGrnDetails: [],
};
const actions = {
  GETASNS: "getAsns/GETASNS",
  GETASNORDER: "getAsnorder/GETASNORDER",
  CREATEGRN: "createGrn/CREATEGRN",
  GETGRNS: "getGrns/GETGRNS",
  ADDEXCEL: "addExcel/ADDEXCEL",
  VIEWADMINGRNDETAILS: "getAdminGrnDetails/VIEWADMINGRNDETAILS",
};

export const addExcelData = createAsyncThunk(
  actions.ADDEXCEL,
  async (payload) => {
    const response = await add_excel_data(payload);
    return response;
  }
);
export const getAsns = createAsyncThunk(actions.GETASNS, async (payload) => {
  const response = await get_asns(payload);
  return response;
});

export const getAsnorder = createAsyncThunk(
  actions.GETASNORDER,
  async (payload) => {
    const response = await get_asn_order(payload);
    return response;
  }
);

export const createGrn = createAsyncThunk(
  actions.CREATEGRN,
  async (payload) => {
    const response = await grns(payload);
    return response;
  }
);

export const getAdminGrnDetails = createAsyncThunk(
  actions.VIEWADMINGRNDETAILS,
  async (payload) => {
    const response = await view_AdminGrnDetails(payload);
    return response;
  }
);

export const getGrns = createAsyncThunk(actions.GETGRNS, async (payload) => {
  const response = await get_grns(payload);
  return response;
});

export const adminGrnsSlice = createSlice({
  name: "adminGrns",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAsns.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAsns.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.asns = data;
        if (success) {
          /*   message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getAsns.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAsnorder.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAsnorder.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.asnorder = data;
      })
      .addCase(getAsnorder.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getAdminGrnDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdminGrnDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.adminGrnDetails = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getAdminGrnDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createGrn.pending, (state) => {
        state.loading = true;
      })
      .addCase(createGrn.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createGrn.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(addExcelData.pending, (state) => {
        state.loading = true;
      })
      .addCase(addExcelData.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addExcelData.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getGrns.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGrns.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.grnsList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getGrns.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const adminGrnsActions = adminGrnsSlice.actions;
export const { resetadminGrnsDetails } = adminGrnsSlice.actions;

export default adminGrnsSlice.reducer;