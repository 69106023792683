import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  get_lot_details,
  create_inspection,
  check_token_status,
} from "./qualityCheckAPI";

const initialState = {
  loading: false,
  lot_details: {},
};
const actions = {
  GETLOTDETAILS: "getLotDetails/GETLOTDETAILS",
  CREATEINSPECTION: "createInspection/CREATEINSPECTION",
  CHECKTOKENSTATUS: "checkTokenStatus/CHECKTOKENSTATUS",
};

export const getLotDetails = createAsyncThunk(
  actions.GETLOTDETAILS,
  async (payload) => {
    const response = await get_lot_details(payload);
    return response;
  }
);

export const createInspection = createAsyncThunk(
  actions.CREATEINSPECTION,
  async (payload) => {
    const response = await create_inspection(payload);
    return response;
  }
);

export const checkTokenStatus = createAsyncThunk(
  actions.CHECKTOKENSTATUS,
  async (payload) => {
    const response = await check_token_status(payload);
    return response;
  }
);

export const qualityCheckSlice = createSlice({
  name: "qualityCheck",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLotDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLotDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.lot_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getLotDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createInspection.pending, (state) => {
        state.loading = true;
      })
      .addCase(createInspection.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createInspection.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(checkTokenStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkTokenStatus.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(checkTokenStatus.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const qualityCheckActions = qualityCheckSlice.actions;
export const { resetQualityCheckDetails } = qualityCheckSlice.actions;

export default qualityCheckSlice.reducer;