import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { styled, useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PrGrantedContent from "./prGranted/prGrantedContent";
import PrPendingContent from "./prPending/prPendingContent";
import PrRevokedContent from "./prRevoked/prRevokedContent";
import { useDispatch, useSelector } from "react-redux";
import {
  getPrGrantedUsers,
  getPrPendingUsers,
  getPrRevokedUsers,
} from "../redux/userPrivilegesSlice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <CustomBox
          sx={{
            pt: 3,
            backgroundColor: "#f5f7f9",
          }}
        >
          <div>{children}</div>
        </CustomBox>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const { prGrantedList, prPendingList, prRevokedList } = useSelector(
    (state) => state.userPrivileges
  );

  const dispatch = useDispatch();

  const prGrantedListInfo = () => {
    dispatch(getPrGrantedUsers());
  };

  const prPendingListInfo = () => {
    dispatch(getPrPendingUsers());
  };

  const prRevokedListInfo = () => {
    dispatch(getPrRevokedUsers());
  };

  React.useEffect(() => {
    prGrantedListInfo();
    prPendingListInfo();
    prRevokedListInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <>
      <Box sx={{ boxShadow: "0px 20px 30px rgba(52, 173, 237, 0.2)" }}>
        <CustomTab
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Pending" {...a11yProps(0)} />
          <Tab label="Granted" {...a11yProps(1)} />
          <Tab label="Revoked" {...a11yProps(2)} />
        </CustomTab>

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <PrPendingContent prPendingList={prPendingList} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <PrGrantedContent prGrantedList={prGrantedList} />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <PrRevokedContent prRevokedList={prRevokedList} />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </>
  );
}

export const CustomBox = styled(Box)`
  background-color: #f5f7f9;
  /*   box-shadow: 0px 0px 20px rgba(52, 173, 237, 0.2); */
  border-radius: 5px;
  /* filter: drop-shadow(0px 0px 0px 20px rgba(52, 173, 237, 0.2)); */
`;

export const CustomTab = styled(Tabs)`
  background-color: #f5f7f9;
  .css-11yukd5-MuiTabs-indicator {
    background-color: #4059e5;
  }
`;
