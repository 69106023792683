import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import WorkflowTableViewContent from "./components/WorkflowTableViewContent";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import { Button, Grid, Menu, MenuItem, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { Link } from "react-router-dom";

function WorkflowTableView() {
  const [search, setSearch] = React.useState("");
  const [clicked, setClicked] = React.useState(false);
  return (
    <div>
      <TitleHolder style={{ paddingBottom: "0" }}>
        <Grid container spacing={2}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Pre-defined Workflows
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: "0.5rem",
            }}
            xs={6}
          >
            <Grid
              item
              sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
            >
              <TextField
                variant="outlined"
                size="small"
                label="Search field"
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                variant="contained"
                size="large"
                onClick={() => setClicked(true)}
              >
                <Search />
              </Button>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "2rem",
                        height: "2.5rem",
                      }}
                      {...bindTrigger(popupState)}
                    >
                      +
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem onClick={popupState.close}>
                        <Link
                          to="/app/create-workflow"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          Create Workflow
                        </Link>
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </Grid>
          </Grid>
        </Grid>
      </TitleHolder>
      <br />
      <StepperContentHolder>
        <WorkflowTableViewContent
          search={search}
          clicked={clicked}
          setClicked={setClicked}
        />
      </StepperContentHolder>
    </div>
  );
}

export default WorkflowTableView;
