import React from "react";
import FormComponent from "../../../../common/sharedComponents/Form";
import { useGetCompanyListForDropdownQuery } from "../../../../utilities/redux-api/api";

const UserAddContent = () => {
  const { data: dropdown, isLoading } = useGetCompanyListForDropdownQuery(null);
  const fields = [
    {
      name: "first_name",
      label: "First Name",
      type: "text",
      placeholder: "Enter your First Name here",
      required: true,
      requiredMessage: "First Name is required",
    },
    {
      name: "last_name",
      label: "Last Name",
      type: "text",
      placeholder: "Enter your Last Name here",
      required: true,
      requiredMessage: "Last Name is required",
    },
    {
      name: "contact_person_email",
      label: "Email",
      type: "email",
      placeholder: "Enter your Email here",
      required: true,
      requiredMessage: "Email is required",
      pattern: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
      patternMessage: "Invalid email format",
    },
    {
      name: "company",
      label: "Select Company",
      type: "dropdown",
      isMultiple: true,
      options: [
        { id: 1, company: "hello" },
        { id: 2, company: "hello 1" },
      ],
      //   options: dropdown?.data
      //     ? dropdown.data.concat(data?.company_name || [])
      //     : dropdown?.data || [],
      required: true,
      requiredMessage: "company is required",
    },
    {
      name: "roles",
      label: "Select Role",
      type: "dropdown",
      isMultiple: false,
      options: [
        { id: 1, roles: "admin" },
        { id: 2, roles: "finance" },
      ],
      //   options: dropdown?.data
      //     ? dropdown.data.concat(data?.company_name || [])
      //     : dropdown?.data || [],
      required: true,
      requiredMessage: "role is required",
    },
  ];
  return (
    <>
      <FormComponent
        initialValues={null}
        onSubmit={"onsubmit"}
        fields={fields}
        heading={"Add Details"}
        isLoading={false}
      />
    </>
  );
};

export default UserAddContent;
