import {
  Box,
  CardContent,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Card,
  TextField,
  TableBody,
  TableHead,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { useState, useEffect } from "react";
import {
  CustomButton,
  DocumentLabels,
  SelectSpan,
  StyledCard,
  StyledOutlineButton,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import {
  Super,
  VendorCardSubTitle,
} from "../../vendorRegistration/components/steps/cardTrial";
import { VendorCardTitle } from "../../vendorRegistration/components/steps/vendorReg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useForm } from "react-hook-form";
import { styled } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import { getAsns, getAsnorder, createGrn } from "../redux/adminGrnsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { history } from "../../../app/history";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const Input = styled("input")({
  display: "none",
});

function AdminGrnsContent() {
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const { asns, asnorder } = useSelector((state) => state.adminGrns);
  const [grnDoc, setGrnDoc] = useState("");
  const [newDocs, setNewDocs] = useState([1]);
  const [supportingDoc, setSupportingDoc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateOfReceipt, setDateOfReceipt] = useState(Date);
  const [date, setDate] = useState(Date);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  console.log("asns :", asns);
  console.log("asnorder :", asnorder);

  const Asndetails = () => {
    dispatch(getAsns());
  };

  useEffect(() => {
    Asndetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const itemList = asnorder[2];
    setItems(itemList ? itemList : []);
  }, [asnorder]);

  const handleQuantityChange = (index, field, value) => {
    const updItems = items.map((item, i) => {
      if (i === index) {
        return { ...item, quantity: value };
      }
      return item;
    });
    setItems(updItems);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleOnchange = (e) => {
    let asn_id = e.target.value;
    dispatch(getAsnorder(asn_id));
    const temp = asns.filter((asn) => asn.id === asn_id);
    setDate(temp[0]?.Lot?.expected_arrival_date);
  };

  const handleOnClick = () => {
    let docsCopy = [...newDocs];

    docsCopy.push({
      supporting_docs: "",
    });

    setNewDocs(docsCopy);
  };

  const handleRemove = (e, i) => {
    let uploadeddocs = [...newDocs];
    uploadeddocs.splice(i, 1);
    setNewDocs(uploadeddocs);
  };

  const handlechangeDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: e.target.files[0] };

    setSupportingDoc(newDocValues);
  };

  const handleclearDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: "" };

    setSupportingDoc(newDocValues);
  };

  const handleDateOfReceipt = (e) => {
    setDateOfReceipt(e.target.value);
  };

  const handleSave = (data) => {
    setLoading(true);
    let grn_details = [
      {
        asn_id: data.asn_id,
        vendor_id: data.vendor_id,
        grn_number: data.grn_number,
        vendor_email: data.vendor_email,
        date_of_receipt: data.date_of_receipt,
        items: items,
      },
    ];

    var formData = new FormData();
    formData.append("data", JSON.stringify(grn_details));
    formData.append("grn_document", grnDoc);
    for (let i = 0; i < newDocs.length; i++) {
      formData.append("supporting_documents", supportingDoc[i]);
    }
    dispatch(createGrn(formData)).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        history.push("/app/grns-list");
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Create New Goods Received Note
                </VendorCardTitle>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <VendorCardSubTitle style={{ marginTop: "0" }}>
                  Select the Vendor and ASN details & enter GRN details
                </VendorCardSubTitle>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>
                    ASN<Super>*</Super>
                  </VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="asn_id"
                    name="asn_id"
                    error={Boolean(errors?.asn_id)}
                    {...register("asn_id", {
                      required: "Please select ASN",
                    })}
                    onChange={(e) => handleOnchange(e)}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select ASN
                      </span>
                    </MenuItem>
                    {asns.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        <SelectSpan>{item.asn_number}</SelectSpan>
                        {/* NEED TO FIX !! */}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {errors.asn_id?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>
                    Order Number<Super>*</Super>
                  </VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="order_number"
                    name="order_number"
                    {...register("order_number", {
                      required: "Please select order number",
                    })}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select order number
                      </span>
                    </MenuItem>
                    {asnorder
                      ? asnorder.map((item, index) => (
                          <MenuItem value={item.order_number} key={index}>
                            <SelectSpan>{item.order_number}</SelectSpan>
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                  <FormHelperText error>
                    {errors.order_number?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>
                    Vendor<Super>*</Super>
                  </VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="vendor_id"
                    name="vendor_id"
                    {...register("vendor_id", {
                      required: "Please select vendor",
                    })}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select vendor
                      </span>
                    </MenuItem>
                    {asnorder
                      ? asnorder.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            <SelectSpan>{item.company_name}</SelectSpan>
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                  <FormHelperText error>
                    {errors.vendor_id?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>
                    Vendor Email<Super>*</Super>
                  </VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="vendor_email"
                    name="vendor_email"
                    {...register("vendor_email", {
                      required: "Please select vendor email",
                    })}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select vendor email
                      </span>
                    </MenuItem>
                    {asnorder
                      ? asnorder.map((item, index) => (
                          <MenuItem value={item.company_email} key={index}>
                            <SelectSpan>{item.company_email}</SelectSpan>
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                  <FormHelperText error>
                    {errors.vendor_email?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>
                    GRN Number<Super>*</Super>
                  </VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    placeholder="Enter GRN number"
                    size="small"
                    variant="standard"
                    style={{ width: "100%", paddingTop: "3px" }}
                    id="grn_number"
                    name="grn_number"
                    {...register("grn_number", {
                      required: "Please enter GRN number",
                    })}
                  />
                  <FormHelperText error>
                    {errors.grn_number?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>
                    Date of Receipt<Super>*</Super>
                  </VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    InputProps={{
                      disableUnderline: false,
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    type="date"
                    style={{
                      width: "100%",
                      paddingTop: "3px",
                      borderBottom: errors?.date_of_receipt
                        ? "0"
                        : "1px solid lightgray",
                    }}
                    name="date_of_receipt"
                    placeholder="select date of receipt"
                    {...register("date_of_receipt", {
                      required: "Please select date of receipt",
                    })}
                    value={dateOfReceipt || ""}
                    onChange={(e) => handleDateOfReceipt(e)}
                    inputProps={{
                      min: new Date(date).toISOString().split("T")[0], // Set the minimum date
                    }}
                    error={Boolean(errors?.date_of_receipt)}
                    helperText={errors?.date_of_receipt?.message}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <VendorCardTitle style={{ paddingBottom: "20px" }}>
              Items Received
            </VendorCardTitle>
            <CustomTableContainer component={Card}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "#e1ebfa",
                    }}
                  >
                    <TableCell width="10%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Action</TableHeaderFont>
                    </TableCell>
                    <TableCell width="10%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Sr. No.</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Item Name</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Quantity</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Unit Price</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Total Price</TableHeaderFont>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell width="10%">
                          <CustomButton
                            className="invoiceErrbutn"
                            color="error"
                            variant="outlined"
                            size="small"
                            onClick={() => handleRemoveItem(index)}
                            style={{ marginLeft: "3px ", textAlign: "center" }}
                          >
                            Remove
                          </CustomButton>
                        </TableCell>
                        <TableCell
                          width="10%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.item_name !== null
                            ? row.item_name
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell width="20%">
                          <input
                            type="number"
                            value={row.quantity}
                            max={row.quantity}
                            min={0}
                            onChange={(e) =>
                              handleQuantityChange(
                                index,
                                "quantity",
                                parseInt(e.target.value, 10)
                              )
                            }
                            style={{
                              border: "1px solid #ccc",
                              padding: "5px",
                              borderRadius: "5px",
                              fontFamily: "var(--font-family-reg)",
                              fontSize: "12px",
                              textAlign: "center",
                              width: "100%",
                            }}
                          />
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.unit_price !== null
                            ? row.unit_price
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {parseFloat(row.unit_price * row.quantity).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </CustomTableContainer>
            <br />
            <br />
            <VendorCardTitle style={{ paddingBottom: "20px" }}>
              Documents
            </VendorCardTitle>
            <Grid container spacing={3}>
              <>
                <Grid item xs={4}>
                  <span
                    variant="outlined"
                    component="span"
                    style={{ width: "50%" }}
                  >
                    <DocumentLabels style={{ marginTop: "10px" }}>
                      GRN Document<Super>*</Super>
                    </DocumentLabels>
                  </span>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    variant="standard"
                    style={{ width: "100%" }}
                    error={Boolean(errors?.grn_document)}
                  >
                    {grnDoc !== "" ? (
                      <>
                        <span
                          style={{
                            color: "#4945FF",
                            fontSize: "13px",
                            fontFamily: "var(--font-family-reg)",
                          }}
                        >
                          <Chip
                            style={{
                              width: "150px",
                              color: "#4945FF",
                              margin: "5px",
                              borderRadius: "5px",
                              border: "1px solid #D9D8FF",
                              background: "#F0F0FF",
                            }}
                            label={grnDoc.name}
                            value={grnDoc.name}
                            name="chips"
                          />
                          <HighlightOffIcon
                            sx={{ color: "#4945FF", paddingBottom: "2px" }}
                            fontSize="medium"
                            onClick={(e) => setGrnDoc("")}
                          />
                        </span>
                      </>
                    ) : (
                      <label htmlFor="grn_document">
                        <Input
                          {...register("grn_document", {
                            required: "Please upload GRN document",
                          })}
                          accept="*"
                          id="grn_document"
                          onChange={(e) => setGrnDoc(e.target.files[0])}
                          type="file"
                        />
                        <StyledOutlineButton
                          variant="outlined"
                          component="span"
                        >
                          Upload
                        </StyledOutlineButton>
                      </label>
                    )}
                    {grnDoc === "" ? (
                      <FormHelperText error>
                        {errors.grn_document?.message}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
              </>
            </Grid>
            {newDocs.map((item, index) => (
              <>
                <br />
                <Grid container spacing={3} key={index}>
                  <>
                    <Grid item xs={4}>
                      <span
                        variant="outlined"
                        component="span"
                        style={{ width: "50%" }}
                      >
                        <DocumentLabels style={{ marginTop: "10px" }}>
                          Supporting Document
                        </DocumentLabels>
                      </span>
                    </Grid>
                    {supportingDoc[index] !== "" &&
                    supportingDoc[index] !== undefined ? (
                      <>
                        <Grid item xs={3}>
                          <FormControl
                            variant="standard"
                            style={{ width: "100%" }}
                          >
                            <span
                              style={{
                                color: "#4945FF",
                                fontSize: "13px",
                                fontFamily: "var(--font-family-reg)",
                              }}
                            >
                              <Chip
                                style={{
                                  width: "150px",
                                  color: "#4945FF",
                                  margin: "5px",
                                  borderRadius: "5px",
                                  border: "1px solid #D9D8FF",
                                  background: "#F0F0FF",
                                }}
                                label={supportingDoc[index].name}
                                value={supportingDoc[index].name}
                                name="chips"
                              />
                              <HighlightOffIcon
                                sx={{ color: "#4945FF", paddingBottom: "2px" }}
                                fontSize="medium"
                                onClick={(e) => handleclearDoc(e, index)}
                              />
                            </span>
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          {index === 0 ? (
                            <CustomButton
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={handleOnClick}
                              className="invoiceErrbutn"
                            >
                              Add Document
                            </CustomButton>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={2}>
                          <FormControl
                            variant="standard"
                            style={{ width: "100%" }}
                          >
                            <label>
                              <Input
                                accept="*"
                                id={`supporting_doc_${index}`}
                                onChange={(e) => handlechangeDoc(e, index)}
                                type="file"
                              />
                              <StyledOutlineButton
                                variant="outlined"
                                component="span"
                              >
                                Upload
                              </StyledOutlineButton>
                            </label>
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          {index === 0 ? (
                            <CustomButton
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={handleOnClick}
                              className="invoiceErrbutn"
                              style={{ marginTop: "10px " }}
                            >
                              Add Document
                            </CustomButton>
                          ) : (
                            <CustomButton
                              className="invoiceErrbutn"
                              color="error"
                              variant="outlined"
                              size="small"
                              onClick={(e) => handleRemove(e, index)}
                              style={{ marginTop: "10px " }}
                            >
                              Remove
                            </CustomButton>
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                </Grid>
              </>
            ))}
          </CardContent>
        </StyledCard>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />

          {/* <LoadingButton
              size="small"
              color="secondary"
              onClick={handleClick}
              loading={loading}
              loadingPosition="start"
              variant="contained"
            >
              Validate
            </LoadingButton> */}
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "200px",
              height: "40px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            Upload GRN
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}

export default AdminGrnsContent;