import React from "react";
import { AdminTitleHolder } from "../../common/sharedComponents/styles/card.style";
import QualityCheckContent from "./components/QualityCheckContent";
import { Grid } from "@mui/material";

function QualityCheck() {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <div style={{ flex: "1", padding: "0 2rem", backgroundColor: "#f4f6f9" }}>
        <AdminTitleHolder>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              SupplierGenie
            </Grid>
          </Grid>
        </AdminTitleHolder>
        <QualityCheckContent />
      </div>
    </div>
  );
}

export default QualityCheck;