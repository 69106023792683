import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  lots,
  get_asns,
  get_schedule_numbers,
  get_packaging_types,
  view_UserAsnDetails,
  get_items,
} from "./lotModuleAPI";

const initialState = {
  loading: false,
  scheduleNumbers: [],
  asnsList: [],
  packagingTypes: [],
  user_asn_details: [],
  itemList: [],
};
const actions = {
  GETITEMS: "getItems/GETITEMS",
  CREATELOT: "createLot/CREATELOT",
  GETASNS: "getAsns/GETASNS",
  GETSCHEDULENUMBERS: "getSchedules/GETSCHEDULENUMBERS",
  GETPACKAGINGTYPES: "getPackagingTypes/GETPACKAGINGTYPES",
  VIEWUSERASNDETAILS: "getUserAsnDetails/VIEWUSERASNDETAILS",
};

export const createLot = createAsyncThunk(
  actions.CREATELOT,
  async (payload) => {
    const response = await lots(payload);
    return response;
  }
);

export const getUserAsnDetails = createAsyncThunk(
  actions.VIEWUSERASNDETAILS,
  async (payload) => {
    const response = await view_UserAsnDetails(payload);
    return response;
  }
);
export const getSchedules = createAsyncThunk(
  actions.GETSCHEDULENUMBERS,
  async (payload) => {
    const response = await get_schedule_numbers(payload);
    return response;
  }
);

export const getAsns = createAsyncThunk(actions.GETASNS, async (payload) => {
  const response = await get_asns(payload);
  return response;
});

export const getPackagingType = createAsyncThunk(
  actions.GETPACKAGINGTYPES,
  async (payload) => {
    const response = await get_packaging_types(payload);
    return response;
  }
);

export const getItems = createAsyncThunk(actions.GETITEMS, async (payload) => {
  const response = await get_items(payload);
  return response;
});

export const lotModuleSlice = createSlice({
  name: "lotModule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserAsnDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserAsnDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.user_asn_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getUserAsnDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createLot.pending, (state) => {
        state.loading = true;
      })
      .addCase(createLot.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createLot.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getSchedules.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSchedules.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.scheduleNumbers = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getSchedules.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAsns.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAsns.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.asnsList = data;
        console.log(data);
        if (success) {
        } else {
          message.error(msg);
        }
      })
      .addCase(getAsns.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getPackagingType.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPackagingType.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.packagingTypes = data;
      })
      .addCase(getPackagingType.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.itemList = data;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
        console.log(data);
      })
      .addCase(getItems.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const lotsModuleActions = lotModuleSlice.actions;
export const { resetLotsModuleDetails } = lotModuleSlice.actions;

export default lotModuleSlice.reducer;