import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Company } from "../../../CONSTANTS";
import { history } from "../../../app/history";
import {
  getPurchasePoints,
  subCompanyDropdown,
} from "../../company/redux/companySlice";
import {
  SelectSpan,
  StyledCard,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import { Super } from "../../vendorRegistration/components/steps/cardTrial";
import { VendorCardTitle } from "../../vendorRegistration/components/steps/vendorReg";
import {
  createWorkflow,
  getRangeList,
  getWorkflowCategoryList,
} from "../redux/userWorkflowsSlice";
import GlobalAutocomplete from "../../../common/sharedComponents/AutoComplate";

function WorkflowContentView() {
  const dispatch = useDispatch();
  const [showRange, setShowRange] = useState(false);
  const { loading, workflowCategoryList, rangeList } = useSelector(
    (state) => state.userWorkflows
  );
  const { subCompanyDropdownList, purchasePointList } = useSelector(
    (state) => state.company
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useForm();

  const companyValue = watch("com_id");
  const purchasePointValue = watch("cpp_id");

  useEffect(() => {
    if (companyValue) {
      setValue("rang_id", null); // Reset range field
      dispatch(getPurchasePoints(getValues()?.com_id?.key));
    }
    // eslint-disable-next-line
  }, [companyValue, setValue]);

  useEffect(() => {
    if (purchasePointValue) {
      setValue("rang_id", null); // Reset range field
      dispatch(getRangeList(getValues()?.cpp_id));
    }
    // eslint-disable-next-line
  }, [purchasePointValue, setValue]);

  const get_workflow_category_list = () => {
    dispatch(getWorkflowCategoryList());
    dispatch(subCompanyDropdown(undefined));
  };

  useEffect(() => {
    get_workflow_category_list();
    // eslint-disable-next-line
  }, []);

  const handleCategoryChange = (category_name) => {
    // showing range field only if PR is selected in category dropdown
    if (category_name === "Purchase Requisition") {
      setShowRange(true);
    } else {
      setShowRange(false);
    }
  };

  const handleSave = (data) => {
    console.log(data);
    let workflow_details = {
      company_id: data?.com_id?.key,
      workflow_name: data.workflow_name,
      wm_id: data.wm_id,
      rm_id: data?.rang_id?.key,
      description: data.description,
      cpp_id: data.cpp_id,
    };

    dispatch(createWorkflow(workflow_details)).then((res) => {
      if (res.payload.success) {
        history.push("/app/workflow-steps");
      }
    });
  };

  const client = JSON.parse(localStorage.getItem("client"));
  const clientId = client.id;

  console.log("watch", watch());
  console.log("watch", getValues());
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Create New Workflow
                </VendorCardTitle>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item md={6}>
                <FormControl
                  variant="standard"
                  style={{ width: "100%" }}
                  error={Boolean(errors?.wm_id)}
                >
                  <VendorCardLabel>
                    Category <Super>*</Super>
                  </VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="wm_id"
                    name="wm_id"
                    error={Boolean(errors?.wm_id)}
                    {...register("wm_id", {
                      required: "Please select workflow category",
                    })}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select Workflow category
                      </span>
                    </MenuItem>
                    {workflowCategoryList.map((item, index) => (
                      <MenuItem
                        value={item.id}
                        key={index}
                        onClick={() =>
                          handleCategoryChange(item.workflow_category)
                        }
                      >
                        <SelectSpan>{item.workflow_category}</SelectSpan>
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>{errors.wm_id?.message}</FormHelperText>
                </FormControl>
              </Grid>
              {clientId === 5 && showRange ? (
                <>
                  <Grid item md={6}>
                    <VendorCardLabel>
                      Select {Company} <Super>*</Super>
                    </VendorCardLabel>
                    <GlobalAutocomplete
                      name="com_id"
                      control={control}
                      options={subCompanyDropdownList?.flatMap((el) => {
                        return {
                          key: el.id,
                          value: el?.company_name,
                        };
                      })}
                      placeholder={`Select ${Company.toLocaleLowerCase()}`}
                      rules={{ required: `${Company} is required` }}
                      isMultiple={false}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FormControl
                      variant="standard"
                      style={{ width: "100%" }}
                      error={Boolean(errors?.cpp_id)}
                    >
                      <VendorCardLabel>
                        Purchase Point <Super>*</Super>
                      </VendorCardLabel>
                      <Select
                        sx={{
                          fontFamily: "var(--font-family-reg)",
                          fontSize: "14px",
                        }}
                        displayEmpty
                        defaultValue=""
                        IconComponent={() => (
                          <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                        )}
                        labelId="demo-simple-select-standard-label"
                        id="cpp_id"
                        name="cpp_id"
                        error={Boolean(errors?.cpp_id)}
                        {...register("cpp_id", {
                          required: "Please select purchase point",
                        })}
                      >
                        <MenuItem value="">
                          <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                            Select Purchase Point
                          </span>
                        </MenuItem>
                        {purchasePointList.map((item, index) => (
                          <MenuItem
                            value={item.id}
                            key={index}
                            // onClick={() => handlePurchasePointChange(item.id)}
                          >
                            <SelectSpan>
                              {item["PurchasePointMaster.purchase_point"]}
                            </SelectSpan>
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error>
                        {errors.cpp_id?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                ""
              )}
              <Grid item md={6}>
                <VendorCardLabel>
                  Name<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter workflow name"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="workflow_name"
                  {...register("workflow_name", {
                    required: "Please enter workflow name",
                  })}
                  error={Boolean(errors?.workflow_name)}
                  helperText={errors?.workflow_name?.message}
                />
              </Grid>
              {clientId === 5 && showRange ? (
                <Grid item md={6}>
                  <FormControl
                    variant="standard"
                    style={{ width: "100%" }}
                    error={Boolean(errors?.wm_id)}
                  >
                    <VendorCardLabel>
                      Range <Super>*</Super>
                    </VendorCardLabel>
                    <GlobalAutocomplete
                      name="rang_id"
                      control={control}
                      options={rangeList?.flatMap((el) => {
                        return {
                          key: el.id,
                          value: el?.range,
                        };
                      })}
                      placeholder={`Select range`}
                      rules={{ required: `Range is required` }}
                      isMultiple={false}
                    />
                    <FormHelperText error>
                      {errors.range?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <br />
            <Grid container spacing={1}>
              <Grid item md={12}>
                <VendorCardLabel>Description</VendorCardLabel>
                <TextField
                  multiline
                  rows={4}
                  placeholder="Enter workflow description"
                  style={{
                    width: "100%",
                    paddingTop: "3px",
                    fontSize: "6px",
                    fontFamily: "var(--font-family-reg)",
                  }}
                  name="description"
                  {...register("description", {
                    required: false,
                  })}
                />
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "200px",
              height: "40px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            Create
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}

export default WorkflowContentView;
