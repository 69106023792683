import {
  Grid,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  Select,
  Tab,
  Box,
  useTheme,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";

import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import AdminPrsTableContent from "./components/adminPrsTableContent";
import Button from "@mui/material/Button";
// import Paper from "@mui/material/Paper";

// dropdown dependance

import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPrCreationStatus } from "../workflows/redux/userWorkflowsSlice";
import {
  CustomBox,
  CustomTab,
} from "../adminTable/components/adminTableContent";
import SwipeableViews from "react-swipeable-views";
import { getPrs } from "./redux/adminPrsSlice";

function AdminPrsTable() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { prCreationStatus } = useSelector((state) => state.userWorkflows);
  const [filter, setFilter] = useState("All");
  const [value, setValue] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [per_page, setPer_page] = React.useState(10);
  const [search, setSearch] = React.useState(null);
  const [timeoutId, setTimeoutId] = React.useState(null);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <CustomBox
            sx={{
              pt: 3,
              backgroundColor: "#f5f7f9",
            }}
          >
            <div>{children}</div>
          </CustomBox>
        )}
      </div>
    );
  }
  const getData = () => {
    dispatch(getPrCreationStatus());
  };

  const prs = () => {
    let payload = { search, filter, page, per_page };

    if (value === 0) {
      payload = {
        ...payload,
        status: "pending",
      };
    }
    if (value === 1) {
      payload = {
        ...payload,
        status: "completed",
      };
    }
    if (value === 2) {
      payload = {
        ...payload,
        status: "rejected",
      };
    }

    dispatch(getPrs(payload));
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    prs();
    // eslint-disable-next-line
  }, [filter, value, page, per_page]);

  useEffect(() => {
    // if (search.length >= 4) {
    //   prs(search);
    // }
    // Clear the existing timeout if inputValue changes
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // Set a new timeout
    const id = setTimeout(() => {
      if (search) {
        prs(search);
      }
    }, 2000);
    // Save the timeout ID
    setTimeoutId(id);
    // Clear timeout on component unmount
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [search]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <div>
      <TitleHolder style={{ paddingBottom: "0" }}>
        <Grid container spacing={3} flex justifyContent={"space-between"}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={4}>
            Purchase Requisitions
          </Grid>

          <Grid item xs={5}>
            <div
              style={{
                display: "flex",
                gap: 10,
                justifyContent: "right",
              }}
            >
              <FormControl variant="standard" size="small">
                <InputLabel>Filter</InputLabel>
                <Select
                  value={filter}
                  onChange={handleFilterChange}
                  label="Filter"
                  // size="small"
                  sx={{ width: "75px" }}
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="my">My PRs</MenuItem>
                  <MenuItem value="assigned">Assigned PRs</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant="standard"
                size="small"
                sx={{ fullWidth: "250px" }}
                label="Search by PR Number"
                onChange={(e) => setSearch(e.target.value)}
              />
              {/* <Button
                variant="contained"
                size="small"
                onClick={() => setClicked(true)}
              >
                <Search />
              </Button> */}

              {prCreationStatus?.is_granted && (
                <>
                  {/* dropdown */}
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          sx={{
                            fontSize: "2rem",
                            height: "3rem",
                          }}
                          {...bindTrigger(popupState)}
                        >
                          +
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem onClick={popupState.close}>
                            <Link
                              to="/app/prs"
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              Create PR
                            </Link>
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </TitleHolder>
      <br />
      <StepperContentHolder>
        <Box sx={{ boxShadow: "0px 20px 30px rgba(52, 173, 237, 0.2)" }}>
          <CustomTab
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              label={
                <Grid spacing={1} className="d-flex gap-3">
                  <Grid md={8}>Pending</Grid>
                </Grid>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Grid spacing={1} className="d-flex gap-3">
                  <Grid md={8}>Approved/Pushed</Grid>
                </Grid>
              }
              {...a11yProps(1)}
            />
            <Tab
              label={
                <Grid spacing={1} className="d-flex gap-3">
                  <Grid md={8}>Rejected</Grid>
                </Grid>
              }
              {...a11yProps(2)}
            />
          </CustomTab>

          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <AdminPrsTableContent
                page={page}
                per_page={per_page}
                setPage={setPage}
                setPer_page={setPer_page}
              />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <AdminPrsTableContent
                page={page}
                per_page={per_page}
                setPage={setPage}
                setPer_page={setPer_page}
              />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <AdminPrsTableContent
                page={page}
                per_page={per_page}
                setPage={setPage}
                setPer_page={setPer_page}
              />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </StepperContentHolder>
    </div>
  );
}

export default AdminPrsTable;
