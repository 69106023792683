import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  inspections,
  get_inspections,
  get_lot_numbers,
  get_gst,
  view_UserInspectionDetails,
  get_items,
} from "./inspectionModuleAPI";

const initialState = {
  loading: false,
  banks: [],
  lotNumbers: [],
  inspectionsList: [],
  gsts: [],
  user_inspection_details: [],
  itemList: [],
};
const actions = {
  GETITEMS: "getItems/GETITEMS",
  GETBANKS: "getBanks/GETBANKS",
  CREATEINSPECTION: "createInspection/CREATEINSPECTION",
  GETINSPECTIONS: "getInspections/GETINSPECTIONS",
  GETLOTNUMBERS: "getLots/GETLOTNUMBERS",
  GETGSTS: "getGST/GETGSTS",
  VIEWUSERINSPECTIONDETAILS:
    "getUserInspectionDetails/VIEWUSERINSPECTIONDETAILS",
};

export const createInspection = createAsyncThunk(
  actions.CREATEINSPECTION,
  async (payload) => {
    const response = await inspections(payload);
    return response;
  }
);

export const getUserInspectionDetails = createAsyncThunk(
  actions.VIEWUSERINSPECTIONDETAILS,
  async (payload) => {
    const response = await view_UserInspectionDetails(payload);
    return response;
  }
);

export const getItems = createAsyncThunk(actions.GETITEMS, async (payload) => {
  const response = await get_items(payload);
  return response;
});

export const getLots = createAsyncThunk(
  actions.GETLOTNUMBERS,
  async (payload) => {
    const response = await get_lot_numbers(payload);
    return response;
  }
);

export const getInspections = createAsyncThunk(
  actions.GETINVOICES,
  async (payload) => {
    const response = await get_inspections(payload);
    return response;
  }
);

export const getGST = createAsyncThunk(actions.GETGSTS, async (payload) => {
  const response = await get_gst(payload);
  return response;
});

export const inspectionModuleSlice = createSlice({
  name: "inspectionModule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserInspectionDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserInspectionDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.user_inspection_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getUserInspectionDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createInspection.pending, (state) => {
        state.loading = true;
      })
      .addCase(createInspection.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createInspection.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.itemList = data;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
        console.log(data);
      })
      .addCase(getItems.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getLots.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLots.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.lotNumbers = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getLots.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getInspections.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInspections.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.inspectionsList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getInspections.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getGST.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGST.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.gsts = data;
      })
      .addCase(getGST.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const inspectionsModuleActions = inspectionModuleSlice.actions;
export const { resetInspectionsModuleDetails } = inspectionModuleSlice.actions;

export default inspectionModuleSlice.reducer;