import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { edit_lot, get_lots, view_LotDetails } from "./userLotsAPI";

const initialState = {
  loading: false,
  lotsList: [],
  lot_details: [],
};
const actions = {
  GETLOTS: "getLots/GETLOTS",
  VIEWLOTDETAILS: "getLotDetails/VIEWLOTDETAILS",
  EDITLOT: "editLot/EDITLOT",
};

export const getLots = createAsyncThunk(actions.GETORDERS, async (payload) => {
  const response = await get_lots(payload);
  return response;
});

export const getLotDetails = createAsyncThunk(
  actions.VIEWLOTDETAILS,
  async (payload) => {
    const response = await view_LotDetails(payload);
    return response;
  }
);

export const editLot = createAsyncThunk(actions.EDITLOT, async (payload) => {
  console.log("payload :", payload);
  const response = await edit_lot(payload);
  return response;
});

export const userLotsSlice = createSlice({
  name: "userLots",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLots.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLots.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.lotsList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getLots.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getLotDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLotDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.lot_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getLotDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(editLot.pending, (state) => {
        state.loading = true;
      })
      .addCase(editLot.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(editLot.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const userLotsActions = userLotsSlice.actions;
export const { resetuserLotsDetails } = userLotsSlice.actions;

export default userLotsSlice.reducer;
