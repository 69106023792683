import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import ScheduleModuleContent from "./components/scheduleModuleContent";

function ScheduleModule() {
  return (
    <div>
      <TitleHolder>Schedules</TitleHolder>
      <StepperContentHolder>
        <ScheduleModuleContent />
      </StepperContentHolder>
    </div>
  );
}

export default ScheduleModule;
