import { Grid } from "@mui/material";
import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import AdminAsnsTableContent from "./components/adminAsnsTableContent";

function AdminAsnsTable() {
  return (
    <div>
      <TitleHolder style={{ paddingBottom: "0" }}>
        <Grid container spacing={1}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Advanced Shipping Notices
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </TitleHolder>
      <br />
      <StepperContentHolder>
        <AdminAsnsTableContent />
      </StepperContentHolder>
    </div>
  );
}

export default AdminAsnsTable;
