import React from "react";
import { useParams } from "react-router-dom";
import { AdminTitleHolder } from "../../../common/sharedComponents/styles/card.style";
import { Grid } from "@mui/material";
import { StepperContentHolder } from "../../vendorRegistration/vendorRegistration";
import UserDetailsContent from "./components/userDetailsContent";

const UserDetails = () => {
  return (
    <div>
      <StepperContentHolder>
        <UserDetailsContent />
      </StepperContentHolder>
    </div>
  );
};

export default UserDetails;
