import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getItem, item, getHsns, upload_item } from "./itemAPI";

const initialState = {
  loading: false,
  itemsData: [],
  hsnList: [],
  pagination: {},
};

const actions = {
  GETITEMS: "getItems/GETITEMS",
  CREATEITEM: "createItem/CREATEITEM",
  GETHSNLIST: "getHsnList/GETHSNLIST",
  ITEMUPLOADEXCEL: "item_upload_excel/ITEMUPLOADEXCEL",
};

export const createItem = createAsyncThunk(
  actions.CREATEITEM,
  async (payload) => {
    const response = await item(payload);
    return response;
  }
);

export const getHsnList = createAsyncThunk(
  actions.GETHSNLIST,
  async (payload) => {
    const response = await getHsns(payload);
    return response;
  }
);

export const getItems = createAsyncThunk(actions.GETITEMS, async (payload) => {
  const response = await getItem(
    payload?.page,
    payload?.per_page,
    payload?.search
  );
  return response;
});

export const item_upload_excel = createAsyncThunk(
  actions.ITEMUPLOADEXCEL,
  async (payload) => {
    const response = await upload_item(payload);
    return response;
  }
);

export const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(createItem.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createItem.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getHsnList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHsnList.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        if (success) {
          state.hsnList = data;
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(getHsnList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        const {
          success,
          message: msg,
          data: { data, pagination },
        } = action.payload;
        state.loading = false;
        state.itemsData = data;
        state.pagination = pagination;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(getItems.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(item_upload_excel.pending, (state) => {
        state.loading = true;
      })
      .addCase(item_upload_excel.fulfilled, (state, action) => {
        const { message: msg } = action.payload;

        state.loading = false;
        if (action.payload?.success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(item_upload_excel.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});

export const itemActions = itemSlice.actions;
export const { resetitemDetails } = itemSlice.actions;

export default itemSlice.reducer;
