import React, { useEffect, useState } from "react";
import {
  CustomButton,
  DocumentLabels,
  SelectSpan,
  StyledCard,
  StyledFilledButton,
  StyledOutlineButton,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import {
  Box,
  CardContent,
  Chip,
  Grid,
  Input,
  TextField,
  Typography,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { VendorCardTitle } from "../../userOrders/components/userOrdersViewContent";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useForm } from "react-hook-form";
import {
  AddContactDiv,
  ContactCardTitle,
  DarkText,
  Dot,
  LightText,
  Super,
  VendorCardSubTitle,
} from "../../vendorRegistration/components/steps/cardTrial";
import { Close } from "@mui/icons-material";
import deleteIcon from "../../../assets/Group 115.svg";
import editIcon from "../../../assets/Group 41.svg";
import { Container } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  checkTokenStatus,
  createInspection,
  getLotDetails,
} from "../redux/qualityCheckSlice";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";

const CheckCard = ({
  index,
  item,
  items,
  register,
  onChange,
  onSave,
  onEdit,
  onDelete,
  selectedQuantity,
}) => {
  const { type, remarks, edit, passed, failed } = item;

  return (
    <AddContactDiv>
      {edit ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ padding: "15px 15px 0 15px" }}>
              <ContactCardTitle style={{ marginLeft: "30px" }}>
                Add Inspection Details
              </ContactCardTitle>
            </Grid>
            <Grid sx={{ padding: "15px 15px 0 15px" }} item xs={6}>
              <ContactCardTitle style={{ textAlign: "right" }}>
                <Close onClick={() => onDelete(index)}>
                  <CloseIcon style={{ cursor: "pointer" }} />
                </Close>
              </ContactCardTitle>
            </Grid>
          </Grid>
          <CardContent>
            <Container fixed>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <VendorCardLabel>
                    Type of Check<Super>*</Super>
                  </VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    id={`type_${index}`}
                    style={{ width: "100%" }}
                    {...register("type")}
                    InputProps={{
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    placeholder="Enter type of check"
                    value={type || ""}
                    onChange={(e) => onChange(e, index)}
                  />
                  <VendorCardLabel style={{ fontSize: "10px" }}>
                    Example: Quantity check, quality check, packaging check etc.
                  </VendorCardLabel>
                </Grid>
                <Grid item md={6}>
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <VendorCardLabel>Select an Item</VendorCardLabel>
                    <Select
                      sx={{
                        fontFamily: "var(--font-family-reg)",
                        fontSize: "14px",
                        height: "26.13px",
                      }}
                      displayEmpty
                      defaultValue=""
                      IconComponent={() => (
                        <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                      )}
                      labelId="demo-simple-select-standard-label"
                      id={`item_${index}`}
                      name="item"
                      {...register("item", {
                        required: false,
                      })}
                      // onChange={handleLotNumberChange}
                      onChange={(e) => onChange(e, index)}
                    >
                      <MenuItem value="">
                        <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                          Select item
                        </span>
                      </MenuItem>
                      {items
                        ? items.map((item) => (
                            <MenuItem
                              value={item.MaterialMasterId}
                              key={item.MaterialMasterId}
                            >
                              <SelectSpan>{item.name}</SelectSpan>
                            </MenuItem>
                          ))
                        : ""}
                    </Select>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={6}>
                  <VendorCardLabel>
                    Passed Quantity<Super>*</Super>
                  </VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    type="number"
                    id={`passed_${index}`}
                    style={{ width: "100%" }}
                    {...register("passed")}
                    InputProps={{
                      disableInjectingGlobalStyles: true,
                      inputProps: { min: 0, max: selectedQuantity },
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    placeholder="Enter passed quantity"
                    value={
                      passed !== null && passed !== undefined ? passed : ""
                    }
                    onChange={(e) => {
                      const newValue =
                        e.target.value.trim() === ""
                          ? null
                          : parseInt(e.target.value);
                      if (
                        newValue === null ||
                        (!isNaN(newValue) &&
                          newValue >= 0 &&
                          newValue <= selectedQuantity)
                      ) {
                        onChange(e, index);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <VendorCardLabel>
                    Failed Quantity<Super>*</Super>
                  </VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    type="number"
                    id={`failed_${index}`}
                    style={{ width: "100%" }}
                    {...register("failed")}
                    InputProps={{
                      disableInjectingGlobalStyles: true,
                      inputProps: { min: 0, max: selectedQuantity },
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    placeholder="Enter failed quantity"
                    value={
                      failed !== null && failed !== undefined ? failed : ""
                    }
                    onChange={(e) => {
                      const newValue =
                        e.target.value.trim() === ""
                          ? null
                          : parseInt(e.target.value);
                      if (
                        newValue === null ||
                        (!isNaN(newValue) &&
                          newValue >= 0 &&
                          newValue <= selectedQuantity)
                      ) {
                        onChange(e, index);
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={1}>
                <Grid item md={12}>
                  <VendorCardLabel>Remarks</VendorCardLabel>
                  <TextField
                    multiline
                    rows={4}
                    placeholder="Enter remarks"
                    style={{
                      width: "100%",
                      // paddingTop: "3px",
                      fontSize: "8px",
                      fontFamily: "var(--font-family-reg)",
                      backgroundColor: "#fff",
                    }}
                    name="remarks"
                    {...register("remarks", {
                      required: false,
                    })}
                    onChange={(e) => onChange(e, index)}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={6} md={8}></Grid>
                <Grid item xs={6} md={4}>
                  <StyledFilledButton
                    variant="contained"
                    color="success"
                    disabled={type === "" ? true : false}
                    style={{ textAlign: "right", float: "right" }}
                    onClick={() => onSave(index)}
                  >
                    Save
                  </StyledFilledButton>
                  <br />
                </Grid>
              </Grid>
            </Container>
          </CardContent>
        </>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs="10">
            <Grid container spacing={1} alignItems="center">
              <Grid item xs="3">
                <DarkText>{`${type || ""}`}</DarkText>
              </Grid>
              <Grid item xs="1">
                <Dot />
              </Grid>
              <Grid item xs="3">
                {remarks ? <LightText>{remarks}</LightText> : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid container spacing={1}>
              <Grid item xs={6} onClick={() => onEdit(index)}>
                <div style={{ cursor: "pointer" }}>
                  <img src={editIcon} alt="edit" />
                </div>
              </Grid>
              <Grid item xs={6} onClick={() => onDelete(index)}>
                <div style={{ cursor: "pointer" }}>
                  <img src={deleteIcon} alt="delete" />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </AddContactDiv>
  );
};

function QualityCheckContent() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  var selected_item;

  const [inspectionDoc, setInspectionDoc] = useState("");
  const [newDocs, setNewDocs] = useState([1]);
  const [supportingDoc, setSupportingDoc] = useState([]);

  const { lot_details, loading } = useSelector((state) => state.qualityCheck);
  console.log("lot_details :", lot_details);

  const [finish, setFinish] = useState(false);
  const [tokenstate, setTokenstate] = useState(true);
  const [checks, setChecks] = useState([
    {
      type: "",
      remarks: "",
      edit: true,
      item_id: "",
      passed: 0,
      failed: 0,
    },
  ]);

  console.log("finish :", finish);
  console.log("tokenstate :", tokenstate);

  const [selectedQuantity, setSelectedQuantity] = useState(0);

  useEffect(() => {
    // Get the URLSearchParams object
    const urlParams = new URLSearchParams(window.location.search);
    // Get the value of the 'token' parameter
    const token = urlParams.get("token");
    // use this token to get lot details from database
    dispatch(getLotDetails({ token: token })).then((res) => {
      if (res.payload.success) {
        if (res.payload.data.status === "draft") {
          setChecks([
            ...res.payload.data.checks,

            {
              type: "",
              remarks: "",
              edit: true,
              item_id: "",
              passed: 0,
              failed: 0,
            },
          ]);
          setInspectionDoc(res.payload.data.inspection_document);
        }
      }
    });
  }, [dispatch]); // executes on reload only

  useEffect(() => {
    // Get the URLSearchParams object
    const urlParams = new URLSearchParams(window.location.search);
    // Get the value of the 'token' parameter
    const token = urlParams.get("token");
    dispatch(checkTokenStatus({ token: token })).then((res) => {
      if (res.payload.success) {
        setTokenstate(true);
      } else {
        setTokenstate(false);
      }
    });
  }, [dispatch]);

  const handleCheckOnchange = (e, index) => {
    const { name, value } = e.target;

    let checksCopy = [...checks];

    // Update the check object with the selected item_id
    checksCopy[index] = {
      ...(checksCopy[index] || {}),
      [name]: value,
    };

    // Ensure that the selected item_id is correctly set
    if (name === "item") {
      checksCopy[index].item_id = value;
      // filtering out selected item object
      selected_item = lot_details.items.filter(
        (item) => item.MaterialMasterId === value
      );
      selected_item = selected_item[0];
      setSelectedQuantity(selected_item?.quantity);
    }

    setChecks(checksCopy);
  };

  const handleSaveCheck = (index) => {
    let checksCopy = [...checks];

    // Assuming item_id is fetched from the selected item in the dropdown
    const selectedItem = lot_details?.items.find(
      (item) => item.id === checksCopy[index].item
    );
    const itemId = selectedItem ? selectedItem.id : "";

    checksCopy[index] = {
      ...(checksCopy[index] || {}),
      edit: false,
      item_id: itemId, // Set the item_id here
    };

    setChecks(checksCopy);
  };

  const handleEditCheck = (index) => {
    let checksCopy = [...checks];

    checksCopy[index] = {
      ...(checksCopy[index] || {}),
      edit: true,
    };

    setChecks(checksCopy);
  };

  const handleDeleteCheck = (index) => {
    let checksCopy = [...checks];
    // eslint-disable-next-line
    checksCopy = checksCopy.filter((_, i) => i != index);

    setChecks(checksCopy);
  };

  const handleAddCheck = () => {
    let checksCopy = [...checks];

    checksCopy.push({
      type: "",
      remarks: "",
      edit: true,
      item_id: "",
      passed: 0,
      failed: 0,
    });

    setChecks(checksCopy);
  };

  const handleclearDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: "" };

    setSupportingDoc(newDocValues);
  };

  const handleOnClick = () => {
    let docsCopy = [...newDocs];

    docsCopy.push({
      supporting_docs: "",
    });

    setNewDocs(docsCopy);
  };

  const handlechangeDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: e.target.files[0] };

    setSupportingDoc(newDocValues);
  };

  const handleRemove = (e, i) => {
    let uploadeddocs = [...newDocs];
    uploadeddocs.splice(i, 1);
    setNewDocs(uploadeddocs);
  };

  const handleSave = async (actionType) => {
    // Gather data of all checks and associated items
    const inspection_details = checks.map((check) => {
      let payload;
      if (check.type !== "") {
        payload = {
          id: check?.id ? check?.id : null,
          type: check.type,
          item_id: check.item,
          passed: check.passed,
          failed: check.failed,
          remarks: check.remarks,
        };
      }
      return payload;
    });
    var formData = new FormData();
    formData.append(
      "data",
      JSON.stringify([{ lot_id: lot_details.id, actionType: actionType }])
    );
    formData.append("inspection_details", JSON.stringify(inspection_details));
    formData.append("inspection_document", inspectionDoc);
    for (let i = 0; i < newDocs.length; i++) {
      formData.append("supporting_documents", supportingDoc[i]);
    }
    dispatch(createInspection(formData)).then((response) => {
      if (response?.payload?.success) {
        setFinish(true);
      } else {
        setFinish(false);
      }
    });
  };
  return (
    <>
      {lot_details.status === "pending" ? (
        <>
          <form onSubmit={handleSubmit((data) => handleSave("draft", data))}>
            <StyledCard>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <VendorCardTitle style={{ paddingBottom: "0" }}>
                      Inspection
                    </VendorCardTitle>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <VendorCardSubTitle style={{ marginTop: "0" }}>
                      Review the lot details below and perform at least one
                      check
                    </VendorCardSubTitle>
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={4} md={4}>
                    <Typography className="label">ORDER NUMBER</Typography>
                    <VendorCardLabel>
                      {lot_details?.order_number
                        ? lot_details.order_number
                        : "Not Applicable"}
                    </VendorCardLabel>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Typography className="label">LOT NUMBER</Typography>
                    <VendorCardLabel>
                      {lot_details?.lot_number
                        ? lot_details.lot_number
                        : "Not Applicable"}
                    </VendorCardLabel>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Typography className="label">INSPECTION NUMBER</Typography>
                    <VendorCardLabel>
                      {lot_details?.inspection_number
                        ? lot_details.inspection_number
                        : "Not Applicable"}
                    </VendorCardLabel>
                  </Grid>
                  {lot_details?.inspection_type === "external" ? (
                    <Grid item xs={4} md={4}>
                      <Typography className="label">AGENCY NAME</Typography>
                      <VendorCardLabel>
                        {lot_details?.agency_name
                          ? lot_details.agency_name
                          : "Not Applicable"}
                      </VendorCardLabel>
                    </Grid>
                  ) : (
                    <Grid item xs={4} md={4}>
                      <Typography className="label">FULL NAME</Typography>
                      <VendorCardLabel>
                        {lot_details?.full_name
                          ? lot_details.full_name
                          : "Not Applicable"}
                      </VendorCardLabel>
                    </Grid>
                  )}
                  <Grid item xs={8} md={8}>
                    <Typography className="label">DIRECTIVES</Typography>
                    <VendorCardLabel>
                      {lot_details?.directives
                        ? lot_details.directives
                        : "Not Applicable"}
                    </VendorCardLabel>
                  </Grid>
                  {lot_details?.inspection_type === "internal" ? (
                    <Grid item xs={4} md={4}>
                      <Typography className="label">DESIGNATION</Typography>
                      <VendorCardLabel>
                        {lot_details?.designation
                          ? lot_details.designation
                          : "Not Applicable"}
                      </VendorCardLabel>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {lot_details?.inspection_type === "internal" ? (
                    <Grid item xs={4} md={4}>
                      <Typography className="label">PHONE NUMBER</Typography>
                      <VendorCardLabel>
                        {lot_details?.phone_number
                          ? lot_details.phone_number
                          : "Not Applicable"}
                      </VendorCardLabel>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
                <br />
                <br />
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <VendorCardTitle>Item Details</VendorCardTitle>
                    <CustomTableContainer component={Card}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow
                            style={{
                              backgroundColor: "#e1ebfa",
                            }}
                          >
                            <TableCell
                              width="10%"
                              style={{ textAlign: "center" }}
                            >
                              <TableHeaderFont>Sr. No.</TableHeaderFont>
                            </TableCell>
                            <TableCell
                              width="20%"
                              style={{ textAlign: "center" }}
                            >
                              <TableHeaderFont>Item Name</TableHeaderFont>
                            </TableCell>
                            <TableCell
                              width="20%"
                              style={{ textAlign: "center" }}
                            >
                              <TableHeaderFont>Packaging Type</TableHeaderFont>
                            </TableCell>
                            <TableCell
                              width="20%"
                              style={{ textAlign: "center" }}
                            >
                              <TableHeaderFont>Quantity</TableHeaderFont>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {lot_details?.items ? (
                            lot_details.items.map((row, index) => {
                              return (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    width="10%"
                                    style={{
                                      fontFamily: "var(--font-family-reg)",
                                      fontSize: "12px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell
                                    width="20%"
                                    style={{
                                      fontFamily: "var(--font-family-reg)",
                                      fontSize: "12px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.name ? row.name : "Not Applicable"}
                                  </TableCell>
                                  <TableCell
                                    width="20%"
                                    style={{
                                      fontFamily: "var(--font-family-reg)",
                                      fontSize: "12px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.packaging_type
                                      ? row.packaging_type
                                      : "Not Applicable"}
                                  </TableCell>
                                  <TableCell
                                    width="20%"
                                    style={{
                                      fontFamily: "var(--font-family-reg)",
                                      fontSize: "12px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.quantity
                                      ? row.quantity
                                      : "Not Applicable"}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow>Fetching Items...</TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                  </Grid>
                </Grid>
              </CardContent>
            </StyledCard>

            <StyledCard>
              <CardContent>
                <Grid>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={8}>
                        <VendorCardTitle>Checks</VendorCardTitle>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <StyledOutlineButton
                          onClick={handleAddCheck}
                          style={{
                            textAlign: "right",
                            float: "right",
                          }}
                        >
                          Add Check
                        </StyledOutlineButton>
                        <br />
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item md={8}>
                      <VendorCardSubTitle style={{ marginTop: "0" }}>
                        Total number of checks added : {checks.length}
                      </VendorCardSubTitle>
                    </Grid>
                  </Grid>
                  <VendorCardSubTitle>
                    {checks.length
                      ? ""
                      : "No checks added yet. Start adding checks by clicking on Add Check button in the right."}
                  </VendorCardSubTitle>
                  {checks.map((item, index) => (
                    <CheckCard
                      key={index}
                      index={index}
                      item={item}
                      items={lot_details?.items}
                      register={register}
                      onChange={handleCheckOnchange}
                      onSave={handleSaveCheck}
                      onEdit={handleEditCheck}
                      onDelete={handleDeleteCheck}
                      selectedQuantity={selectedQuantity}
                    />
                  ))}
                </Grid>
                <br />
                <VendorCardTitle style={{ paddingBottom: "20px" }}>
                  Documents
                </VendorCardTitle>
                <Grid container spacing={3}>
                  <>
                    <Grid item xs={4}>
                      <span
                        variant="outlined"
                        component="span"
                        style={{ width: "50%" }}
                      >
                        <DocumentLabels style={{ marginTop: "10px" }}>
                          Inspection Document<Super>*</Super>
                        </DocumentLabels>
                      </span>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl
                        variant="standard"
                        style={{ width: "100%" }}
                        error={Boolean(errors?.inspection_document)}
                      >
                        {inspectionDoc !== "" ? (
                          <>
                            <span
                              style={{
                                color: "#4945FF",
                                fontSize: "13px",
                                fontFamily: "var(--font-family-reg)",
                              }}
                            >
                              <Chip
                                style={{
                                  width: "150px",
                                  color: "#4945FF",
                                  margin: "5px",
                                  borderRadius: "5px",
                                  border: "1px solid #D9D8FF",
                                  background: "#F0F0FF",
                                }}
                                label={inspectionDoc.name}
                                value={inspectionDoc.name}
                                name="chips"
                              />
                              <HighlightOffIcon
                                sx={{ color: "#4945FF", paddingBottom: "2px" }}
                                fontSize="medium"
                                onClick={(e) => setInspectionDoc("")}
                              />
                            </span>
                          </>
                        ) : (
                          <label htmlFor="inspection_document">
                            <Input
                              {...register("inspection_document", {
                                required: "Please upload inspection document",
                              })}
                              accept="*"
                              id="inspection_document"
                              onChange={(e) =>
                                setInspectionDoc(e.target.files[0])
                              }
                              type="file"
                            />
                            <StyledOutlineButton
                              variant="outlined"
                              component="span"
                            >
                              Upload
                            </StyledOutlineButton>
                          </label>
                        )}
                        {inspectionDoc === "" ? (
                          <FormHelperText error>
                            {errors.inspection_document?.message}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>
                  </>
                </Grid>
                {newDocs.map((item, index) => (
                  <>
                    <br />
                    <Grid container spacing={3} key={index}>
                      <>
                        <Grid item xs={4}>
                          <span
                            variant="outlined"
                            component="span"
                            style={{ width: "50%" }}
                          >
                            <DocumentLabels style={{ marginTop: "10px" }}>
                              Supporting Document
                            </DocumentLabels>
                          </span>
                        </Grid>
                        {supportingDoc[index] !== "" &&
                        supportingDoc[index] !== undefined ? (
                          <>
                            <Grid item xs={3}>
                              <FormControl
                                variant="standard"
                                style={{ width: "100%" }}
                              >
                                <span
                                  style={{
                                    color: "#4945FF",
                                    fontSize: "13px",
                                    fontFamily: "var(--font-family-reg)",
                                  }}
                                >
                                  <Chip
                                    style={{
                                      width: "150px",
                                      color: "#4945FF",
                                      margin: "5px",
                                      borderRadius: "5px",
                                      border: "1px solid #D9D8FF",
                                      background: "#F0F0FF",
                                    }}
                                    label={supportingDoc[index].name}
                                    value={supportingDoc[index].name}
                                    name="chips"
                                  />
                                  <HighlightOffIcon
                                    sx={{
                                      color: "#4945FF",
                                      paddingBottom: "2px",
                                    }}
                                    fontSize="medium"
                                    onClick={(e) => handleclearDoc(e, index)}
                                  />
                                </span>
                              </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                              {index === 0 ? (
                                <CustomButton
                                  size="small"
                                  color="primary"
                                  variant="outlined"
                                  onClick={handleOnClick}
                                  className="invoiceErrbutn"
                                >
                                  Add Document
                                </CustomButton>
                              ) : (
                                ""
                              )}
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={2}>
                              <FormControl
                                variant="standard"
                                style={{ width: "100%" }}
                              >
                                <label>
                                  <Input
                                    accept="*"
                                    id={`supporting_doc_${index}`}
                                    onChange={(e) => handlechangeDoc(e, index)}
                                    type="file"
                                  />
                                  <StyledOutlineButton
                                    variant="outlined"
                                    component="span"
                                  >
                                    Upload
                                  </StyledOutlineButton>
                                </label>
                              </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                              {index === 0 ? (
                                <CustomButton
                                  size="small"
                                  color="primary"
                                  variant="outlined"
                                  onClick={handleOnClick}
                                  className="invoiceErrbutn"
                                  style={{ marginTop: "10px " }}
                                >
                                  Add Document
                                </CustomButton>
                              ) : (
                                <CustomButton
                                  className="invoiceErrbutn"
                                  color="error"
                                  variant="outlined"
                                  size="small"
                                  onClick={(e) => handleRemove(e, index)}
                                  style={{ marginTop: "10px " }}
                                >
                                  Remove
                                </CustomButton>
                              )}
                            </Grid>
                          </>
                        )}
                      </>
                    </Grid>
                  </>
                ))}
              </CardContent>
            </StyledCard>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                justifyContent: "right",
                marginRight: "2rem",
                gap: "5px",
              }}
            >
              <CustomButton
                sx={{
                  textTransform: "none",
                  background: "var(--button-color)",
                  width: "200px",
                  height: "40px",
                }}
                color="success"
                variant="contained"
                type="submit"
                disabled={!checks.length || loading}
              >
                Save as Draft
              </CustomButton>
            </Box>
            <br />
          </form>
          <CustomButton
            sx={{
              textTransform: "none",
              // background: "var(--button-color)",
              width: "200px",
              height: "40px",
            }}
            color="success"
            variant="contained"
            type="button" // Set type as button to prevent form submission
            disabled={!checks.length || loading}
            onClick={() => handleSave("complete")}
          >
            Complete Inspection
          </CustomButton>
        </>
      ) : tokenstate && lot_details.status === "completed" ? (
        <>
          <StyledCard>
            <CardContent>
              <p>Thank you !</p>
              <p>You have successfully performed the Inspection.</p>
              <p>You can close this tab now.</p>
            </CardContent>
          </StyledCard>
        </>
      ) : lot_details.status === "draft" ? (
        <>
          <StyledCard>
            <CardContent>
              <>
                <form
                  onSubmit={handleSubmit((data) => handleSave("draft", data))}
                >
                  <StyledCard>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <VendorCardTitle style={{ paddingBottom: "0" }}>
                            Inspection (Complete the draft)
                          </VendorCardTitle>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={8}>
                          <VendorCardSubTitle style={{ marginTop: "0" }}>
                            Review the lot details below and perform at least
                            one check
                          </VendorCardSubTitle>
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={3}>
                        <Grid item xs={4} md={4}>
                          <Typography className="label">
                            ORDER NUMBER
                          </Typography>
                          <VendorCardLabel>
                            {lot_details?.order_number
                              ? lot_details.order_number
                              : "Not Applicable"}
                          </VendorCardLabel>
                        </Grid>
                        <Grid item xs={4} md={4}>
                          <Typography className="label">LOT NUMBER</Typography>
                          <VendorCardLabel>
                            {lot_details?.lot_number
                              ? lot_details.lot_number
                              : "Not Applicable"}
                          </VendorCardLabel>
                        </Grid>
                        <Grid item xs={4} md={4}>
                          <Typography className="label">
                            INSPECTION NUMBER
                          </Typography>
                          <VendorCardLabel>
                            {lot_details?.inspection_number
                              ? lot_details.inspection_number
                              : "Not Applicable"}
                          </VendorCardLabel>
                        </Grid>
                        {lot_details?.inspection_type === "external" ? (
                          <Grid item xs={4} md={4}>
                            <Typography className="label">
                              AGENCY NAME
                            </Typography>
                            <VendorCardLabel>
                              {lot_details?.agency_name
                                ? lot_details.agency_name
                                : "Not Applicable"}
                            </VendorCardLabel>
                          </Grid>
                        ) : (
                          <Grid item xs={4} md={4}>
                            <Typography className="label">FULL NAME</Typography>
                            <VendorCardLabel>
                              {lot_details?.full_name
                                ? lot_details.full_name
                                : "Not Applicable"}
                            </VendorCardLabel>
                          </Grid>
                        )}
                        <Grid item xs={8} md={8}>
                          <Typography className="label">DIRECTIVES</Typography>
                          <VendorCardLabel>
                            {lot_details?.directives
                              ? lot_details.directives
                              : "Not Applicable"}
                          </VendorCardLabel>
                        </Grid>
                        {lot_details?.inspection_type === "internal" ? (
                          <Grid item xs={4} md={4}>
                            <Typography className="label">
                              DESIGNATION
                            </Typography>
                            <VendorCardLabel>
                              {lot_details?.designation
                                ? lot_details.designation
                                : "Not Applicable"}
                            </VendorCardLabel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {lot_details?.inspection_type === "internal" ? (
                          <Grid item xs={4} md={4}>
                            <Typography className="label">
                              PHONE NUMBER
                            </Typography>
                            <VendorCardLabel>
                              {lot_details?.phone_number
                                ? lot_details.phone_number
                                : "Not Applicable"}
                            </VendorCardLabel>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <br />
                      <br />
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                          <VendorCardTitle>Item Details</VendorCardTitle>
                          <CustomTableContainer component={Card}>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow
                                  style={{
                                    backgroundColor: "#e1ebfa",
                                  }}
                                >
                                  <TableCell
                                    width="10%"
                                    style={{ textAlign: "center" }}
                                  >
                                    <TableHeaderFont>Sr. No.</TableHeaderFont>
                                  </TableCell>
                                  <TableCell
                                    width="20%"
                                    style={{ textAlign: "center" }}
                                  >
                                    <TableHeaderFont>Item Name</TableHeaderFont>
                                  </TableCell>
                                  <TableCell
                                    width="20%"
                                    style={{ textAlign: "center" }}
                                  >
                                    <TableHeaderFont>
                                      Packaging Type
                                    </TableHeaderFont>
                                  </TableCell>
                                  <TableCell
                                    width="20%"
                                    style={{ textAlign: "center" }}
                                  >
                                    <TableHeaderFont>Quantity</TableHeaderFont>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {lot_details?.items ? (
                                  lot_details.items.map((row, index) => {
                                    return (
                                      <TableRow
                                        key={index}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell
                                          width="10%"
                                          style={{
                                            fontFamily:
                                              "var(--font-family-reg)",
                                            fontSize: "12px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          width="20%"
                                          style={{
                                            fontFamily:
                                              "var(--font-family-reg)",
                                            fontSize: "12px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {row.name
                                            ? row.name
                                            : "Not Applicable"}
                                        </TableCell>
                                        <TableCell
                                          width="20%"
                                          style={{
                                            fontFamily:
                                              "var(--font-family-reg)",
                                            fontSize: "12px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {row.packaging_type
                                            ? row.packaging_type
                                            : "Not Applicable"}
                                        </TableCell>
                                        <TableCell
                                          width="20%"
                                          style={{
                                            fontFamily:
                                              "var(--font-family-reg)",
                                            fontSize: "12px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {row.quantity
                                            ? row.quantity
                                            : "Not Applicable"}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                                ) : (
                                  <TableRow>Fetching Items...</TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </CustomTableContainer>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </StyledCard>

                  <StyledCard>
                    <CardContent>
                      <Grid>
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6} md={8}>
                              <VendorCardTitle>Checks</VendorCardTitle>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <StyledOutlineButton
                                onClick={handleAddCheck}
                                style={{
                                  textAlign: "right",
                                  float: "right",
                                }}
                              >
                                Add Check
                              </StyledOutlineButton>
                              <br />
                            </Grid>
                          </Grid>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item md={8}>
                            <VendorCardSubTitle style={{ marginTop: "0" }}>
                              Total number of checks added : {checks.length}
                            </VendorCardSubTitle>
                          </Grid>
                        </Grid>
                        <VendorCardSubTitle>
                          {checks.length
                            ? ""
                            : "No checks added yet. Start adding checks by clicking on Add Check button in the right."}
                        </VendorCardSubTitle>
                        {checks.map((item, index) => (
                          <CheckCard
                            key={index}
                            index={index}
                            item={item}
                            items={lot_details?.items}
                            register={register}
                            onChange={handleCheckOnchange}
                            onSave={handleSaveCheck}
                            onEdit={handleEditCheck}
                            onDelete={handleDeleteCheck}
                            selectedQuantity={selectedQuantity}
                          />
                        ))}
                      </Grid>
                      <br />
                      <VendorCardTitle style={{ paddingBottom: "20px" }}>
                        Documents
                      </VendorCardTitle>
                      <Grid container spacing={3}>
                        <>
                          <Grid item xs={4}>
                            <span
                              variant="outlined"
                              component="span"
                              style={{ width: "50%" }}
                            >
                              <DocumentLabels style={{ marginTop: "10px" }}>
                                Inspection Document<Super>*</Super>
                              </DocumentLabels>
                            </span>
                          </Grid>
                          <Grid item xs={3}>
                            <FormControl
                              variant="standard"
                              style={{ width: "100%" }}
                              error={Boolean(errors?.inspection_document)}
                            >
                              {inspectionDoc !== "" ? (
                                <>
                                  <span
                                    style={{
                                      color: "#4945FF",
                                      fontSize: "13px",
                                      fontFamily: "var(--font-family-reg)",
                                    }}
                                  >
                                    <Chip
                                      style={{
                                        width: "150px",
                                        color: "#4945FF",
                                        margin: "5px",
                                        borderRadius: "5px",
                                        border: "1px solid #D9D8FF",
                                        background: "#F0F0FF",
                                      }}
                                      label={inspectionDoc.name}
                                      value={inspectionDoc.name}
                                      name="chips"
                                    />
                                    <HighlightOffIcon
                                      sx={{
                                        color: "#4945FF",
                                        paddingBottom: "2px",
                                      }}
                                      fontSize="medium"
                                      onClick={(e) => setInspectionDoc("")}
                                    />
                                  </span>
                                </>
                              ) : (
                                <label htmlFor="inspection_document">
                                  <Input
                                    {...register("inspection_document", {
                                      required:
                                        "Please upload inspection document",
                                    })}
                                    accept="*"
                                    id="inspection_document"
                                    onChange={(e) =>
                                      setInspectionDoc(e.target.files[0])
                                    }
                                    type="file"
                                  />
                                  <StyledOutlineButton
                                    variant="outlined"
                                    component="span"
                                  >
                                    Upload
                                  </StyledOutlineButton>
                                </label>
                              )}
                              {inspectionDoc === "" ? (
                                <FormHelperText error>
                                  {errors.inspection_document?.message}
                                </FormHelperText>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          </Grid>
                        </>
                      </Grid>
                      {newDocs.map((item, index) => (
                        <>
                          <br />
                          <Grid container spacing={3} key={index}>
                            <>
                              <Grid item xs={4}>
                                <span
                                  variant="outlined"
                                  component="span"
                                  style={{ width: "50%" }}
                                >
                                  <DocumentLabels style={{ marginTop: "10px" }}>
                                    Supporting Document
                                  </DocumentLabels>
                                </span>
                              </Grid>
                              {supportingDoc[index] !== "" &&
                              supportingDoc[index] !== undefined ? (
                                <>
                                  <Grid item xs={3}>
                                    <FormControl
                                      variant="standard"
                                      style={{ width: "100%" }}
                                    >
                                      <span
                                        style={{
                                          color: "#4945FF",
                                          fontSize: "13px",
                                          fontFamily: "var(--font-family-reg)",
                                        }}
                                      >
                                        <Chip
                                          style={{
                                            width: "150px",
                                            color: "#4945FF",
                                            margin: "5px",
                                            borderRadius: "5px",
                                            border: "1px solid #D9D8FF",
                                            background: "#F0F0FF",
                                          }}
                                          label={supportingDoc[index].name}
                                          value={supportingDoc[index].name}
                                          name="chips"
                                        />
                                        <HighlightOffIcon
                                          sx={{
                                            color: "#4945FF",
                                            paddingBottom: "2px",
                                          }}
                                          fontSize="medium"
                                          onClick={(e) =>
                                            handleclearDoc(e, index)
                                          }
                                        />
                                      </span>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={2}>
                                    {index === 0 ? (
                                      <CustomButton
                                        size="small"
                                        color="primary"
                                        variant="outlined"
                                        onClick={handleOnClick}
                                        className="invoiceErrbutn"
                                      >
                                        Add Document
                                      </CustomButton>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                </>
                              ) : (
                                <>
                                  <Grid item xs={2}>
                                    <FormControl
                                      variant="standard"
                                      style={{ width: "100%" }}
                                    >
                                      <label>
                                        <Input
                                          accept="*"
                                          id={`supporting_doc_${index}`}
                                          onChange={(e) =>
                                            handlechangeDoc(e, index)
                                          }
                                          type="file"
                                        />
                                        <StyledOutlineButton
                                          variant="outlined"
                                          component="span"
                                        >
                                          Upload
                                        </StyledOutlineButton>
                                      </label>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={2}>
                                    {index === 0 ? (
                                      <CustomButton
                                        size="small"
                                        color="primary"
                                        variant="outlined"
                                        onClick={handleOnClick}
                                        className="invoiceErrbutn"
                                        style={{ marginTop: "10px " }}
                                      >
                                        Add Document
                                      </CustomButton>
                                    ) : (
                                      <CustomButton
                                        className="invoiceErrbutn"
                                        color="error"
                                        variant="outlined"
                                        size="small"
                                        onClick={(e) => handleRemove(e, index)}
                                        style={{ marginTop: "10px " }}
                                      >
                                        Remove
                                      </CustomButton>
                                    )}
                                  </Grid>
                                </>
                              )}
                            </>
                          </Grid>
                        </>
                      ))}
                    </CardContent>
                  </StyledCard>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      pt: 2,
                      justifyContent: "right",
                      marginRight: "2rem",
                      gap: "5px",
                    }}
                  >
                    <CustomButton
                      sx={{
                        textTransform: "none",
                        background: "var(--button-color)",
                        width: "200px",
                        height: "40px",
                      }}
                      color="success"
                      variant="contained"
                      type="submit"
                      disabled={!checks.length || loading}
                    >
                      Save as Draft
                    </CustomButton>
                  </Box>
                  <br />
                </form>
                <CustomButton
                  sx={{
                    textTransform: "none",
                    // background: "var(--button-color)",
                    width: "200px",
                    height: "40px",
                  }}
                  color="success"
                  variant="contained"
                  type="button" // Set type as button to prevent form submission
                  disabled={!checks.length || loading}
                  onClick={() => handleSave("complete")}
                >
                  Complete Inspection
                </CustomButton>
              </>
            </CardContent>
          </StyledCard>
        </>
      ) : !tokenstate && lot_details.status === "completed" ? (
        <>
          <StyledCard>
            <CardContent>
              <p>Sorry !</p>
              <p>You have already performed the Inspection for this lot.</p>
            </CardContent>
          </StyledCard>
        </>
      ) : (
        "loading..."
      )}
    </>
  );
}

export default QualityCheckContent;
