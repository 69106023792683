import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useDispatch } from "react-redux";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Card,
  Grid,
  IconButton,
  TableFooter,
  TablePagination,
  Tooltip,
  useTheme,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  CustomTableContainer,
  CustomTableHead,
  TableContentVendorFont,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import PropTypes from "prop-types";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { DisplayText } from "../../bankDetails/components/bankAccountsContent";
import empty from "../../../assets/illo.png";
import { getAdminAsns } from "../redux/adminAsnsSlice";
import moment from "moment";
import { Link } from "react-router-dom";

const EmptyContainer = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
  >
    <br />
    <img src={empty} alt="empty" />

    <DisplayText> There are no ASNs to display</DisplayText>

    <br />

    <br />
  </Grid>
);

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  console.log("jdhdhdhd");
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <>
      <Box sx={{ flexShrink: 0 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function AdminAsnsTableContent() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const { adminAsnsList } = useSelector((state) => state.adminAsns);
  const dispatch = useDispatch();

  console.log("adminAsnsList :", adminAsnsList);
  const Asns = () => {
    dispatch(getAdminAsns());
  };

  useEffect(() => {
    Asns();
    // eslint-disable-next-line
  }, []);

  // console.log(adminInvoicesList);
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - adminAsnsList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <CustomTableContainer component={Card}>
      <Table aria-label="simple table">
        <CustomTableHead>
          <TableRow>
            <TableCell>
              <TableHeaderFont>Vendor</TableHeaderFont>
            </TableCell>
            <TableCell align="left">
              <TableHeaderFont>Lot Number</TableHeaderFont>
            </TableCell>
            <TableCell align="left">
              <TableHeaderFont>ASN Number</TableHeaderFont>
            </TableCell>
            <TableCell align="left">
              <TableHeaderFont>Date</TableHeaderFont>
            </TableCell>
            <TableCell align="left">
              <TableHeaderFont>Expected Arrival Date</TableHeaderFont>
            </TableCell>
            <TableCell align="center">
              <TableHeaderFont>Action</TableHeaderFont>
            </TableCell>
          </TableRow>
        </CustomTableHead>
        {adminAsnsList.length !== 0 ? (
          <TableBody>
            {(rowsPerPage > 0
              ? adminAsnsList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : adminAsnsList
            ).map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Link
                    style={{ textDecoration: "none" }}
                    // eslint-disable-next-line
                    to={"/app/asns-list-view/" + `${row.id}`}
                  >
                    <TableContentVendorFont>
                      {row.VendorCompanyName
                        ? row.VendorCompanyName
                        : "Not Applicable"}
                    </TableContentVendorFont>
                  </Link>
                </TableCell>

                <TableCell>
                  {row.lot_number ? row.lot_number : "Not Applicable"}
                </TableCell>
                <TableCell>
                  {row.asn_number ? row.asn_number : "Not Applicable"}
                </TableCell>
                <TableCell>
                  {row.createdAt
                    ? moment(row.createdAt).format("DD MMM YY")
                    : "Not Applicable"}
                </TableCell>
                <TableCell>
                  {row.expected_arrival_date
                    ? moment(row.expected_arrival_date).format("DD MMM YY")
                    : "Not Applicable"}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Tooltip title="View ASN">
                    <Link
                      style={{ textDecoration: "none" }}
                      // eslint-disable-next-line
                      to={"/app/asns-list-view/" + `${row.id}`}
                    >
                      <RemoveRedEyeIcon
                        style={{
                          fill: "royalblue",
                          height: "18px",
                          width: "18px",
                        }}
                      />
                    </Link>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 0 * emptyRows }}></TableRow>
            )}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={6}>
                <CustomTableContainer>
                  <EmptyContainer />
                </CustomTableContainer>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
        <TableFooter style={{ borderTop: "1px solid lightgray" }}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={6}
              count={adminAsnsList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </CustomTableContainer>
  );
}

export default AdminAsnsTableContent;
