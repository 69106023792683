import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { approve_grn, get_grns, view_GrnDetails } from "./userGrnsAPI";

const initialState = {
  loading: false,
  grnsList: [],
  grn_details: [],
};
const actions = {
  GETGRNS: "getGrns/GETGRNS",
  VIEWGRNDETAILS: "getGrnDetails/VIEWGRNDETAILS",
  APPROVEGRN: "approveGrn/APPROVEGRN",
};

export const getGrns = createAsyncThunk(actions.GETGRNS, async (payload) => {
  const response = await get_grns(payload);
  return response;
});

export const getGrnDetails = createAsyncThunk(
  actions.VIEWGRNDETAILS,
  async (payload) => {
    const response = await view_GrnDetails(payload);
    return response;
  }
);

export const approveGrn = createAsyncThunk(
  actions.APPROVEGRN,
  async (payload) => {
    const response = await approve_grn(payload);
    return response;
  }
);

export const userGrnsSlice = createSlice({
  name: "userGrns",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGrns.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGrns.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.grnsList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getGrns.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getGrnDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGrnDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.grn_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getGrnDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(approveGrn.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveGrn.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(approveGrn.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const userGrnsActions = userGrnsSlice.actions;
export const { resetuserGrnsDetails } = userGrnsSlice.actions;

export default userGrnsSlice.reducer;
