import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import InspectionModuleContent from "./components/inspectionModuleContent";

function InspectionModule() {
  return (
    <div>
      <TitleHolder>Inspections </TitleHolder>
      <StepperContentHolder>
        <InspectionModuleContent />
      </StepperContentHolder>
    </div>
  );
}

export default InspectionModule;