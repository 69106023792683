import { Grid } from "@mui/material";
import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { AddButton } from "../bankDetails/components/bankAccountsContent";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import DepartmentTableContent from "./components/DepartmentTableContent";

function Department() {
  return (
    <div>
      <TitleHolder style={{ paddingBottom: "0" }}>
        <Grid container spacing={1}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Departments
          </Grid>
          <Grid item xs={6}>
            <div style={{ textAlign: "right" }}>
              <AddButton replace to="/app/create-department"></AddButton>
            </div>
          </Grid>
        </Grid>
      </TitleHolder>
      <br />
      <StepperContentHolder>
        <DepartmentTableContent />
      </StepperContentHolder>
    </div>
  );
}

export default Department;
