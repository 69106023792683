import {
  CardContent,
  Chip,
  Grid,
  Typography,
  TableHead,
  Tooltip,
} from "@mui/material";
import {
  AdminTitleHolder,
  CustomDocumentLink,
} from "../../../common/sharedComponents/styles/card.style";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { styled as materialStyles } from "@mui/material/styles";
import Card from "@mui/material/Card";
import styled from "styled-components";
import moment from "moment";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import InfoIcon from "@mui/icons-material/Info";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { getUserAsnDetails } from "../redux/asnModuleSlice";

function AsnModuleViewContent() {
  const { user_asn_details } = useSelector((state) => state.asnModule);

  const params = useParams();
  const dispatch = useDispatch();

  console.log("ASNS", user_asn_details);

  const asn_id = params.asnid;
  const viewAsnDetails = (asn_id) => {
    dispatch(getUserAsnDetails(asn_id));
  };

  useEffect(() => {
    viewAsnDetails(asn_id);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Advanced Shipping Notice#
            {user_asn_details.asn_number
              ? user_asn_details.asn_number
              : "Not Applicable"}
          </Grid>
          {user_asn_details?.status !== "Accepted" ? (
            ""
          ) : (
            <Grid item xs={6}>
              <Chip
                style={{
                  /*  height: "40px", */
                  width: "100px",
                  borderRadius: "25px",
                  backgroundColor: "var(--button-color)",
                  color: "white",
                  float: "right",
                }}
                label="Accepted"
              />
            </Grid>
          )}
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">COMPANY NAME</Typography>
              <VendorCardLabel>
                {user_asn_details.companyName
                  ? user_asn_details.companyName
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">LOT NUMBER</Typography>
              <VendorCardLabel>
                {user_asn_details.Lot_number
                  ? user_asn_details.Lot_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">ASN NUMBER</Typography>
              <VendorCardLabel>
                {user_asn_details.asn_number
                  ? user_asn_details.asn_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">EXPECTED ARRIVAL DATE</Typography>
              <VendorCardLabel>
                {user_asn_details.expected_arrival_date
                  ? moment(user_asn_details.expected_arrival_date).format(
                      "DD MMM YY"
                    )
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">CARRIER NAME</Typography>
              <VendorCardLabel>
                {user_asn_details.carrier_name
                  ? user_asn_details.carrier_name
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">PACKAGING TYPE</Typography>
              <VendorCardLabel>
                {user_asn_details.packaging_type
                  ? user_asn_details.packaging_type
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">HANDLING INSTRUCTIONS</Typography>
              <VendorCardLabel>
                {user_asn_details.handling_instructions
                  ? user_asn_details.handling_instructions
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <br />
          <VendorCardTitle>Item Details</VendorCardTitle>
          <CustomTableContainer component={Card}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#e1ebfa",
                  }}
                >
                  <TableCell width="10%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Sr. No.</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Item Name</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Quantity</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Unit Price</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Total Price</TableHeaderFont>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user_asn_details?.item_list ? (
                  user_asn_details?.item_list.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          width="10%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.item_name !== null
                            ? row.item_name
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.quantity !== null
                            ? row.quantity
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.unit_price !== null
                            ? row.unit_price
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {parseFloat(row.quantity * row.unit_price).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>Fetching Items...</TableRow>
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>
          <br />
          <br />
          <VendorCardTitle>Documents</VendorCardTitle>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">ASN Document</Typography>
              <VendorCardLabel>
                <Tooltip title="Download Document">
                  <CustomDocumentLink
                    href={user_asn_details?.asn_document}
                    target="_blank"
                  >
                    <InsertDriveFileOutlinedIcon
                      style={{
                        marginTop: "-1px",
                        marginRight: "3px",
                        height: "12px",
                        width: "12px",
                      }}
                    />
                    ASN document
                  </CustomDocumentLink>
                </Tooltip>
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Supporting Documents</Typography>
              {user_asn_details.supporting_document !== undefined &&
              user_asn_details.supporting_document !== null &&
              user_asn_details.supporting_document.length > 0 ? (
                user_asn_details.supporting_document.map((item, index) => (
                  <VendorCardLabel key={index}>
                    <Tooltip title="Download Document">
                      <CustomDocumentLink href={item} target="_blank">
                        <InsertDriveFileOutlinedIcon
                          style={{
                            marginTop: "-1px",
                            marginRight: "3px",
                            height: "12px",
                            width: "12px",
                          }}
                        />
                        Supporting document
                      </CustomDocumentLink>
                    </Tooltip>
                  </VendorCardLabel>
                ))
              ) : (
                <Typography className="label">
                  <InfoIcon
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />{" "}
                  There are no supporting documents
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    </>
  );
}

export default AsnModuleViewContent;

export const StyledCard = materialStyles(Card)`
  box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
  flex-direction: column;
  border: none;
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 1em;
  `;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;