import apiClient from "../../../utilities/apiClient";

export const get_pr_granted_users = () => {
  return apiClient.get(`${apiClient.Urls.get_pr_granted_users}`, "", true);
};

export const get_pr_pending_users = () => {
  return apiClient.get(`${apiClient.Urls.get_pr_pending_users}`, "", true);
};

export const get_pr_revoked_users = () => {
  return apiClient.get(`${apiClient.Urls.get_pr_revoked_users}`, "", true);
};

export const grant_pr_privilege = (payload) => {
  return apiClient.post(`${apiClient.Urls.grant_pr_privilege}`, payload, true);
};

export const revoke_pr_privilege = (payload) => {
  return apiClient.post(`${apiClient.Urls.revoke_pr_privilege}`, payload, true);
};
