import {
  CardContent,
  Grid,
  Typography,
  TableHead,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AdminTitleHolder } from "../../../common/sharedComponents/styles/card.style";
import { styled as materialStyles } from "@mui/material/styles";
import styled from "styled-components";
import {
  getAdminApprovedItemsPrDetails,
  pushPRToPg,
} from "../redux/adminPrsSlice";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import { Table, TableRow, TableCell, TableBody, Card } from "@mui/material";
import moment from "moment";
import LocalStorage from "../../../utilities/localStorage";
import { capitalizeString } from "../../../utilities/helper";
import { ApproveButton } from "../../vendorRegistration/components/ViewvendorDetails";
import { history } from "../../../app/history";
import { Company } from "../../../CONSTANTS";

function AdminPrsPushViewContent() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const clientId = LocalStorage.getItem("client").id;
  const { adminApprovedItemsPrDetails } = useSelector(
    (state) => state.adminPrs
  );
  const params = useParams();
  const dispatch = useDispatch();

  const pr_id = params.prid;
  const viewPrDetails = (pr_id) => {
    dispatch(getAdminApprovedItemsPrDetails(pr_id));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePush = (pr_id) => {
    setLoading(true);
    const payload = { pr_id: pr_id };
    dispatch(pushPRToPg(payload)).then((response) => {
      setLoading(false);
      setOpen(false);
      if (response.payload.status === 200) {
        history.push("/app/prs-list");
      }
    });
  };

  useEffect(() => {
    viewPrDetails(pr_id);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Push Review for PR#
            {adminApprovedItemsPrDetails.pr_number
              ? adminApprovedItemsPrDetails.pr_number
              : "Not Applicable"}
          </Grid>
          <Grid item xs={6}>
            <Tooltip title="Push PR to Sourcing Portal">
              <ApproveButton
                onClick={handleClickOpen}
                variant="outlined"
                style={{
                  height: "40px",
                  width: "150px",
                  float: "right",
                }}
              >
                Push
              </ApproveButton>
            </Tooltip>
          </Grid>
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">{Company.toUpperCase()}</Typography>
              <VendorCardLabel>
                {adminApprovedItemsPrDetails?.company_name
                  ? adminApprovedItemsPrDetails.company_name
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>

            <Grid item xs={6} md={6}>
              <Typography className="label">PR NUMBER</Typography>
              <VendorCardLabel>
                {adminApprovedItemsPrDetails.pr_number
                  ? adminApprovedItemsPrDetails.pr_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">DESIRED DELIVERY DATE</Typography>
              <VendorCardLabel>
                {adminApprovedItemsPrDetails.desired_delivery_date
                  ? moment(
                      adminApprovedItemsPrDetails.desired_delivery_date
                    ).format("DD MMM YY")
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">SHIPPING ADDRESS</Typography>
              <VendorCardLabel>
                {adminApprovedItemsPrDetails.shipping_address
                  ? adminApprovedItemsPrDetails.shipping_address
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            {clientId !== 5 ? (
              <>
                <Grid item xs={6} md={6}>
                  <Typography className="label">BILLING ADDRESS</Typography>
                  <VendorCardLabel>
                    {adminApprovedItemsPrDetails.billing_address
                      ? adminApprovedItemsPrDetails.billing_address
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>

                <Grid item xs={6} md={6}>
                  <Typography className="label">PREFERRED SUPPLIER</Typography>
                  <VendorCardLabel>
                    {adminApprovedItemsPrDetails.preferred_supplier
                      ? adminApprovedItemsPrDetails.preferred_supplier
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
              </>
            ) : (
              ""
            )}

            <Grid item xs={6} md={6}>
              <Typography className="label">REASON OF PURCHASE</Typography>
              <VendorCardLabel>
                {adminApprovedItemsPrDetails.reason_of_purchase
                  ? adminApprovedItemsPrDetails.reason_of_purchase
                  : "-"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">URGENCY LEVEL</Typography>
              <VendorCardLabel>
                {adminApprovedItemsPrDetails.urgency_level
                  ? capitalizeString(adminApprovedItemsPrDetails.urgency_level)
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">REMARKS</Typography>
              <VendorCardLabel>
                {adminApprovedItemsPrDetails.remarks
                  ? adminApprovedItemsPrDetails.remarks
                  : "-"}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <br />
          <VendorCardTitle>Item Details</VendorCardTitle>
          <CustomTableContainer component={Card}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#e1ebfa",
                  }}
                >
                  <TableCell width="10%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Sr. No.</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Item Description</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Req Qty</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>UOM</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Frequency</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Justification/Purpose</TableHeaderFont>
                  </TableCell>
                  <TableCell align="left">
                    <TableHeaderFont>Status</TableHeaderFont>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {adminApprovedItemsPrDetails?.item_list ? (
                  adminApprovedItemsPrDetails.item_list.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          width="10%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.item_name ? row.item_name : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.quantity ? row.quantity : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.uom ? row.uom : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.frequency ? row.frequency : "Not Applicable"}
                        </TableCell>

                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.purpose ? row.purpose : "-"}
                        </TableCell>
                        <TableCell>
                          {row.status !== null ? (
                            <Chip
                              label={row.material_status.toUpperCase()}
                              color={
                                row.material_status === "pending"
                                  ? "warning"
                                  : row.material_status === "approved"
                                  ? "success"
                                  : row.material_status === "rejected"
                                  ? "error"
                                  : "default"
                              }
                              size="small"
                              style={{ fontSize: "9px" }}
                            />
                          ) : (
                            "Not Applicable"
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <CircularProgress />
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>
        </CardContent>
      </StyledCard>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please Confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to push this Purchase Requisition to sourcing
            portal?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            style={{
              borderColor: "var(--button-color)",
              color: "black",
            }}
            size="small"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              float: "right",
              backgroundColor: "var(--button-color)",
              color: "white",
            }}
            color="success"
            onClick={(e) => handlePush(pr_id)}
            autoFocus
            size="small"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Push"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default AdminPrsPushViewContent;

export const StyledCard = materialStyles(Card)`
    box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
    flex-direction: column;
    border: none;
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 1em;
    `;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;
