import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  approve_asn,
  get_AdminSchedules,
  view_ScheduleDetails,
} from "./adminSchedulesAPI";

const initialState = {
  loading: false,
  adminSchedulesList: [],
  schedule_details: [],
};
const actions = {
  GETADMINSCHEDULES: "getAdminSchedules/GETADMINSCHEDULES",
  VIEWSCHEDULEDETAILS: "getScheduleDetails/VIEWSCHEDULEDETAILS",
  APPROVEASN: "approveAsn/APPROVEASN",
};

export const getAdminSchedules = createAsyncThunk(
  actions.GETADMINSCHEDULES,
  async (payload) => {
    const response = await get_AdminSchedules(payload);
    return response;
  }
);

export const getScheduleDetails = createAsyncThunk(
  actions.VIEWSCHEDULEDETAILS,
  async (payload) => {
    const response = await view_ScheduleDetails(payload);
    return response;
  }
);

export const approveAsn = createAsyncThunk(
  actions.APPROVEASN,
  async (payload) => {
    const response = await approve_asn(payload);
    return response;
  }
);
export const adminSchedulesSlice = createSlice({
  name: "adminSchedules",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminSchedules.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdminSchedules.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.adminSchedulesList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getAdminSchedules.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getScheduleDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getScheduleDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.schedule_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getScheduleDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(approveAsn.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveAsn.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(approveAsn.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const adminSchedulesActions = adminSchedulesSlice.actions;
export const { resetadminSchedulesDetails } = adminSchedulesSlice.actions;

export default adminSchedulesSlice.reducer;