import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import WorkflowContentView from "./components/WorkflowContentView";

function WorkflowContent() {
  return (
    <div>
      <TitleHolder>Workflows </TitleHolder>
      <StepperContentHolder>
        <WorkflowContentView />
      </StepperContentHolder>
    </div>
  );
}

export default WorkflowContent;