import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const sendInvitationLink = (payload) => {
  return apiClient.post(apiClient.Urls.invite_register_link, payload, true, "");
};
export const offcerList = (payload) => {
  return apiClient.get(
    apiClient.Urls.officerCompnayListForDropdown,
    payload,
    true
  );
};

export const company_list_for_pr = (payload) => {
  var role = LocalStorage.getItem("tata_login_user").roles[0];
  return apiClient.get(
    role === "officer"
      ? apiClient.Urls.officer_CompnayListForPr
      : apiClient.Urls.admin_CompnayListForPr,
    payload,
    true
  );
};
