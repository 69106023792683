import React from "react";
import { TitleHolder } from "../../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../../vendorRegistration/vendorRegistration";
import TenantEditContent from "./components/tenantEditContent";

const TenantEdit = () => {
  return (
    <div>
      <TitleHolder>Tenant Edit</TitleHolder>
      <StepperContentHolder>
        <TenantEditContent />
      </StepperContentHolder>
    </div>
  );
};

export default TenantEdit;
