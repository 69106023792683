import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  ActiveInactiveCompanies,
  company,
  getCompany,
  getCompanyDetails,
  getCompanyOfficers,
  getSubCompaniesDropdown,
  get_purchase_points,
} from "./companyAPI";

const initialState = {
  loading: false,
  companiesData: [],
  companyList: [],
  companyDetails: [],
  companyOfficersList: [],
  subCompanyDropdownList: [],
  purchasePointList: [],
  pagination: {},
};

const actions = {
  CREATECOMPANY: "craeteCompany/CREATECOMPANY",
  GETCOMPANIES: "getCompanies/GETCOMPANIES",
  VIEWCOMPANYDETAILS: "getAdminOrderDetails/VIEWADMINORDERDETAILS",
  COMPANYOFFICERSLIST: "companyOfficersList/COMPANYOFFICERSLIST",
  GETSUBCOMPANYDROPDOWN: "getSubCompaniesDropdown/GETSUBCOMPANYDROPDOWN",
  GETPURCHASEPOINTS: "getPurchasePoints/GETPURCHASEPOINTS",
  ACTIVEORINAVTIVECOMPANIES:
    "activeOrInactiveCompanies/ACTIVEORINAVTIVECOMPANIES",
};

export const craeteCompany = createAsyncThunk(
  actions.CREATECOMPANY,
  async (payload) => {
    const response = await company(payload);
    return response;
  }
);
export const getCompanies = createAsyncThunk(
  actions.GETCOMPANIES,
  async (payload) => {
    const response = await getCompany(
      payload?.page,
      payload?.per_page,
      payload?.search
    );
    return response;
  }
);
export const getCompaniesdetails = createAsyncThunk(
  actions.VIEWCOMPANYDETAILS,
  async (payload) => {
    const response = await getCompanyDetails(payload);
    return response;
  }
);
export const companyOfficersList = createAsyncThunk(
  actions.COMPANYOFFICERSLIST,
  async (payload) => {
    const response = await getCompanyOfficers(payload);
    return response;
  }
);
export const subCompanyDropdown = createAsyncThunk(
  actions.GETSUBCOMPANYDROPDOWN,
  async (payload) => {
    const response = await getSubCompaniesDropdown(payload);
    return response;
  }
);

export const getPurchasePoints = createAsyncThunk(
  actions.GETPURCHASEPOINTS,
  async (payload) => {
    const response = await get_purchase_points(payload);
    return response;
  }
);
export const ActiveOrInactiveCompanies = createAsyncThunk(
  actions.ACTIVEORINAVTIVECOMPANIES,
  async (payload) => {
    const response = await ActiveInactiveCompanies(payload);
    return response;
  }
);

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(craeteCompany.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(craeteCompany.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getCompanies.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        const {
          success,
          message: msg,
          data: { data, pagination },
        } = action.payload;
        state.loading = false;
        state.companiesData = data;
        state.pagination = pagination;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(getCompanies.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getCompaniesdetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompaniesdetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.companyDetails = data;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(getCompaniesdetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(companyOfficersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(companyOfficersList.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        if (success) {
          state.companyOfficersList = data;
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(companyOfficersList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(subCompanyDropdown.pending, (state) => {
        state.loading = true;
      })
      .addCase(subCompanyDropdown.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        if (success) {
          state.subCompanyDropdownList = data;
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(subCompanyDropdown.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getPurchasePoints.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPurchasePoints.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        if (success) {
          state.purchasePointList = data;
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(getPurchasePoints.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(ActiveOrInactiveCompanies.pending, (state) => {
        state.loading = true;
      })
      .addCase(ActiveOrInactiveCompanies.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(ActiveOrInactiveCompanies.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});

export const companyActions = companySlice.actions;
export const { resetcompanyDetails } = companySlice.actions;

export default companySlice.reducer;
