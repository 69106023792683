import {
  CardContent,
  Grid,
  Typography,
  TableHead,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorState, convertToRaw } from "draft-js";
import { useParams } from "react-router-dom";
import { AdminTitleHolder } from "../../../common/sharedComponents/styles/card.style";
import { styled as materialStyles } from "@mui/material/styles";
import Card from "@mui/material/Card";
import styled from "styled-components";
import { approveItem, getAdminItemDetails } from "../redux/adminPrsSlice";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import { Editor } from "react-draft-wysiwyg";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import moment from "moment";
import {
  ApproveButton,
  RejectButton,
} from "../../vendorRegistration/components/ViewvendorDetails";
import LocalStorage from "../../../utilities/localStorage";
import { history } from "../../../app/history";
import draftToHtml from "draftjs-to-html";

function AdminItemsViewContent() {
  const { adminItemDetails } = useSelector((state) => state.adminPrs);
  const [open, setOpen] = React.useState(false);
  const [openRej, setOpenRej] = React.useState(false);
  const [showApproveRejectButton, setShowApproveRejectButton] =
    React.useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateForApproval, setEditorStateForApproval] = useState(
    EditorState.createEmpty()
  );
  console.log("SUP", adminItemDetails);

  const params = useParams();
  const dispatch = useDispatch();

  var user = LocalStorage.getItem("tata_login_user");
  const user_id = user.user_id;

  const item_id = params.itemid;
  const viewItemDetails = (item_id) => {
    dispatch(getAdminItemDetails(item_id)).then((response) => {
      if (response.payload.success === true) {
        let data = response.payload.data;
        let steps = data.steps;
        steps.forEach((item) => {
          if (
            item.approver_id === user_id &&
            item.material_status === "pending" &&
            item.action_performed === "pending"
          ) {
            setShowApproveRejectButton(true);
          }
        });
      }
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenReject = () => {
    setOpenRej(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseRej = () => {
    setOpenRej(false);
  };
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const onEditorStateForApprovalChange = (editorStateForApproval) => {
    setEditorStateForApproval(editorStateForApproval);
  };

  useEffect(() => {
    viewItemDetails(item_id);
    // eslint-disable-next-line
  }, []);

  const handleApprove = (item_id) => {
    const payload = { item_ids: [item_id], is_approved: true };

    if (editorStateForApproval.getCurrentContent().hasText()) {
      payload["reason"] = draftToHtml(
        convertToRaw(editorStateForApproval.getCurrentContent())
      );
    } else {
      payload["reason"] = "";
    }
    dispatch(approveItem(payload)).then((response) => {
      setOpen(false);
      console.log(response);
      if (response.payload.success === true) {
        history.push(`/app/prs-list-view/${adminItemDetails.pr_id}`);
      }
    });
  };

  const handleReject = (item_id) => {
    let payload = { item_ids: [item_id], is_rejected: true };

    if (editorState.getCurrentContent().hasText()) {
      payload["reason"] = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
    } else {
      payload["reason"] = "";
    }
    dispatch(approveItem(payload)).then((response) => {
      // setOpenRej(false);
      console.log(response);
      if (response.payload.success === true) {
        history.push(`/app/prs-list-view/${adminItemDetails.pr_id}`);
      }
    });
  };
  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Item Details
          </Grid>
          <Grid item xs={6}>
            {showApproveRejectButton ? (
              <>
                <ApproveButton
                  onClick={handleClickOpen}
                  variant="outlined"
                  color="success"
                  style={{
                    height: "40px",
                    width: "150px",
                    float: "right",
                  }}
                >
                  Approve
                </ApproveButton>
                <RejectButton
                  onClick={handleClickOpenReject}
                  variant="outlined"
                  color="error"
                  style={{
                    height: "40px",
                    width: "150px",
                    float: "right",
                    marginInline: "1rem",
                  }}
                >
                  Reject
                </RejectButton>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">ITEM DESCRIPTION</Typography>
              <VendorCardLabel>
                {adminItemDetails.item_name
                  ? adminItemDetails.item_name
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">QUANTITY</Typography>
              <VendorCardLabel>
                {adminItemDetails.quantity
                  ? adminItemDetails.quantity
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">UNIT OF MEASUREMENT</Typography>
              <VendorCardLabel>
                {adminItemDetails.uom ? adminItemDetails.uom : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">FREQUENCY</Typography>
              <VendorCardLabel>
                {adminItemDetails.frequency
                  ? adminItemDetails.frequency
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">JUSTIFICATION / PURPOSE</Typography>
              <VendorCardLabel>
                {adminItemDetails.purpose
                  ? adminItemDetails.purpose
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <VendorCardTitle>Approval Status</VendorCardTitle>
          <CustomTableContainer component={Card}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#e1ebfa",
                  }}
                >
                  <TableCell width="10%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Step</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Step Status</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Action Performed</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Approver Email</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Reason</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Performed At</TableHeaderFont>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {adminItemDetails?.steps ? (
                  adminItemDetails.steps.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          width="10%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.step ? row.step : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          <Chip
                            label={row.material_status.toUpperCase()}
                            color={
                              row.material_status === "pending"
                                ? "warning"
                                : row.material_status === "approved"
                                ? "success"
                                : row.material_status === "rejected"
                                ? "error"
                                : "default"
                            }
                            size="small"
                            style={{ fontSize: "9px" }}
                          />
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          <Chip
                            label={row.action_performed?.toUpperCase()}
                            color={
                              row.action_performed === "pending"
                                ? "warning"
                                : row.action_performed === "approved"
                                ? "success"
                                : row.action_performed === "rejected"
                                ? "error"
                                : "default"
                            }
                            size="small"
                            style={{ fontSize: "9px" }}
                          />
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.approver_email
                            ? row.approver_email
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.reason ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: row.reason }}
                            />
                          ) : (
                            "-"
                          )}
                        </TableCell>

                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.updatedAt
                            ? moment(row.updatedAt).format("DD MMM YY HH:mm:ss")
                            : "Not Applicable"}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>Fetching Steps..</TableRow>
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Do you really want to approve?"}
            </DialogTitle>
            <DialogContent>
              <Editor
                editorState={editorStateForApproval}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateForApprovalChange}
                placeholder="Start typing a suggestion.."
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="outlined"
                style={{
                  borderColor: "rgb(28 182 151)",
                  color: "#0f0f0f",
                }}
                size="small"
                // color="success"
              >
                Cancel
              </Button>
              <ApproveButton
                variant="contained"
                style={{
                  float: "right",
                  backgroundColor: "var(--button-color)",
                  color: "white",
                }}
                onClick={(e) => handleApprove(item_id)}
                autoFocus
                size="small"
              >
                Approve
              </ApproveButton>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openRej}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Do you really want to reject?"}
            </DialogTitle>
            <DialogContent>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                placeholder="Start typing a remark.."
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseRej}
                variant="outlined"
                style={{
                  borderColor: "rgb(211 47 47)",
                  color: "#0f0f0f",
                }}
                size="small"
              >
                Cancel
              </Button>
              <RejectButton
                color="error"
                variant="contained"
                style={{
                  float: "right",
                }}
                onClick={(e) => handleReject(item_id)}
                autoFocus
                size="small"
              >
                Reject
              </RejectButton>
            </DialogActions>
          </Dialog>
        </CardContent>
      </StyledCard>
    </>
  );
}
export default AdminItemsViewContent;

export const StyledCard = materialStyles(Card)`
    box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
    flex-direction: column;
    border: none;
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 1em;
    `;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;
