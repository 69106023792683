import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const get_lot_numbers = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.inspection_lot_numbers}`,
    payload,
    false
  );
};

export const inspections = (payload) => {
  return apiClient.post(`${apiClient.Urls.inspections}`, payload, false);
};

export const get_inspections = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const user_id = user.user_id;
  return apiClient.get(
    `${apiClient.Urls.inspection_list}?user_id=${user_id}`,
    "",
    false
  );
};

export const get_gst = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_id = user.vendor_id;
  return apiClient.get(
    `${apiClient.Urls.gst_info}?vendor_id=${vendor_id}`,
    payload,
    true
  );
};

export const view_UserInspectionDetails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.view_UserInspectionDetails}/${payload}`,
    "",
    false
  );
};

export const get_items = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.get_lot_items_for_inspection}/${payload}`,
    "",
    false
  );
};