import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { approve_asn, get_AdminAsns, view_AsnDetails } from "./adminAsnsAPI";

const initialState = {
  loading: false,
  adminAsnsList: [],
  asn_details: [],
};
const actions = {
  GETADMINASNS: "getAdminAsns/GETADMINASNS",
  VIEWASNDETAILS: "getAsnDetails/VIEWASNDETAILS",
  APPROVEASN: "approveAsn/APPROVEASN",
};

export const getAdminAsns = createAsyncThunk(
  actions.GETADMINASNS,
  async (payload) => {
    const response = await get_AdminAsns(payload);
    console.log("response :", response);
    return response;
  }
);

export const getAsnDetails = createAsyncThunk(
  actions.VIEWASNDETAILS,
  async (payload) => {
    const response = await view_AsnDetails(payload);
    return response;
  }
);

export const approveAsn = createAsyncThunk(
  actions.APPROVEASN,
  async (payload) => {
    const response = await approve_asn(payload);
    return response;
  }
);
export const adminAsnsSlice = createSlice({
  name: "adminAsns",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminAsns.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdminAsns.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.adminAsnsList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getAdminAsns.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAsnDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAsnDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.asn_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getAsnDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(approveAsn.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveAsn.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(approveAsn.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const adminAsnsActions = adminAsnsSlice.actions;
export const { resetadminAsnsDetails } = adminAsnsSlice.actions;

export default adminAsnsSlice.reducer;
