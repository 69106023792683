import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  get_workflows,
  view_WorkflowDetails,
  view_PrWorkflowDetails,
  get_workflow_category_list,
  get_range_list,
  workflow_steps,
  get_approvers,
  get_persons,
  get_pr_creation_status,
  workflows,
  check_workflow_step_api,
  update_workflow_steps,
} from "./userWorkflowsAPI";
import LocalStorage from "../../../utilities/localStorage";

const initialState = {
  loading: false,
  workflowsList: [],
  workflow_details: {},
  pr_workflow_details: [],
  workflowCategoryList: [],
  rangeList: [],
  approvers: [],
  persons: [],
  prCreationStatus: [],
  stepDefined: true,
};
const actions = {
  GETWORKFLOWS: "getWorkflows/GETWORKFLOWS",
  GETAPPROVERS: "getApprovers/GETAPPROVERS",
  GETPERSONS: "getPersons/GETPERSONS",
  VIEWWORKFLOWDETAILS: "getWorkflowDetails/VIEWWORKFLOWDETAILS",
  VIEWPRWORKFLOWDETAILS: "getPrWorkflowDetails/VIEWPRWORKFLOWDETAILS",
  CREATEWORKFLOWSTEPS: "createWorkflowSteps/CREATEWORKFLOWSTEPS",
  UPDATEWORKFLOWSTEPS: "updateWorkflowSteps/UPDATEWORKFLOWSTEPS",
  GETPRCREATIONSTATUS: "getPrCreationStatus/GETPRCREATIONSTATUS",
  GETWORKFLOWCATEGORYLIST: "getWorkflowCategoryList/GETWORKFLOWCATEGORYLIST",
  GETRANGELIST: "getRangeList/GETRANGELIST",
  CREATEWORKFLOW: "createWorkflow/CREATEWORKFLOW",
  CHECKWORKFLOWSTEPDEFINED: "checkWorkflowStapDefined/CHECKWORKFLOWSTEPDEFINED",
};

export const createWorkflowSteps = createAsyncThunk(
  actions.CREATEWORKFLOWSTEPS,
  async (payload) => {
    const response = await workflow_steps(payload);
    return response;
  }
);
export const updateWorkflowSteps = createAsyncThunk(
  actions.UPDATEWORKFLOWSTEPS,
  async (payload) => {
    const response = await update_workflow_steps(payload);
    return response;
  }
);

export const createWorkflow = createAsyncThunk(
  actions.CREATEWORKFLOW,
  async (payload) => {
    const response = await workflows(payload);
    return response;
  }
);

export const getApprovers = createAsyncThunk(
  actions.GETAPPROVERS,
  async (payload) => {
    const response = await get_approvers(payload);
    return response;
  }
);

export const getWorkflowCategoryList = createAsyncThunk(
  actions.GETWORKFLOWCATEGORYLIST,
  async (payload) => {
    const response = await get_workflow_category_list(payload);
    return response;
  }
);

export const getRangeList = createAsyncThunk(
  actions.GETRANGELIST,
  async (payload) => {
    const response = await get_range_list(payload);
    return response;
  }
);

export const getPrCreationStatus = createAsyncThunk(
  actions.GETPRCREATIONSTATUS,
  async (payload) => {
    const response = await get_pr_creation_status(payload);
    return response;
  }
);

export const getPersons = createAsyncThunk(
  actions.GETPERSONS,
  async (payload) => {
    const response = await get_persons(payload);
    return response;
  }
);

export const getWorkflows = createAsyncThunk(
  actions.GETWORKFLOWS,
  async (payload) => {
    const response = await get_workflows(payload);
    return response;
  }
);

export const getWorkflowDetails = createAsyncThunk(
  actions.VIEWWORKFLOWDETAILS,
  async (payload) => {
    const response = await view_WorkflowDetails(payload);
    return response;
  }
);

export const getPrWorkflowDetails = createAsyncThunk(
  actions.VIEWPRWORKFLOWDETAILS,
  async () => {
    const response = await view_PrWorkflowDetails();
    return response;
  }
);
export const check_workflow_step_thunk = createAsyncThunk(
  actions.CHECKWORKFLOWSTEPDEFINED,
  async (payload) => {
    const response = await check_workflow_step_api(payload);
    return response;
  }
);

export const userWorkflowsSlice = createSlice({
  name: "userWorkflows",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWorkflows.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWorkflows.fulfilled, (state, action) => {
        const {
          success,
          message: msg,
          data: { data, pagination },
        } = action.payload;
        state.loading = false;
        state.workflowsList = data;
        state.pagination = pagination;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getWorkflows.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getWorkflowCategoryList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWorkflowCategoryList.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.workflowCategoryList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getWorkflowCategoryList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getRangeList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRangeList.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.rangeList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getRangeList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getApprovers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApprovers.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.approvers = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getApprovers.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });

    builder
      .addCase(getPersons.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPersons.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.persons = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getPersons.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });

    builder
      .addCase(getPrCreationStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrCreationStatus.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.prCreationStatus = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getPrCreationStatus.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });

    builder
      .addCase(getWorkflowDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWorkflowDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.workflow_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getWorkflowDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getPrWorkflowDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrWorkflowDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.pr_workflow_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          const clientId = LocalStorage.getItem("client")?.id;
          if (clientId !== 5) {
            message.error(msg);
          }
        }
      })
      .addCase(getPrWorkflowDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createWorkflowSteps.pending, (state) => {
        state.loading = true;
      })
      .addCase(createWorkflowSteps.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createWorkflowSteps.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(updateWorkflowSteps.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateWorkflowSteps.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(updateWorkflowSteps.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createWorkflow.pending, (state) => {
        state.loading = true;
      })
      .addCase(createWorkflow.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createWorkflow.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(check_workflow_step_thunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(check_workflow_step_thunk.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (!success) {
          state.stepDefined = false;
          message.error(msg);
        } else {
          state.stepDefined = true;
        }
      })
      .addCase(check_workflow_step_thunk.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const userWorkflowsActions = userWorkflowsSlice.actions;
export const { resetuserWorkflowsDetails } = userWorkflowsSlice.actions;

export default userWorkflowsSlice.reducer;
