import apiClient from "../../../utilities/apiClient";
// import LocalStorage from "../../../utilities/localStorage";

export const get_lot_details = (payload) => {
  return apiClient.post(`${apiClient.Urls.inspection_lot_details}`, payload);
};

export const create_inspection = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.create_inspection}`,
    payload,
    false,
    "file"
  );
};

export const check_token_status = (payload) => {
  return apiClient.post(`${apiClient.Urls.check_token_status}`, payload);
};