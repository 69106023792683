import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  offcerList,
  sendInvitationLink,
  company_list_for_pr,
} from "./sendLinkAPI";

const initialState = {
  loading: false,
  companyList: [],
  companyListForPr: [],
};

const actions = {
  SENDINVITATIONLINK: "sendInvitationLink/SENDINVITATIONLINK",
  COMPANYLISTFOROFFICER: "companyListForOfficer/COMPANYLISTFOROFFICER",
  GETCOMPANYLISTFORPR: "getCompanyListForPr/GETCOMPANYLISTFORPR",
};

export const invitationLink = createAsyncThunk(
  actions.SENDINVITATIONLINK,
  async (payload) => {
    const response = await sendInvitationLink(payload);
    return response;
  }
);
export const companyListForOfficer = createAsyncThunk(
  actions.COMPANYLISTFOROFFICER,
  async (payload) => {
    const response = await offcerList(payload);
    return response;
  }
);

export const getCompanyListForPr = createAsyncThunk(
  actions.GETCOMPANYLISTFORPR,
  async (payload) => {
    const response = await company_list_for_pr(payload);
    console.log("trespospdls: ", response);
    return response;
  }
);

export const sendInvitation = createSlice({
  name: "InvitationLink",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(invitationLink.pending, (state) => {
        state.loading = true;
      })
      .addCase(invitationLink.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = true;
        if (success === true) {
          state.loading = false;
          message.success(msg);
        } else {
          state.loading = false;
          message.error(msg);
        }
      })
      .addCase(invitationLink.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(companyListForOfficer.pending, (state) => {
        state.loading = true;
      })
      .addCase(companyListForOfficer.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        state.loading = true;
        if (success === true) {
          state.loading = false;
          state.companyList = data;
        } else {
          state.loading = false;
        }
      })
      .addCase(companyListForOfficer.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getCompanyListForPr.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyListForPr.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        state.loading = true;
        if (success === true) {
          state.loading = false;
          state.companyListForPr = data;
        } else {
          state.loading = false;
        }
      })
      .addCase(getCompanyListForPr.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});

export const sendInvitationActions = sendInvitation.actions;
export const { resetPending } = sendInvitation.actions;

export default sendInvitation.reducer;
