import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import LotModuleContent from "./components/lotModuleContent";

function LotModule() {
  return (
    <div>
      <TitleHolder>Lots</TitleHolder>
      <StepperContentHolder>
        <LotModuleContent />
      </StepperContentHolder>
    </div>
  );
}

export default LotModule;