import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { get_departments, departments } from "./departmentAPI";

const initialState = {
  loading: false,
  departmentsList: [],
};
const actions = {
  CREATEDEPARTMENT: "createDepartment/CREATEDEPARTMENT",
  GETDEPARTMENTS: "getDepartments/GETDEPARTMENTS",
};

export const createDepartment = createAsyncThunk(
  actions.CREATEDEPARTMENT,
  async (payload) => {
    const response = await departments(payload);
    return response;
  }
);

export const getDepartments = createAsyncThunk(
  actions.GETDEPARTMENTS,
  async (payload) => {
    const response = await get_departments(payload);
    return response;
  }
);

export const departmentModuleSlice = createSlice({
  name: "departmentModule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createDepartment.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDepartment.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createDepartment.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getDepartments.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDepartments.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.departmentsList = data;
        console.log(data);
        if (success) {
        } else {
          message.error(msg);
        }
      })
      .addCase(getDepartments.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const departmentsModuleActions = departmentModuleSlice.actions;
export const { resetDepartmentsModuleDetails } = departmentModuleSlice.actions;

export default departmentModuleSlice.reducer;
