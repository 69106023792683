import { Grid, Tooltip } from "@mui/material";
import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { AddButton } from "../bankDetails/components/bankAccountsContent";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import UserSchedulesTableContent from "./components/userSchedulesTableContent";

function UserSchedulesTable() {
  return (
    <div>
      <TitleHolder style={{ paddingBottom: "0" }}>
        <Grid container spacing={1}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Schedules
          </Grid>
          <Grid item xs={6}>
            <div style={{ textAlign: "right" }}>
              <Tooltip title="Create a Schedule">
                <AddButton replace to="/app/schedules"></AddButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </TitleHolder>
      <br />
      <StepperContentHolder>
        <UserSchedulesTableContent />
      </StepperContentHolder>
    </div>
  );
}

export default UserSchedulesTable;
