import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const get_banks = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_id = user.user_id;
  return apiClient.get(
    `${apiClient.Urls.bankinformation}/${vendor_id}`,
    payload,
    true
  );
};

export const get_schedule_numbers = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_id = user.vendor_id;
  return apiClient.get(
    `${apiClient.Urls.schedule_accept_numbers}?vendor_id=${vendor_id}`,
    payload,
    true
  );
};

export const lots = (payload) => {
  return apiClient.post(`${apiClient.Urls.lots}`, payload, true, "file");
};

export const get_asns = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_id = user.vendor_id;
  return apiClient.get(
    `${apiClient.Urls.asns_list}?vendor_id=${vendor_id}`,
    payload,
    true
  );
};

export const get_packaging_types = (payload) => {
  // var user = LocalStorage.getItem("tata_login_user");
  // const vendor_id = user.vendor_id;
  return apiClient.get(`${apiClient.Urls.packaging_type_info}`, payload, true);
};

export const view_UserAsnDetails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.view_user_asn_details}/${payload}`,
    "",
    true
  );
};

export const get_items = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.get_schedule_items}/?schedule_id=${payload}`,
    payload,
    true
  );
};