import apiClient from "../../../utilities/apiClient";

export const get_asns = (payload) => {
  return apiClient.get(`${apiClient.Urls.asn_list}`, payload, true);
};

export const get_asn_order = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.asn_list}?asn_id=${payload}`,
    "",
    true
  );
};

export const grns = (payload) => {
  return apiClient.post(`${apiClient.Urls.grns}`, payload, true, "file");
};

export const view_AdminGrnDetails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.view_admin_grn_details}/${payload}`,
    "",
    true
  );
};

export const get_grns = (payload) => {
  return apiClient.get(`${apiClient.Urls.grns_list}`, payload, true);
};

export const add_excel_data = (payload) => {
  return apiClient.post(`${apiClient.Urls.add_excel_grns}`, payload, true);
};