import React from "react";
import { TitleHolder } from "../../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../../vendorRegistration/vendorRegistration";
import UserEditContent from "./components/userEditContent";

const UserEdit = () => {
  return (
    <div>
      <TitleHolder>User Edit</TitleHolder>
      <StepperContentHolder>
        <UserEditContent />
      </StepperContentHolder>
    </div>
  );
};

export default UserEdit;
