import React, { useEffect, useState } from "react";
import {
  StyledCard,
  VendorCardLabel,
} from "../../../vendorRegistration/components/StepMain";
import {
  Box,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import {
  useEditTenantDetailsMutation,
  useGetTenantDetailsQuery,
} from "../../../../utilities/redux-api/api";
import LocalStorage from "../../../../utilities/localStorage";
import { useForm } from "react-hook-form";
import { VendorCardTitle } from "../../../adminOrders/components/adminOrdersViewContent";
import { LoadingButton } from "@mui/lab";
import { message } from "antd";
import { history } from "../../../../app/history";

const TenantEditContent = () => {
  const TenantId = LocalStorage.getItem("client")?.id;
  const [details, setDetails] = useState({});
  var { data, isLoading } = useGetTenantDetailsQuery(TenantId);
  const [editTenant, { isLoading: loading, isSuccess, isError, error }] =
    useEditTenantDetailsMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleOnChange = (e) => {
    e.persist();
    setDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSave = async (data) => {
    try {
      await editTenant({ ...data, id: details?.id }).unwrap();

      message.success("Tenant details updated successfully!");
      history.push("/app/support/tenant");
    } catch (error) {
      if (error?.data) {
        message.error(
          error.data.message || "An error occurred while updating data"
        );
      } else {
        message.error("An unexpected error occurred");
      }
    }
  };
  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Edit Details
                </VendorCardTitle>
              </Grid>
            </Grid>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                <CircularProgress sx={{ display: "block", margin: "0 auto" }} />
              </Box>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <VendorCardLabel>Company Name</VendorCardLabel>
                  <TextField
                    {...register("company_name", {
                      required: "Please Company name Name",
                      value: details?.company_name,
                    })}
                    error={Boolean(errors?.company_name)}
                    helperText={errors.company_name?.message}
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    InputProps={{
                      disableUnderline: false,
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    style={{
                      width: "100%",
                      paddingTop: "3px",
                      borderBottom: "1px solid lightgray",
                    }}
                    id="company_name"
                    value={details?.company_name}
                    placeholder="Enter company name"
                    onChange={handleOnChange}
                    name="company_name"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <VendorCardLabel>Domain URL</VendorCardLabel>
                  <TextField
                    {...register("domain_url", {
                      required: "Please Domain URL",
                      value: details?.domain_url,
                    })}
                    error={Boolean(errors?.domain_url)}
                    helperText={errors.domain_url?.message}
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    InputProps={{
                      disableUnderline: false,
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    style={{
                      width: "100%",
                      paddingTop: "3px",
                      borderBottom: "1px solid lightgray",
                    }}
                    id="domain_url"
                    value={details?.domain_url}
                    placeholder="Enter Domain URL"
                    onChange={handleOnChange}
                    name="domain_url"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <VendorCardLabel>SMTP Email</VendorCardLabel>
                  <TextField
                    {...register("smtp_email", {
                      required: "Please SMTP Email",
                      value: details?.smtp_email,
                    })}
                    error={Boolean(errors?.smtp_email)}
                    helperText={errors.smtp_email?.message}
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    InputProps={{
                      disableUnderline: false,
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    style={{
                      width: "100%",
                      paddingTop: "3px",
                      borderBottom: "1px solid lightgray",
                    }}
                    id="company_name"
                    value={details?.smtp_email}
                    placeholder="Enter SMTP Email"
                    onChange={handleOnChange}
                    name="smtp_email"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <VendorCardLabel>SMTP Port</VendorCardLabel>
                  <TextField
                    {...register("smtp_port", {
                      required: "Please SMTP Port",
                      value: details?.smtp_port,
                    })}
                    error={Boolean(errors?.smtp_port)}
                    helperText={errors.smtp_port?.message}
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    InputProps={{
                      disableUnderline: false,
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    style={{
                      width: "100%",
                      paddingTop: "3px",
                      borderBottom: "1px solid lightgray",
                    }}
                    id="smtp_port"
                    value={details?.smtp_port}
                    placeholder="Enter SMTP Port"
                    onChange={handleOnChange}
                    name="smtp_port"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <VendorCardLabel>SMTP Host</VendorCardLabel>
                  <TextField
                    {...register("smtp_host", {
                      required: "Please SMTP Host ",
                      value: details?.smtp_host,
                    })}
                    error={Boolean(errors?.smtp_host)}
                    helperText={errors.smtp_host?.message}
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    InputProps={{
                      disableUnderline: false,
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    style={{
                      width: "100%",
                      paddingTop: "3px",
                      borderBottom: "1px solid lightgray",
                    }}
                    id="smtp_host"
                    value={details?.smtp_host}
                    placeholder="Enter SMTP Host"
                    onChange={handleOnChange}
                    name="smtp_host"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <VendorCardLabel>SMTP Password</VendorCardLabel>
                  <TextField
                    {...register("smtp_password", {
                      required: "Please SMTP Password ",
                      value: details?.smtp_password,
                    })}
                    error={Boolean(errors?.smtp_password)}
                    helperText={errors.smtp_password?.message}
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    InputProps={{
                      disableUnderline: false,
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    style={{
                      width: "100%",
                      paddingTop: "3px",
                      borderBottom: "1px solid lightgray",
                    }}
                    id="smtp_password"
                    value={details?.smtp_password}
                    placeholder="Enter SMTP Password"
                    onChange={handleOnChange}
                    name="smtp_password"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <VendorCardLabel>SMTP Service</VendorCardLabel>
                  <TextField
                    {...register("smtp_service", {
                      required: "Please SMTP Service ",
                      value: details?.smtp_service,
                    })}
                    error={Boolean(errors?.smtp_service)}
                    helperText={errors.smtp_service?.message}
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    InputProps={{
                      disableUnderline: false,
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    style={{
                      width: "100%",
                      paddingTop: "3px",
                      borderBottom: "1px solid lightgray",
                    }}
                    id="smtp_service"
                    value={details?.smtp_service}
                    placeholder="Enter SMTP Service"
                    onChange={handleOnChange}
                    name="smtp_service"
                  />
                </Grid>
              </Grid>
            )}
          </CardContent>
        </StyledCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "200px",
              height: "40px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            Submit
          </LoadingButton>
        </Box>
      </form>
    </>
  );
};

export default TenantEditContent;
