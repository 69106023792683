import {
  CardContent,
  Grid,
  Typography,
  TableHead,
  Tooltip,
  Chip,
  // Button,
  Checkbox,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AdminTitleHolder,
  CustomDocumentLink,
} from "../../../common/sharedComponents/styles/card.style";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled as materialStyles } from "@mui/material/styles";
import Card from "@mui/material/Card";
import styled from "styled-components";
import { approveItem, getAdminPrDetails } from "../redux/adminPrsSlice";
import InfoIcon from "@mui/icons-material/Info";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import moment from "moment";
import LocalStorage from "../../../utilities/localStorage";
import { history } from "../../../app/history";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import {
  ApproveButton,
  RejectButton,
} from "../../vendorRegistration/components/ViewvendorDetails";
import { Editor } from "react-draft-wysiwyg";
import { capitalizeString } from "../../../utilities/helper";

function AdminPrsViewContent() {
  const clientId = LocalStorage.getItem("client").id;
  const { adminPrDetails } = useSelector((state) => state.adminPrs);
  console.log("adminPrDetails", adminPrDetails);

  const [open, setOpen] = React.useState(false);
  const [openRej, setOpenRej] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateForApproval, setEditorStateForApproval] = useState(
    EditorState.createEmpty()
  );
  const itemList = adminPrDetails?.item_list || [];
  console.log("itemList", itemList);

  const dispatch = useDispatch();

  const params = useParams();
  const pr_id = params.prid;

  const handleCheckboxChange = (id) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    const pendingItems = itemList
      .filter((item) => item.action_performed === "pending")
      .map((item) => item.id);
    if (selectedItems.length === pendingItems.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(pendingItems);
    }
  };

  const handleAcceptItems = () => {
    setLoading(true);
    const payload = { item_ids: selectedItems, is_approved: true };

    if (editorStateForApproval.getCurrentContent().hasText()) {
      payload["reason"] = draftToHtml(
        convertToRaw(editorStateForApproval.getCurrentContent())
      );
    } else {
      payload["reason"] = "";
    }
    dispatch(approveItem(payload)).then((response) => {
      setOpen(false);
      console.log(response);
      if (response.payload.success === true) {
        setLoading(false);
        history.push(`/app/prs-list`);
      }
    });
  };

  const handleRejectItems = () => {
    setLoading(true);
    let payload = { item_ids: selectedItems, is_rejected: true };

    if (editorState.getCurrentContent().hasText()) {
      payload["reason"] = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
    } else {
      payload["reason"] = "";
    }
    dispatch(approveItem(payload)).then((response) => {
      console.log(response);
      if (response.payload.success === true) {
        setOpenRej(false);
        setLoading(false);
        history.push(`/app/prs-list`);
      }
    });
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const onEditorStateForApprovalChange = (editorStateForApproval) => {
    setEditorStateForApproval(editorStateForApproval);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseRej = () => {
    setOpenRej(false);
  };

  const handleClickOpenReject = () => {
    setOpenRej(true);
  };

  const viewPrDetails = (pr_id) => {
    dispatch(getAdminPrDetails(pr_id));
  };

  useEffect(() => {
    viewPrDetails(pr_id);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            PR#
            {adminPrDetails?.pr_number
              ? adminPrDetails.pr_number
              : "Not Applicable"}
            &nbsp;
            <Chip
              label={adminPrDetails?.status?.toUpperCase()}
              color={
                adminPrDetails?.status === "pending"
                  ? "warning"
                  : adminPrDetails?.status === "completed"
                  ? "success"
                  : adminPrDetails?.status === "rejected"
                  ? "error"
                  : "default"
              }
              size="small"
              style={{ fontSize: "9px" }}
            />
          </Grid>
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <Grid container spacing={2}>
            {/* {clientId !== 5 ? (
              <>
                <Grid item xs={6} md={6}>
                  <Typography className="label">PROJECT</Typography>
                  <VendorCardLabel>
                    {adminPrDetails.project
                      ? adminPrDetails.project
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
              </>
            ) : (
              ""
            )} */}

            <Grid item xs={6} md={6}>
              <Typography className="label">PR NUMBER</Typography>
              <VendorCardLabel>
                {adminPrDetails.pr_number
                  ? adminPrDetails.pr_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">REQUISITIONER NAME</Typography>
              <VendorCardLabel>
                {adminPrDetails?.first_name
                  ? capitalizeString(adminPrDetails.first_name) +
                    " " +
                    capitalizeString(adminPrDetails.last_name)
                  : "-"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">REQUISITIONER EMAIL</Typography>
              <VendorCardLabel>
                {adminPrDetails?.contact_person_email
                  ? adminPrDetails.contact_person_email
                  : "-"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">COMPANY NAME</Typography>
              <VendorCardLabel>
                {adminPrDetails.company_name
                  ? adminPrDetails.company_name
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">DESIRED DELIVERY DATE</Typography>
              <VendorCardLabel>
                {adminPrDetails.desired_delivery_date
                  ? moment(adminPrDetails.desired_delivery_date).format(
                      "DD MMM YY"
                    )
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            {clientId === 5 ? (
              <>
                <Grid item xs={6} md={6}>
                  <Typography className="label">PR AMOUNT</Typography>
                  <VendorCardLabel>
                    {adminPrDetails.pr_amount
                      ? adminPrDetails.pr_amount
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className="label">EXPENSE TYPE</Typography>
                  <VendorCardLabel>
                    {adminPrDetails.expense_type
                      ? adminPrDetails.expense_type.toUpperCase()
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
              </>
            ) : (
              ""
            )}
            <Grid item xs={6} md={6}>
              <Typography className="label">SHIPPING ADDRESS</Typography>
              <VendorCardLabel>
                {adminPrDetails.shipping_address
                  ? adminPrDetails.shipping_address
                  : "-"}
              </VendorCardLabel>
            </Grid>
            {clientId !== 5 ? (
              <>
                <Grid item xs={6} md={6}>
                  <Typography className="label">BILLING ADDRESS</Typography>
                  <VendorCardLabel>
                    {adminPrDetails.billing_address
                      ? adminPrDetails.billing_address
                      : "-"}
                  </VendorCardLabel>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className="label">PREFERRED SUPPLIER</Typography>
                  <VendorCardLabel>
                    {adminPrDetails.preferred_supplier
                      ? adminPrDetails.preferred_supplier
                      : "-"}
                  </VendorCardLabel>
                </Grid>
              </>
            ) : (
              ""
            )}

            <Grid item xs={6} md={6}>
              <Typography className="label">REASON OF PURCHASE</Typography>
              <VendorCardLabel>
                {adminPrDetails.reason_of_purchase
                  ? adminPrDetails.reason_of_purchase
                  : "-"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">URGENCY LEVEL</Typography>
              <VendorCardLabel>
                {adminPrDetails.urgency_level
                  ? adminPrDetails.urgency_level.toUpperCase()
                  : "-"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">REMARKS</Typography>
              <VendorCardLabel>
                {adminPrDetails.remarks ? adminPrDetails.remarks : "-"}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <br />
          <VendorCardTitle>Item Details</VendorCardTitle>
          <CustomTableContainer component={Card}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "4px",
                gap: "4px",
              }}
            >
              <Tooltip title="Reject Selected Items">
                <IconButton
                  color="error"
                  onClick={handleClickOpenReject}
                  size="small"
                  disabled={selectedItems.length === 0}
                >
                  <CancelIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Approve Selected Items">
                <IconButton
                  color="success"
                  onClick={handleClickOpen}
                  size="small"
                  disabled={selectedItems.length === 0}
                >
                  <CheckCircleIcon />
                </IconButton>
              </Tooltip>
            </div>

            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#e1ebfa",
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedItems.length > 0 &&
                        selectedItems.length <
                          itemList.filter(
                            (item) => item.action_performed !== "pending"
                          ).length
                      }
                      checked={
                        itemList.filter(
                          (item) => item.action_performed === "pending"
                        ).length > 0 &&
                        selectedItems.length ===
                          itemList.filter(
                            (item) => item.action_performed === "pending"
                          ).length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell width="10%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Sr. No.</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Item Description</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Req Qty</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>UOM</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Frequency</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Justification/Purpose</TableHeaderFont>
                  </TableCell>
                  <TableCell align="left">
                    <TableHeaderFont>Workflow Status</TableHeaderFont>
                  </TableCell>
                  <TableCell align="left">
                    <TableHeaderFont>Action Performed by You</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Action</TableHeaderFont>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemList.length > 0 ? (
                  itemList.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedItems.includes(row.id)}
                            onChange={() => handleCheckboxChange(row.id)}
                            disabled={row.action_performed !== "pending"}
                          />
                        </TableCell>
                        <TableCell
                          width="10%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.item_name ? row.item_name : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.quantity ? row.quantity : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.uom ? row.uom : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.frequency ? row.frequency : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.purpose ? row.purpose : "-"}
                        </TableCell>
                        <TableCell>
                          {row.status !== null ? (
                            <Chip
                              label={row.material_status.toUpperCase()}
                              color={
                                row.material_status === "pending"
                                  ? "warning"
                                  : row.material_status === "approved"
                                  ? "success"
                                  : row.material_status === "rejected"
                                  ? "error"
                                  : "default"
                              }
                              size="small"
                              style={{ fontSize: "9px" }}
                            />
                          ) : (
                            "Not Applicable"
                          )}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {row?.action_performed ? (
                            <Chip
                              label={row?.action_performed.toUpperCase()}
                              color={
                                row?.action_performed === "pending"
                                  ? "warning"
                                  : row?.action_performed === "approved"
                                  ? "success"
                                  : row?.action_performed === "rejected"
                                  ? "error"
                                  : "default"
                              }
                              size="small"
                              style={{ fontSize: "9px" }}
                            />
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <Tooltip title="View Item">
                            <Link
                              style={{ textDecoration: "none" }}
                              // eslint-disable-next-line
                              to={"/app/items-list-view/" + `${row.id}`}
                            >
                              <RemoveRedEyeIcon
                                style={{
                                  fill: "royalblue",
                                  height: "18px",
                                  width: "18px",
                                }}
                              />
                            </Link>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>Fetching items..</TableRow>
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>
          <br />
          <br />
          <VendorCardTitle>Documents</VendorCardTitle>
          {clientId !== 5 ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  <Typography className="label">PR Document</Typography>
                  <VendorCardLabel>
                    {adminPrDetails.Pr_document ? (
                      adminPrDetails.Pr_document ? (
                        <Tooltip title="Download Document">
                          <CustomDocumentLink
                            href={adminPrDetails.Pr_document}
                            target="_blank"
                          >
                            <InsertDriveFileOutlinedIcon
                              style={{
                                marginTop: "-1px",
                                marginRight: "3px",
                                height: "12px",
                                width: "12px",
                              }}
                            />
                            Pr document
                          </CustomDocumentLink>
                        </Tooltip>
                      ) : (
                        <Typography className="label">
                          <InfoIcon
                            sx={{
                              marginTop: "-1px",
                              marginRight: "1px",
                              fill: "grey",
                              height: "15px",
                              width: "15px",
                            }}
                          />{" "}
                          There is no PR Document
                        </Typography>
                      )
                    ) : (
                      <Typography className="label">
                        <InfoIcon
                          sx={{
                            marginTop: "-1px",
                            marginRight: "1px",
                            fill: "grey",
                            height: "15px",
                            width: "15px",
                          }}
                        />{" "}
                        There is no PR Document
                      </Typography>
                    )}
                  </VendorCardLabel>
                </Grid>
              </Grid>
              <br />
            </>
          ) : (
            ""
          )}

          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Supporting Documents</Typography>
              {adminPrDetails?.Supporting_document !== undefined &&
              adminPrDetails?.Supporting_document !== null &&
              adminPrDetails?.Supporting_document.length > 0 ? (
                adminPrDetails?.Supporting_document.map((item, index) => (
                  <VendorCardLabel key={index}>
                    <Tooltip title="Download Document">
                      <CustomDocumentLink
                        key={index}
                        href={item}
                        target="_blank"
                      >
                        <InsertDriveFileOutlinedIcon
                          style={{
                            marginTop: "-1px",
                            marginRight: "3px",
                            height: "12px",
                            width: "12px",
                          }}
                        />
                        Supporting document
                      </CustomDocumentLink>
                    </Tooltip>
                  </VendorCardLabel>
                ))
              ) : (
                <Typography className="label">
                  <InfoIcon
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />{" "}
                  There are no supporting documents
                </Typography>
              )}
            </Grid>
          </Grid>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Do you really want to approve?"}
            </DialogTitle>
            <DialogContent>
              <Editor
                editorState={editorStateForApproval}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateForApprovalChange}
                placeholder="Start typing a suggestion.."
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="outlined"
                style={{
                  borderColor: "rgb(28 182 151)",
                  color: "#0f0f0f",
                }}
                size="small"
                // color="success"
              >
                Cancel
              </Button>
              <ApproveButton
                variant="contained"
                style={{
                  float: "right",
                  backgroundColor: "var(--button-color)",
                  color: "white",
                }}
                onClick={(e) => handleAcceptItems()}
                autoFocus
                size="small"
                disabled={loading}
              >
                Approve
              </ApproveButton>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openRej}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Do you really want to reject?"}
            </DialogTitle>
            <DialogContent>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                placeholder="Start typing a remark.."
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseRej}
                variant="outlined"
                style={{
                  borderColor: "rgb(211 47 47)",
                  color: "#0f0f0f",
                }}
                size="small"
              >
                Cancel
              </Button>
              <RejectButton
                color="error"
                variant="contained"
                style={{
                  float: "right",
                }}
                onClick={(e) => handleRejectItems()}
                autoFocus
                size="small"
                disabled={loading}
              >
                Reject
              </RejectButton>
            </DialogActions>
          </Dialog>
        </CardContent>
      </StyledCard>
    </>
  );
}
export default AdminPrsViewContent;

export const StyledCard = materialStyles(Card)`
  box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
  flex-direction: column;
  border: none;
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 1em;
  `;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;
