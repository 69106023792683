import React, { useEffect } from "react";
import { AdminTitleHolder } from "../../../common/sharedComponents/styles/card.style";
import {
  Box,
  Card,
  Grid,
  IconButton,
  TableFooter,
  TablePagination,
  useTheme,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  CustomTableContainer,
  CustomTableHead,
  TableContentVendorFont,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  RemoveRedEye,
  LastPage,
  FirstPage,
} from "@mui/icons-material";
import {
  ButtonLink,
  AddButton,
  DisplayText,
} from "../../bankDetails/components/bankAccountsContent";
import empty from "../../../assets/illo.png";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { companyOfficersList } from "../redux/companySlice";
import moment from "moment";
import { Company, company } from "../../../CONSTANTS";
const EmptyContainer = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
  >
    <br />
    <img src={empty} alt="empty" />

    <DisplayText> There are no {company} Officer to display</DisplayText>
    <br />
    <ButtonLink to="/app/add-user">+ Add Officer</ButtonLink>
    <br />

    <br />
  </Grid>
);

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <>
      <Box sx={{ flexShrink: 0 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
        </IconButton>
      </Box>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const CompanyOfficerTable = () => {
  const { companyOfficersList: OfficerList } = useSelector(
    (state) => state.company
  );
  const dispatch = useDispatch();
  const cid = useParams()?.company;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - OfficerList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(companyOfficersList(cid));
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Users List
          </Grid>
          <Grid item xs={6}>
            <div style={{ textAlign: "right" }}>
              <AddButton
                style={{ backgroundColor: "#4059e5" }}
                replace
                to="/app/add-user"
              ></AddButton>
            </div>
          </Grid>
        </Grid>
      </AdminTitleHolder>
      <CustomTableContainer component={Card}>
        <Table aria-label="simple table">
          <CustomTableHead>
            <TableRow>
              <TableCell>
                <TableHeaderFont>Full Name</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont>{Company} Name</TableHeaderFont>
              </TableCell>
              {/* <TableCell>
              <TableHeaderFont>PAN Number</TableHeaderFont>
            </TableCell> */}
              <TableCell>
                <TableHeaderFont>Contact Email</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont>Role</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont>Date</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont>Action</TableHeaderFont>
              </TableCell>
            </TableRow>
          </CustomTableHead>
          {OfficerList.lenght !== 0 ? (
            <TableBody>
              {(rowsPerPage > 0
                ? OfficerList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : OfficerList
              ).map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Link
                        style={{ textDecoration: "none" }}
                        // eslint-disable-next-line
                        to={"/app/users/" + `${row.id}`}
                      >
                        <TableContentVendorFont>
                          {row.first_name !== null
                            ? row.first_name
                            : "Not Applicable"}{" "}
                          {row.last_name !== null
                            ? row.last_name
                            : "Not Applicable"}
                        </TableContentVendorFont>
                      </Link>
                    </TableCell>
                    <TableCell>
                      {row.company_name !== null
                        ? row.company_name
                        : "Not Applicable"}
                    </TableCell>
                    {/* <TableCell>
                    {row.permanent_account_number !== null
                      ? row.permanent_account_number
                      : "Not Applicable"}
                  </TableCell> */}
                    <TableCell>
                      {row.contact_person_email !== null
                        ? row.contact_person_email
                        : "Not Applicable"}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {row.role_name !== null
                        ? row.role_name
                        : "Not Applicable"}
                    </TableCell>
                    <TableCell>
                      {row.createdAt !== null
                        ? moment(row.createdAt).format("DD MMM YY")
                        : "Not Applicable"}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Link
                        style={{ textDecoration: "none" }}
                        // eslint-disable-next-line
                        to={"/app/users/" + `${row.id}`}
                      >
                        <RemoveRedEye
                          style={{
                            fill: "royalblue",
                            height: "18px",
                            width: "18px",
                          }}
                        />
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 0 * emptyRows }}></TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <CustomTableContainer>
                    <EmptyContainer />
                  </CustomTableContainer>
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {/* table footer  */}
          <TableFooter style={{ borderTop: "1px solid lightgray" }}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={8}
                count={OfficerList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </CustomTableContainer>
    </div>
  );
};

export default CompanyOfficerTable;
