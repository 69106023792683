import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import {
  Box,
  Card,
  IconButton,
  useTheme,
  CircularProgress,
  Pagination,
  Grid,
} from "@mui/material";

import {
  CustomTableContainer,
  CustomTableHead,
  TableContentFont,
  TableContentVendorFont,
  TableHeaderFont,
} from "../../../../styles/tableContent.style";
import { useSelector } from "react-redux";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import PropTypes from "prop-types";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import moment from "moment";
import { Link } from "react-router-dom";
import { EmptyContainer } from "../../../../utilities/helper";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <Box sx={{ flexShrink: 0 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function ReturnedContent({ setPage, setPer_page, page, per_page }) {
  const { VendorData, loading, pagination } = useSelector(
    (state) => state.pendinglist
  );

  // // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - VendorData.length) : 0;

  const handleChangeRowsPerPage = (event) => {
    setPer_page(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <>
      <CustomTableContainer component={Card}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table /*  sx={{ minWidth: 650 }} */ aria-label="simple table">
            <CustomTableHead>
              <TableRow>
                <TableCell>
                  <TableHeaderFont>Vendor Name</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>PAN</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>GST</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Date</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Vendor ID</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Vendor Code</TableHeaderFont>
                </TableCell>
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {VendorData.length !== 0 ? (
                VendorData.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Link
                        style={{ textDecoration: "none" }}
                        // eslint-disable-next-line
                        to={"/app/vendor-registration-view/" + `${row.id}`}
                      >
                        <TableContentVendorFont>
                          {typeof row.company_name !== undefined &&
                          row.company_name !== null
                            ? `${row.company_name}`
                            : `Not Applicable`}
                        </TableContentVendorFont>
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {typeof row.permanent_account_number !== undefined &&
                        row.permanent_account_number !== null
                          ? `${row.permanent_account_number}`
                          : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont style={{ textTransform: "capitalize" }}>
                        {typeof row.gst_registration_type !== undefined &&
                        row.gst_registration_type !== null
                          ? `${row.gst_registration_type}`
                          : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {row.updatedAt !== null
                          ? moment(row.updatedAt).format("DD MMM YY")
                          : "Not Applicable"}
                      </TableContentFont>
                    </TableCell>

                    <TableCell align="left">
                      <TableContentFont> {row.id}</TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {typeof row.vendor_code !== undefined &&
                        row.vendor_code !== null
                          ? `${row.vendor_code}`
                          : "Not Applicable"}
                      </TableContentFont>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <CustomTableContainer>
                      <EmptyContainer text={"There is no data to display."} />
                    </CustomTableContainer>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
        <Grid
          container
          className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
          sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
        >
          <Grid className="d-flex align-items-center gap-2">
            <span>Rows per page: </span>
            <select
              value={per_page}
              onChange={(e) => setPer_page(e.target.value)}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </Grid>
          <Pagination
            sx={{
              width: "max-content",
            }}
            color="primary"
            variant="text"
            onChange={(_, newValue) => setPage(newValue)}
            count={pagination?.total_page}
            page={page}
            shape="rounded"
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
          />
        </Grid>
      </CustomTableContainer>
    </>
  );
}
export default ReturnedContent;

export function ReturnedContentForOfficer({
  setPage,
  setPer_page,
  page,
  per_page,
}) {
  const {
    returned,
    returned_pagination: pagination,
    loading,
  } = useSelector((state) => state.pendinglist);

  const handleChangeRowsPerPage = (event) => {
    setPer_page(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <>
      <CustomTableContainer component={Card}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table /*  sx={{ minWidth: 650 }} */ aria-label="simple table">
            <CustomTableHead>
              <TableRow>
                <TableCell>
                  <TableHeaderFont>Vendor Name</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>PAN</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>GST</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Date</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Vendor ID</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Vendor Code</TableHeaderFont>
                </TableCell>
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {returned.length !== 0 ? (
                returned.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Link
                        style={{ textDecoration: "none" }}
                        // eslint-disable-next-line
                        to={"/app/vendor-registration-view/" + `${row.id}`}
                      >
                        <TableContentVendorFont>
                          {typeof row.company_name !== undefined &&
                          row.company_name !== null
                            ? `${row.company_name}`
                            : `Not Applicable`}
                        </TableContentVendorFont>
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {typeof row.permanent_account_number !== undefined &&
                        row.permanent_account_number !== null
                          ? `${row.permanent_account_number}`
                          : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont style={{ textTransform: "capitalize" }}>
                        {typeof row.gst_registration_type !== undefined &&
                        row.gst_registration_type !== null
                          ? `${row.gst_registration_type}`
                          : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {row.updatedAt !== null
                          ? moment(row.updatedAt).format("DD MMM YY")
                          : "Not Applicable"}
                      </TableContentFont>
                    </TableCell>

                    <TableCell align="left">
                      <TableContentFont> {row.user_id}</TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {typeof row.vendor_code !== undefined &&
                        row.vendor_code !== null
                          ? `${row.vendor_code}`
                          : "Not Applicable"}
                      </TableContentFont>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <CustomTableContainer>
                      <EmptyContainer text={"There is no data to display."} />
                    </CustomTableContainer>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
        <Grid
          container
          className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
          sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
        >
          <Grid className="d-flex align-items-center gap-2">
            <span>Rows per page: </span>
            <select
              value={per_page}
              onChange={(e) => setPer_page(e.target.value)}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </Grid>
          <Pagination
            sx={{
              width: "max-content",
            }}
            color="primary"
            variant="text"
            onChange={(_, newValue) => setPage(newValue)}
            count={pagination?.total_page}
            page={page}
            shape="rounded"
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
          />
        </Grid>
      </CustomTableContainer>
    </>
  );
}
