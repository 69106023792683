import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTableData, inActiveActingSA } from "../redux/AdminAuthoritiesSlice";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  FirstPage,
} from "@mui/icons-material";
import {
  Box,
  Card,
  IconButton,
  TableFooter,
  TablePagination,
  useTheme,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Chip,
} from "@mui/material";
import PropTypes from "prop-types";
import {
  CustomTableContainer,
  CustomTableHead,
  TableContentVendorFont,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import LocalStorage from "../../../utilities/localStorage";
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <>
      <Box sx={{ flexShrink: 0 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
        </IconButton>
      </Box>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const AdminAuthoritieTableContent = () => {
  const tenant_id = LocalStorage.getItem("client")?.id;
  const { pending } = useSelector((state) => state.pendinglist);
  const { TableViewData } = useSelector((state) => state.addGlobleFinance);
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [userData, setUserData] = React.useState({});
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };

  const InactiveUser = () => {
    dispatch(inActiveActingSA(userData)).then(() => {
      dispatch(getTableData(""));
      handleClose();
    });
  };

  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  useEffect(() => {
    dispatch(getTableData(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pending.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <CustomTableContainer component={Card}>
      <Table aria-label="simple table">
        <CustomTableHead>
          <TableRow>
            <TableCell>
              <TableHeaderFont>Name</TableHeaderFont>
            </TableCell>
            <TableCell>
              <TableHeaderFont>Email</TableHeaderFont>
            </TableCell>
            <TableCell>
              <TableHeaderFont>Role</TableHeaderFont>
            </TableCell>
            <TableCell>
              <TableHeaderFont>Status</TableHeaderFont>
            </TableCell>
            {tenant_id !== 5 ? (
              <TableCell>
                <TableHeaderFont>Action</TableHeaderFont>
              </TableCell>
            ) : (
              ""
            )}
          </TableRow>
        </CustomTableHead>
        {TableViewData.length ? (
          <TableBody>
            {(rowsPerPage > 0
              ? TableViewData?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : TableViewData
            ).map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <TableContentVendorFont>
                    {row["User.first_name"] && row["User.last_name"]
                      ? `${row["User.first_name"]} ${row["User.last_name"]}`
                      : "Not Applicable"}
                  </TableContentVendorFont>
                </TableCell>
                <TableCell>
                  {row["User.contact_person_email"]
                    ? `${row["User.contact_person_email"]}`
                    : "Not Applicable"}
                </TableCell>
                <TableCell>
                  {row["Role.role_name"] !== null
                    ? `${row["Role.role_name"]}`
                    : "Not Applicable"}
                </TableCell>
                <TableCell>
                  <Chip
                    label={row.is_active ? "ACTIVE" : "INACTIVE"}
                    color={row.is_active ? "success" : "warning"}
                    size="small"
                    style={{ fontSize: "9px" }}
                  />
                </TableCell>
                {tenant_id !== 5 ? (
                  <TableCell>
                    <Button
                      variant="contained"
                      disabled={
                        row["Role.role_name"] !== "acting_sa" ? true : false
                      }
                      onClick={() => {
                        setOpen(true);
                        setUserData(row);
                      }}
                    >
                      {row.is_active ? "Inactive" : "Active"}
                    </Button>
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 0 * emptyRows }}></TableRow>
            )}
          </TableBody>
        ) : (
          <TableRow>
            <TableCell colSpan={4} style={{ textAlign: "center" }}>
              No Data Found
            </TableCell>
          </TableRow>
        )}

        <TableFooter style={{ borderTop: "1px solid lightgray" }}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={8}
              count={TableViewData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>

      {/* dialog box for conformation */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to make this user inactive?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={InactiveUser} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </CustomTableContainer>
  );
};

export default AdminAuthoritieTableContent;
