import {
  CardContent,
  Chip,
  Grid,
  Typography,
  TableHead,
  Tooltip,
} from "@mui/material";
import {
  AdminTitleHolder,
  CustomDocumentLink,
} from "../../../common/sharedComponents/styles/card.style";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { styled as materialStyles } from "@mui/material/styles";
import Card from "@mui/material/Card";
import styled from "styled-components";

import moment from "moment";

import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import InfoIcon from "@mui/icons-material/Info";
import { getUserInspectionDetails } from "../redux/inspectionModuleSlice";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

function InspectionModuleViewContent() {
  const { user_inspection_details } = useSelector(
    (state) => state.inspectionModule
  );

  const item_list = user_inspection_details.item_list || [];

  const params = useParams();
  const dispatch = useDispatch();

  console.log("user_inspection_details :", user_inspection_details);

  const inspection_id = params.inspectionid;
  const viewInspectionDetails = (inspection_id) => {
    dispatch(getUserInspectionDetails(inspection_id));
  };

  useEffect(() => {
    viewInspectionDetails(inspection_id);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Inspection#
            {user_inspection_details.inspection_number
              ? user_inspection_details.inspection_number
              : "Not Applicable"}
          </Grid>
          {user_inspection_details?.status !== "Completed" ? (
            ""
          ) : (
            <Grid item xs={6}>
              <Chip
                style={{
                  /*  height: "40px", */
                  width: "100px",
                  borderRadius: "25px",
                  backgroundColor: "var(--button-color)",
                  color: "white",
                  float: "right",
                }}
                label="Completed"
              />
            </Grid>
          )}
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">ORDER NUMBER</Typography>
              <VendorCardLabel>
                {user_inspection_details?.order_number
                  ? user_inspection_details?.order_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">INSPECTION NUMBER</Typography>
              <VendorCardLabel>
                {user_inspection_details?.inspection_number
                  ? user_inspection_details?.inspection_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">LOT NUMBER</Typography>
              <VendorCardLabel>
                {user_inspection_details?.lot_number
                  ? user_inspection_details?.lot_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label"> INVITEE EMAIL</Typography>
              <VendorCardLabel>
                {user_inspection_details?.invitee_email
                  ? user_inspection_details?.invitee_email
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">DATE OF INITIATION</Typography>
              <VendorCardLabel>
                {user_inspection_details.createdAt
                  ? moment(user_inspection_details.createdAt).format(
                      "DD MMM YY"
                    )
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <br />
          <VendorCardTitle>Checks Performed</VendorCardTitle>
          <CustomTableContainer component={Card}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#e1ebfa",
                  }}
                >
                  <TableCell width="10%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Sr. No.</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Item Name</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Packaging Type</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Type of Check</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Total Quantity</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Passed Quantity</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Failed Quantity</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Remarks</TableHeaderFont>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item_list.map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        width="10%"
                        style={{
                          fontFamily: "var(--font-family-reg)",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        width="20%"
                        style={{
                          fontFamily: "var(--font-family-reg)",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        {row.item_name !== null
                          ? row.item_name
                          : "Not Applicable"}
                      </TableCell>
                      <TableCell
                        width="10%"
                        style={{
                          fontFamily: "var(--font-family-reg)",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        {row.packaging_type !== null
                          ? row.packaging_type
                          : "Not Applicable"}
                      </TableCell>
                      <TableCell
                        width="10%"
                        style={{
                          fontFamily: "var(--font-family-reg)",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        {row.type !== null ? row.type : "Not Applicable"}
                      </TableCell>
                      <TableCell
                        width="10%"
                        style={{
                          fontFamily: "var(--font-family-reg)",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        {row.quantity !== null
                          ? row.quantity
                          : "Not Applicable"}
                      </TableCell>
                      <TableCell
                        width="10%"
                        style={{
                          fontFamily: "var(--font-family-reg)",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        {row.passed !== null ? row.passed : "Not Applicable"}
                      </TableCell>
                      <TableCell
                        width="10%"
                        style={{
                          fontFamily: "var(--font-family-reg)",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        {row.failed !== null ? row.failed : "Not Applicable"}
                      </TableCell>
                      <TableCell
                        width="20%"
                        style={{
                          fontFamily: "var(--font-family-reg)",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        {row.remarks !== null ? row.remarks : "Not Applicable"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CustomTableContainer>
          <br />
          <br />
          <VendorCardTitle>Documents</VendorCardTitle>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Inspection Document</Typography>
              <VendorCardLabel>
                <Tooltip title="Download Document">
                  <CustomDocumentLink
                    href={user_inspection_details?.inspection_document}
                    target="_blank"
                  >
                    <InsertDriveFileOutlinedIcon
                      style={{
                        marginTop: "-1px",
                        marginRight: "3px",
                        height: "12px",
                        width: "12px",
                      }}
                    />
                    Inspection document
                  </CustomDocumentLink>
                </Tooltip>
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Supporting Documents</Typography>
              {user_inspection_details?.supporting_document !== undefined &&
              user_inspection_details?.supporting_document !== null &&
              user_inspection_details?.supporting_document.length > 0 ? (
                user_inspection_details?.supporting_document.map(
                  (item, index) => (
                    <VendorCardLabel key={index}>
                      <Tooltip title="Download Document">
                        <CustomDocumentLink href={item} target="_blank">
                          <InsertDriveFileOutlinedIcon
                            style={{
                              marginTop: "-1px",
                              marginRight: "3px",
                              height: "12px",
                              width: "12px",
                            }}
                          />
                          Supporting document
                        </CustomDocumentLink>
                      </Tooltip>
                    </VendorCardLabel>
                  )
                )
              ) : (
                <Typography className="label">
                  <InfoIcon
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />{" "}
                  There are no supporting documents
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    </>
  );
}

export default InspectionModuleViewContent;

export const StyledCard = materialStyles(Card)`
    box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
    flex-direction: column;
    border: none;
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 1em;
    `;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;
