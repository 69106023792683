import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  countriesApi,
  createbank,
  statesApi,
  banksApi,
  validateBank,
  removeBankApi,
} from "./bankDetailsAPI";

const initialState = {
  loading: false,
  runApi: false,
  token: "",
  countries: [],
  states: [],
  countrycurrency: {},
  banks: [],
  bankdetails: [],
};

const actions = {
  ADDBANKDETAILS: "addbankdetails/ADDBANKDETAILS",
  GETCOUNTRIES: "getcountries/GETCOUNTRIES",
  GETSTATES: "getstates/GETSTATES",
  GETBANKS: "getBanks/GETBANKS",
  VALIDATEBANK: "validatebank/VALIDATEBANK",
  REMOVEBANK: "removeBank/REMOVEBANK",
};

export const addbank = createAsyncThunk(
  actions.ADDBANKDETAILS,
  async (payload) => {
    const response = await createbank(payload);
    return response;
  }
);

export const validatebank = createAsyncThunk(
  actions.VALIDATEBANK,
  async (payload) => {
    const response = await validateBank(payload);
    return response;
  }
);

export const getStates = createAsyncThunk(
  actions.GETSTATES,
  async (payload) => {
    const response = await statesApi(payload);
    return response;
  }
);
export const getCountries = createAsyncThunk(
  actions.GETCOUNTRIES,
  async (payload) => {
    const response = await countriesApi(payload);
    return response;
  }
);

export const getBanks = createAsyncThunk(
  actions.GETBANKS,
  async (vendor_id) => {
    const response = await banksApi(vendor_id);
    return response;
  }
);
export const removeBank = createAsyncThunk(
  actions.REMOVEBANK,
  async (payload) => {
    const response = await removeBankApi(payload);
    return response;
  }
);
export const bankDetailsSlice = createSlice({
  name: "bankdetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addbank.pending, (state) => {
        state.loading = true;
      })
      .addCase(addbank.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addbank.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getCountries.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;

        state.countries = data;
        // if (success) {
        //   message.success(msg);
        // } else {
        //   message.error(msg);
        // }
      })
      .addCase(getCountries.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getStates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStates.fulfilled, (state, action) => {
        const { data, cuurency } = action.payload;
        state.loading = false;
        state.states = data;
        state.countrycurrency = cuurency;
      })
      .addCase(getStates.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getBanks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBanks.fulfilled, (state, action) => {
        const { data, cuurency } = action.payload;
        state.loading = false;
        state.states = data;
        state.countrycurrency = cuurency;
        state.runApi = false;
      })
      .addCase(getBanks.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(validatebank.pending, (state) => {
        state.loading = true;
      })
      .addCase(validatebank.fulfilled, (state, action) => {
        const { data, success, message: msg } = action.payload;
        state.loading = false;

        state.bankdetails = data;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(validatebank.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(removeBank.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeBank.fulfilled, (state, action) => {
        const { data, success, message: msg } = action.payload;
        state.loading = false;

        state.bankdetails = data;
        state.runApi = true;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(removeBank.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const bankDetailsActions = bankDetailsSlice.actions;
export const { resetbankDetails } = bankDetailsSlice.actions;

export default bankDetailsSlice.reducer;
