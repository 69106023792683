import {
  CustomTableContainer,
  CustomTableHead,
  TableContentVendorFont,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Pagination,
  Tooltip,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import moment from "moment";
// for EmptyContainer
import empty from "../../../assets/illo.png";
import {
  ButtonLink,
  DisplayText,
} from "../../bankDetails/components/bankAccountsContent";
import { useDispatch, useSelector } from "react-redux";
import { ActiveOrInactiveCompanies, getCompanies } from "../redux/companySlice";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { RemoveRedEye, ToggleOnSharp } from "@mui/icons-material";
import LocalStorage from "../../../utilities/localStorage";
import { CompaniesLabel, CompanyLabel } from "../../../CONSTANTS";
import { dev } from "../../../utilities/helper";
const EmptyContainer = () => {
  const ClientId = LocalStorage.getItem("client")?.id;
  const Companies = CompaniesLabel(ClientId);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <br />
      <img src={empty} alt="empty" />

      <DisplayText>
        There are no {Companies.toLocaleLowerCase()} to display
      </DisplayText>
      <br />
      <ButtonLink to="/app/add-company">+ Add {Companies}</ButtonLink>
      <br />

      <br />
    </Grid>
  );
};

function CompanyTableContent({ search, clicked, setClicked }) {
  const [page, setPage] = React.useState(1);
  const [per_page, setPer_page] = React.useState(10);
  const [reload, setreload] = React.useState(false);

  const { companiesData, pagination, loading } = useSelector(
    (state) => state.company
  );

  const ClientId = LocalStorage.getItem("client")?.id;
  const Companies = CompaniesLabel(ClientId);
  const Company = CompanyLabel(ClientId);

  const dispatch = useDispatch();

  const getCompany = () => {
    const payload = {
      per_page,
      page,
      search,
    };
    dispatch(getCompanies(payload));
    setClicked(false);
    setreload(false);
  };

  const ActiveInActiveHandle = (payload) => {
    dispatch(ActiveOrInactiveCompanies(payload)).then((data) => {
      if (data.payload.success) {
        setreload(true);
      }
    });
  };
  useEffect(() => {
    getCompany();
    // eslint-disable-next-line
  }, [page, per_page, clicked, reload]);
  useEffect(() => {
    setPage(1);
  }, [per_page, clicked]);

  const handleChangeRowsPerPage = (event) => {
    setPer_page(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <CustomTableContainer component={Card}>
      <Table aria-label="simple table">
        <CustomTableHead>
          <TableRow>
            <TableCell>
              <TableHeaderFont>{Company} Name</TableHeaderFont>
            </TableCell>
            {ClientId === 2 ? (
              <>
                <TableCell>
                  <TableHeaderFont>{Company} Code</TableHeaderFont>
                </TableCell>
                <TableCell>
                  <TableHeaderFont>{Company} Location</TableHeaderFont>
                </TableCell>
              </>
            ) : (
              <TableCell>
                <TableHeaderFont>{Company} Email</TableHeaderFont>
              </TableCell>
            )}
            {ClientId !== 5 ? (
              <>
                <TableCell>
                  <TableHeaderFont>PAN Number</TableHeaderFont>
                </TableCell>
                <TableCell>
                  <TableHeaderFont>Contact Email</TableHeaderFont>
                </TableCell>
              </>
            ) : (
              <TableCell>
                <TableHeaderFont>Parent {Company}</TableHeaderFont>
              </TableCell>
            )}

            <TableCell>
              <TableHeaderFont>Date</TableHeaderFont>
            </TableCell>
            <TableCell>
              <TableHeaderFont>Action</TableHeaderFont>
            </TableCell>
          </TableRow>
        </CustomTableHead>
        <TableBody>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : companiesData.length !== 0 ? (
            companiesData.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Link
                      style={{ textDecoration: "none" }}
                      // eslint-disable-next-line
                      to={"/app/company/" + `${row.id}`}
                    >
                      <TableContentVendorFont>
                        {row.company_name !== null
                          ? row.company_name
                          : "Not Applicable"}
                      </TableContentVendorFont>
                    </Link>
                  </TableCell>
                  {ClientId === 2 ? (
                    <>
                      <TableCell>
                        {row.company_code !== null
                          ? row.company_code
                          : "Not Applicable"}
                      </TableCell>
                      <TableCell>
                        {row.company_location !== null
                          ? row.company_location
                          : "Not Applicable"}
                      </TableCell>
                    </>
                  ) : (
                    <TableCell>
                      {row.company_email !== null
                        ? row.company_email
                        : "Not Applicable"}
                    </TableCell>
                  )}
                  {ClientId !== 5 ? (
                    <>
                      <TableCell>
                        {row.permanent_account_number !== null
                          ? row.permanent_account_number
                          : "Not Applicable"}
                      </TableCell>
                      <TableCell>
                        {row.contact_person_email !== null
                          ? row.contact_person_email
                          : "Not Applicable"}
                      </TableCell>
                    </>
                  ) : (
                    <TableCell>
                      {row.parent_company !== null
                        ? row.parent_company
                        : "Not Applicable"}
                    </TableCell>
                  )}

                  <TableCell>
                    {row.createdAt !== null
                      ? moment(row.createdAt).format("DD MMM YY")
                      : "Not Applicable"}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Link
                          style={{ textDecoration: "none" }}
                          // eslint-disable-next-line
                          to={"/app/company/" + `${row.id}`}
                        >
                          <RemoveRedEye
                            style={{
                              fill: "royalblue",
                              height: "18px",
                              width: "18px",
                            }}
                          />
                        </Link>
                      </Grid>
                      {ClientId === 5 && dev ? (
                        <Grid item>
                          <Tooltip
                            title={
                              row?.is_active
                                ? `Inactive ${Company}`
                                : `Active ${Company}`
                            }
                          >
                            <IconButton
                              onClick={() =>
                                ActiveInActiveHandle({
                                  is_active: !row?.is_active,
                                  cid: row.id,
                                })
                              }
                            >
                              <ToggleOnSharp
                                color={row?.is_active ? "success" : "error"}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={8}>
                <CustomTableContainer>
                  <EmptyContainer
                    text={`There are no ${Companies.toLocaleLowerCase()} to display`}
                  />
                </CustomTableContainer>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Grid
        container
        className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
        sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
      >
        <Grid className="d-flex align-items-center gap-2">
          <span>Rows per page: </span>
          <select
            value={per_page}
            onChange={(e) => setPer_page(e.target.value)}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </Grid>
        <Pagination
          sx={{
            width: "max-content",
          }}
          color="primary"
          variant="text"
          onChange={(_, newValue) => setPage(newValue)}
          count={pagination?.total_page}
          page={page}
          shape="rounded"
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </Grid>
    </CustomTableContainer>
  );
}

export default CompanyTableContent;
