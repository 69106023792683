import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  approve_order,
  get_schedules,
  view_ScheduleDetails,
} from "./userSchedulesAPI";

const initialState = {
  loading: false,
  schedulesList: [],
  schedule_details: [],
};
const actions = {
  GETSCHEDULES: "getSchedules/GETSCHEDULES",
  VIEWSCHEDULEDETAILS: "getScheduleDetails/VIEWSCHEDULEDETAILS",
  APPROVEORDER: "approveOrder/APPROVEORDER",
};

export const getSchedules = createAsyncThunk(
  actions.GETSCHEDULES,
  async (payload) => {
    const response = await get_schedules(payload);
    return response;
  }
);

export const getScheduleDetails = createAsyncThunk(
  actions.VIEWSCHEDULEDETAILS,
  async (payload) => {
    const response = await view_ScheduleDetails(payload);
    return response;
  }
);

export const approveOrder = createAsyncThunk(
  actions.APPROVEORDER,
  async (payload) => {
    const response = await approve_order(payload);
    return response;
  }
);

export const userSchedulesSlice = createSlice({
  name: "userSchedules",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSchedules.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSchedules.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.schedulesList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getSchedules.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getScheduleDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getScheduleDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.schedule_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getScheduleDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(approveOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveOrder.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(approveOrder.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const userSchedulesActions = userSchedulesSlice.actions;
export const { resetuserSchedulesDetails } = userSchedulesSlice.actions;

export default userSchedulesSlice.reducer;
