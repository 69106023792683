import apiClient from "../../../utilities/apiClient";

export const item = (payload) => {
  return apiClient.post(`${apiClient.Urls.item}`, payload, true);
};

export const getHsns = (payload) => {
  // var user_role = LocalStorage.getItem("tata_login_user").roles[0];
  return apiClient.get(`${apiClient.Urls.getHsns}?search=${payload}`, "", true);
};

export const getItem = (page, per_page, search) => {
  return apiClient.get(
    `${apiClient.Urls.item_list}?page=${page}&per_page=${per_page}&search=${
      search || ""
    }`,
    "",
    true
  );
};

export const upload_item = (payload) => {
  return apiClient.post(`${apiClient.Urls.item_upload}`, payload, true, "file");
};
