import React, { useEffect, useState } from "react";
import { FormLinkHeader } from "../../../../assets/fonts/typography.style";
import { ButtonContainer } from "../../../../common/sharedComponents/styles/authRight.style";
import IndianSignupRight from "./IndianSignupRight";
import IndianSignupPanRight from "./IndianSignupPanRight";
import { IconSpan } from "../forms/IndianSignup";
import nexticon from "../../../../assets/next.png";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { signup, token } from "../../redux/signup/signupSlice";
// import LocalStorage from "../../../../utilities/localStorage";
import { tenant } from "../../../login/redux/loginSlice";
import { domainName } from "../../../../utilities/helper";
// import { bank_info } from "../../../../utilities/helper";
import { LoadingButton } from "@mui/lab";

const steps = [
  {
    content: <IndianSignupRight />,
  },
  {
    content: <IndianSignupPanRight />,
  },
];
// { loading }
function IndianSignUpStepper() {
  const { client } = useSelector((state) => state.login);
  const { loading } = useSelector((state) => state.signup);
  const dispatch = useDispatch();

  const onSubmit = (payload) => {
    dispatch(signup(payload)).then((data) => {
      if (data.payload.success === true) {
        setBasic(payload);
        setCurrent(1);
      }
    });
  };

  const [form] = Form.useForm();
  const [basic, setBasic] = useState("");
  const search = useLocation().search;

  const inviteToken = new URLSearchParams(search).get("token");
  const inviteEmail = new URLSearchParams(search).get("email");

  useEffect(() => {
    dispatch(token({ email: inviteEmail, token: inviteToken }));
    dispatch(tenant({ domain_url: domainName }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    if (basic !== "") {
      values["company_name"] = basic.company_name;
      values["contact_person_email"] = basic.contact_person_email;
      values["first_name"] = basic.first_name;
      values["last_name"] = basic.last_name;
      values["is_domestic"] = true;
      values["token"] = inviteToken;
      values["tokenEmail"] = inviteEmail;
      values["tenant_id"] = client.id;
      onSubmit(values);
    } else {
      values["tenant_id"] = client.id;
      values["is_domestic"] = true;
      values["token"] = inviteToken;
      values["tokenEmail"] = inviteEmail;
      onSubmit(values);
    }
  };

  const [current, setCurrent] = useState(0);

  const next = () => {};
  // useEffect(
  //   (current) => {
  //     console.log(success);
  //     if (success === true) {
  //       return setCurrent(1);
  //     }
  //   },
  //   [success]
  // );
  return (
    <Form form={form} onFinish={onFinish}>
      <div>{steps[current].content}</div>
      <div>
        {current < steps.length - 1 && (
          <ButtonContainer>
            <FormLinkHeader>
              <button
                loading={loading}
                disabled={loading}
                type="submit"
                onClick={() => next()}
              >
                Next{" "}
                <IconSpan>
                  <img alt="icon" src={nexticon} />
                </IconSpan>
              </button>
            </FormLinkHeader>
          </ButtonContainer>
        )}
        {current > 0 && (
          <ButtonContainer>
            <FormLinkHeader>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={loading}
                loadingPosition="start"
              >
                Sign up
              </LoadingButton>
              {/* <button loading={loading} disabled={loading} type="submit">
                Sign up
              </button> */}
            </FormLinkHeader>
          </ButtonContainer>
        )}
      </div>
    </Form>
  );
}

export default IndianSignUpStepper;
