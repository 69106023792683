import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import OfficerCreateVendorContent from "./components/OfficerCreateVendorContent";
import { useSelector } from "react-redux";
// import { check_workflow_step_thunk } from "../workflows/redux/userWorkflowsSlice";
import { history } from "../../app/history";

const OfficerCreateVendor = () => {
  const { stepDefined } = useSelector((state) => state.userWorkflows);
  if (!stepDefined) {
    history.back();
  }

  return (
    <div>
      <TitleHolder>Vendor Registration by Officer</TitleHolder>
      <StepperContentHolder>
        <OfficerCreateVendorContent />
      </StepperContentHolder>
    </div>
  );
};

export default OfficerCreateVendor;
