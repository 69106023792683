import React from "react";
import { AdminTitleHolder } from "../../../../common/sharedComponents/styles/card.style";
import { Button, Grid, Tooltip } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import ViewComponent from "../../../../common/sharedComponents/View";
import { useGetUserDetailsQuery } from "../../../../utilities/redux-api/api";
import { Edit } from "@mui/icons-material";
import { support_role_endpoint } from "../../../../utilities/redux-api/endpoint";

const UserDetailsContent = () => {
  const id = useParams().id;
  console.log("id: ", id);

  const { data, isLoading } = useGetUserDetailsQuery(id);
  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            User#{id}
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
            <Tooltip title="Edit Details">
              <Link to={`/app/${support_role_endpoint.user}/edit/${id}`}>
                <Button variant="outlined">
                  <Edit />
                </Button>
              </Link>
            </Tooltip>
          </Grid>
        </Grid>
      </AdminTitleHolder>
      {!isLoading && (
        <ViewComponent
          data={data}
          ignoredFields={[
            "id",
            "UsersRoles",
            "creater_id",
            "tenant_id",
            "is_domestic",
            "is_verified",
            "is_owner",
            "country_id",
            "expire_token",
            "reset_token",
          ]}
        />
      )}
    </>
  );
};

export default UserDetailsContent;
