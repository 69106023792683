import React from "react";
import FormComponent from "../../../../common/sharedComponents/Form";
import {
  useGetCompanyListForDropdownQuery,
  useGetUserDetailsQuery,
  useUpdateUserDetailsMutation,
} from "../../../../utilities/redux-api/api";
import { useParams } from "react-router-dom";
import { history } from "../../../../app/history";
import { support_role_endpoint } from "../../../../utilities/redux-api/endpoint";
import { message } from "antd";

const UserEditContent = () => {
  const uID = useParams()?.id;
  const { data: dropdown, isLoading } = useGetCompanyListForDropdownQuery(uID);
  const { data } = useGetUserDetailsQuery(uID);
  const [updateUserDetails, { isLoading: userLoading, isSuccess, error }] =
    useUpdateUserDetailsMutation();
  const onSubmit = async (data) => {
    try {
      await updateUserDetails({ id: uID, ...data }).unwrap();
      history.push(`/app/${support_role_endpoint.user}/list`);
    } catch (error) {
      message.error(error.message);
    }
  };

  const fields = [
    {
      name: "first_name",
      label: "First Name",
      type: "text",
      placeholder: "Enter your First Name here",
      required: true,
      requiredMessage: "First Name is required",
    },
    {
      name: "last_name",
      label: "Last Name",
      type: "text",
      placeholder: "Enter your Last Name here",
      required: true,
      requiredMessage: "Last Name is required",
    },
    {
      name: "contact_person_email",
      label: "Email",
      type: "email",
      placeholder: "Enter your Email here",
      required: true,
      requiredMessage: "Email is required",
      pattern: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
      patternMessage: "Invalid email format",
    },
    {
      name: "company_name",
      label: "Company",
      type: "dropdown",
      isMultiple: true,
      options: dropdown?.data
        ? dropdown.data.concat(data?.company_name || [])
        : dropdown?.data || [],
      required: true,
      requiredMessage: "company is required",
    },
  ];

  return (
    <>
      {isLoading ? (
        "Loading..."
      ) : (
        <FormComponent
          initialValues={data}
          onSubmit={onSubmit}
          fields={fields}
          heading={"Edit Details"}
          isLoading={userLoading}
        />
      )}
    </>
  );
};

export default UserEditContent;
