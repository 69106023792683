import React from "react";
import { TitleHolder } from "../../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../../vendorRegistration/vendorRegistration";
import UserAddContent from "./components/userAddContent";

const UserAdd = () => {
  return (
    <div>
      <TitleHolder>Users</TitleHolder>
      <StepperContentHolder>
        <UserAddContent />
      </StepperContentHolder>
    </div>
  );
};

export default UserAdd;
