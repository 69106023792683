import {
  Box,
  CardContent,
  Card,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  TableBody,
  TableHead,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { useEffect, useState } from "react";
import {
  CustomButton,
  DocumentLabels,
  SelectSpan,
  StyledCard,
  StyledOutlineButton,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import {
  Super,
  VendorCardSubTitle,
} from "../../vendorRegistration/components/steps/cardTrial";
import { VendorCardTitle } from "../../vendorRegistration/components/steps/vendorReg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalStorage from "../../../utilities/localStorage";
import { useForm } from "react-hook-form";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  getSchedules,
  getPackagingType,
  getItems,
} from "../../lotModule/redux/lotModuleSlice";
import { history } from "../../../app/history";
import { createLot } from "../redux/lotModuleSlice";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const Input = styled("input")({
  display: "none",
});

function LotModuleContent() {
  const dispatch = useDispatch();
  const { scheduleNumbers, itemList, packagingTypes } = useSelector(
    (state) => state.lotModule
  );
  const [lotDoc, setLotDoc] = useState("");
  const [items, setItems] = useState([]);
  const [newDocs, setNewDocs] = useState([1]);
  const [supportingDoc, setSupportingDoc] = useState([]);
  const [expectedArrivalDate, setExpectedArrivalDate] = useState(Date);
  const [companyId, setCompanyId] = useState("");
  const [date, setDate] = useState(Date);
  const [open, setOpen] = React.useState(false);
  const [indexToRemove, setIndexToRemove] = React.useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const scheduleInfo = (payload) => {
    dispatch(getSchedules(payload));
  };
  const getPackagingTypeInfo = (payload) => {
    dispatch(getPackagingType(payload));
  };

  useEffect(() => {
    scheduleInfo();
    getPackagingTypeInfo();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setItems(itemList);
  }, [itemList]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleScheduleNumberChange = (e) => {
    const schedule_id = e.target.value;
    dispatch(getItems(schedule_id));
    var temp = scheduleNumbers.filter(
      (scheduleNumbers) => scheduleNumbers.id === schedule_id
    );
    setCompanyId(temp[0]?.CompanyId);
    setDate(temp[0]?.createdAt);
  };

  const handleInputChange = (index, field, value) => {
    const updatedItems = [...items];
    const itemToUpdate = updatedItems[index];

    // Check if the item is extensible
    if (Object.isExtensible(itemToUpdate)) {
      // If extensible, add the new property
      itemToUpdate[field] = value;
    } else {
      // If not extensible, create a new object with the additional property
      updatedItems[index] = { ...itemToUpdate, [field]: value };
    }

    setItems(updatedItems);
  };

  const handleQuantityChange = (index, field, value, item_name) => {
    const itemMatch = itemList.filter((item) => item.item_name === item_name);
    const updItems = itemMatch.map((item, i) => {
      if (value < item.quantity && value > 0) {
        return { ...item, quantity: value };
      }
      return item;
    });
    setItems(updItems);
  };
  const handleRemoveItem = (index) => {
    setOpen(true);
    setIndexToRemove(index);
  };

  const handleRemovePostConfirmation = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
    setOpen(false);
  };

  const handleOnClick = () => {
    let docsCopy = [...newDocs];
    docsCopy.push({
      supporting_docs: "",
    });
    setNewDocs(docsCopy);
  };

  const handleRemove = (e, i) => {
    let uploadeddocs = [...newDocs];
    uploadeddocs.splice(i, 1);
    setNewDocs(uploadeddocs);
  };

  const handlechangeDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: e.target.files[0] };
    setSupportingDoc(newDocValues);
  };

  const handleExpectedArrivalDate = (e) => {
    setExpectedArrivalDate(e.target.value);
  };
  const handleclearDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: "" };

    setSupportingDoc(newDocValues);
  };

  const handleSave = (data) => {
    var user = LocalStorage.getItem("tata_login_user");
    const vendor_id = user.vendor_id;
    let lot_details = [
      {
        VendorId: vendor_id,
        company_id: companyId,
        ScheduleId: data.schedule_number,
        lot_number: data.lot_number,
        expected_arrival_date: data.expected_arrival_date,
        carrier_name: data.carrier_name,
        tracking_number: data.tracking_number,
        packaging_type: "",
        items: items,
      },
    ];
    var formData = new FormData();
    formData.append("data", JSON.stringify(lot_details));
    formData.append("lot_document", lotDoc);
    for (let i = 0; i < newDocs.length; i++) {
      formData.append("supporting_documents", supportingDoc[i]);
    }
    dispatch(createLot(formData)).then((response) => {
      if (response?.payload?.success) {
        history.push(`/app/user-schedules-list-view/${data.schedule_number}`);
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Create New Lot
                </VendorCardTitle>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <VendorCardSubTitle style={{ marginTop: "0" }}>
                  Select the schedule and enter lot details
                </VendorCardSubTitle>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>Schedule number</VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="schedule_number"
                    name="schedule_number"
                    {...register("schedule_number", {
                      required: false,
                    })}
                    onChange={handleScheduleNumberChange}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select schedule number
                      </span>
                    </MenuItem>
                    {scheduleNumbers
                      ? scheduleNumbers.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            <SelectSpan>{item.schedule_number}</SelectSpan>
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>
                  Lot number<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter lot number"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="lot_number"
                  {...register("lot_number", {
                    required: "Please enter Lot number",
                  })}
                  error={Boolean(errors?.lot_number)}
                  helperText={errors?.lot_number?.message}
                />
              </Grid>
              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>
                    Expected Arrival Date<Super>*</Super>
                  </VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    InputProps={{
                      disableUnderline: false,
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    type="date"
                    style={{
                      width: "100%",
                      paddingTop: "3px",
                      borderBottom: "1px solid lightgray",
                    }}
                    name="expected_arrival_date"
                    placeholder="select expected arrival date"
                    {...register("expected_arrival_date", {
                      required: "Please select exptected arrival date",
                    })}
                    value={expectedArrivalDate || ""}
                    onChange={(e) => handleExpectedArrivalDate(e)}
                    inputProps={{
                      min: new Date(date).toISOString().split("T")[0], // Set the minimum date
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>Carrier Name</VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter carrier name"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="carrier_name"
                  {...register("carrier_name", {
                    required: false,
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>Tracking Number</VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter tracking number"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="tracking_number"
                  {...register("tracking_number", {
                    required: false,
                  })}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Items in Lot
                </VendorCardTitle>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <VendorCardSubTitle style={{ marginTop: "0" }}>
                  Select items in lot and its packaging type
                </VendorCardSubTitle>
              </Grid>
            </Grid>
            <CustomTableContainer component={Card}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "#e1ebfa",
                    }}
                  >
                    <TableCell width="10%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Action</TableHeaderFont>
                    </TableCell>
                    <TableCell width="10%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Sr. No.</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Item Name</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Packaging Type</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Quantity</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Unit Price</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Total Price</TableHeaderFont>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell width="10%">
                          <CustomButton
                            className="invoiceErrbutn"
                            color="error"
                            variant="outlined"
                            size="small"
                            onClick={() => handleRemoveItem(index)}
                            style={{ marginLeft: "3px ", textAlign: "center" }}
                          >
                            Remove
                          </CustomButton>
                        </TableCell>
                        <TableCell
                          width="10%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.item_name !== null
                            ? row.item_name
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell width="10%">
                          <FormControl
                            variant="standard"
                            style={{ width: "100%" }}
                          >
                            <select
                              value={row.packaging_type}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "packaging_type",
                                  e.target.value
                                )
                              }
                              style={{
                                border: "1px solid #ccc",
                                padding: "5px",
                                borderRadius: "5px",
                                fontFamily: "var(--font-family-reg)",
                                fontSize: "12px",
                                textAlign: "left",
                                width: "180px",
                              }}
                              required
                            >
                              <option
                                value=""
                                style={{ fontSize: "12px", color: "#A5A5BA" }}
                              >
                                Select packaging type
                              </option>
                              {packagingTypes
                                ? packagingTypes.map((row, index) => (
                                    <option
                                      value={row.packaging_type}
                                      key={index}
                                      style={{ fontSize: "12px" }}
                                    >
                                      {row.packaging_type}
                                    </option>
                                  ))
                                : ""}
                            </select>
                            <FormHelperText></FormHelperText>
                          </FormControl>
                        </TableCell>
                        <TableCell width="20%">
                          <input
                            type="number"
                            value={row.quantity}
                            onChange={(e) =>
                              handleQuantityChange(
                                index,
                                "quantity",
                                parseInt(e.target.value, 10),
                                row.item_name
                              )
                            }
                            style={{
                              border: "1px solid #ccc",
                              padding: "5px",
                              borderRadius: "5px",
                              fontFamily: "var(--font-family-reg)",
                              fontSize: "12px",
                              textAlign: "center",
                              width: "100%",
                            }}
                          />
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.unit_price !== null
                            ? row.unit_price
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {parseFloat(row.unit_price * row.quantity).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </CustomTableContainer>
            <br />
            <br />
            <VendorCardTitle style={{ paddingBottom: "20px" }}>
              Documents
            </VendorCardTitle>
            <Grid container spacing={3}>
              <>
                <Grid item xs={4}>
                  <span
                    variant="outlined"
                    component="span"
                    style={{ width: "50%" }}
                  >
                    <DocumentLabels style={{ marginTop: "10px" }}>
                      Lot Document <Super>*</Super>
                    </DocumentLabels>
                  </span>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    variant="standard"
                    style={{ width: "100%" }}
                    error={Boolean(errors?.lot_document)}
                  >
                    {lotDoc !== "" ? (
                      <>
                        <span
                          style={{
                            color: "#4945FF",
                            fontSize: "13px",
                            fontFamily: "var(--font-family-reg)",
                          }}
                        >
                          <Chip
                            style={{
                              width: "150px",
                              color: "#4945FF",
                              margin: "5px",
                              borderRadius: "5px",
                              border: "1px solid #D9D8FF",
                              background: "#F0F0FF",
                            }}
                            label={lotDoc.name}
                            value={lotDoc.name}
                            name="chips"
                          />
                          <HighlightOffIcon
                            sx={{ color: "#4945FF", paddingBottom: "2px" }}
                            fontSize="medium"
                            onClick={(e) => setLotDoc("")}
                          />
                        </span>
                      </>
                    ) : (
                      <label htmlFor="lot_document">
                        <Input
                          {...register("lot_document", {
                            required: "Please upload Lot document",
                          })}
                          accept="*"
                          id="lot_document"
                          onChange={(e) => setLotDoc(e.target.files[0])}
                          type="file"
                        />
                        <StyledOutlineButton
                          variant="outlined"
                          component="span"
                        >
                          Upload
                        </StyledOutlineButton>
                      </label>
                    )}
                    {lotDoc === "" ? (
                      <FormHelperText error>
                        {errors.lot_document?.message}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
              </>
            </Grid>
            {newDocs.map((item, index) => (
              <>
                <br />
                <Grid container spacing={3} key={index}>
                  <>
                    <Grid item xs={4} key={index}>
                      <span
                        variant="outlined"
                        component="span"
                        style={{ width: "50%" }}
                      >
                        <DocumentLabels style={{ marginTop: "10px" }}>
                          Supporting Document
                        </DocumentLabels>
                      </span>
                    </Grid>
                    {supportingDoc[index] !== "" &&
                    supportingDoc[index] !== undefined ? (
                      <>
                        <Grid item xs={3} key={index}>
                          <FormControl
                            variant="standard"
                            style={{ width: "100%" }}
                          >
                            <span
                              style={{
                                color: "#4945FF",
                                fontSize: "13px",
                                fontFamily: "var(--font-family-reg)",
                              }}
                            >
                              <Chip
                                style={{
                                  width: "150px",
                                  color: "#4945FF",
                                  margin: "5px",
                                  borderRadius: "5px",
                                  border: "1px solid #D9D8FF",
                                  background: "#F0F0FF",
                                }}
                                label={supportingDoc[index].name}
                                value={supportingDoc[index].name}
                                name="chips"
                              />
                              <HighlightOffIcon
                                sx={{ color: "#4945FF", paddingBottom: "2px" }}
                                fontSize="medium"
                                onClick={(e) => handleclearDoc(e, index)}
                              />
                            </span>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          {index === 0 ? (
                            <CustomButton
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={handleOnClick}
                              className="invoiceErrbutn"
                            >
                              Add Document
                            </CustomButton>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={2}>
                          <FormControl
                            variant="standard"
                            style={{ width: "100%" }}
                          >
                            <label>
                              <Input
                                accept="*"
                                id={`supporting_doc_${index}`}
                                onChange={(e) => handlechangeDoc(e, index)}
                                type="file"
                              />
                              <StyledOutlineButton
                                variant="outlined"
                                component="span"
                              >
                                Upload
                              </StyledOutlineButton>
                            </label>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          {index === 0 ? (
                            <CustomButton
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={handleOnClick}
                              className="invoiceErrbutn"
                              style={{ marginTop: "10px " }}
                            >
                              Add Document
                            </CustomButton>
                          ) : (
                            <CustomButton
                              className="invoiceErrbutn"
                              color="error"
                              variant="outlined"
                              size="small"
                              onClick={(e) => handleRemove(e, index)}
                              style={{ marginTop: "10px " }}
                            >
                              Remove
                            </CustomButton>
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                </Grid>
              </>
            ))}
          </CardContent>
        </StyledCard>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Please Confirm"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to remove this item from lot?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="outlined"
              style={{
                borderColor: "var(--button-color)",
                color: "black",
              }}
              size="small"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{
                float: "right",
                backgroundColor: "var(--button-color)",
                color: "white",
              }}
              color="success"
              onClick={(e) => handleRemovePostConfirmation(indexToRemove)}
              autoFocus
              size="small"
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <CustomButton
            sx={{
              textTransform: "none",
              background: "var(--button-color)",
              width: "200px",
              height: "40px",
            }}
            color="success"
            variant="contained"
            type="submit"
          >
            Create
          </CustomButton>
        </Box>
      </form>
    </>
  );
}

export default LotModuleContent;
