import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const get_AdminInvoices = (payload) => {
  const role = LocalStorage.getItem("tata_login_user").roles;
  return apiClient.get(
    //Todo fix permissions
    role[0] === "Admin" || role[0] === "finance"
      ? `${apiClient.Urls.admin_invoice_lists}`
      : `${apiClient.Urls.officer_invoices_list}`,
    payload,
    true
  );
};

export const view_InvoiceDetails = (payload) => {
  const role = LocalStorage.getItem("tata_login_user").roles;
  return apiClient.get(
    //Todo fix permissions
    role[0] === "Admin" || role[0] === "finance"
      ? `${apiClient.Urls.admin_invoice_view}/${payload}`
      : `${apiClient.Urls.view_invoice_details}/${payload}`,
    "",
    true
  );
};

export const approve_invoice = (payload) => {

  return apiClient.post(
    `${apiClient.Urls.invoice_approve}/${payload.invoice_id}`,
    payload,
    true
  );
};
