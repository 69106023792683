import {
  CardContent,
  Chip,
  Grid,
  Typography,
  TableHead,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AdminTitleHolder,
  CustomDocumentLink,
} from "../../../common/sharedComponents/styles/card.style";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { styled as materialStyles } from "@mui/material/styles";
import Card from "@mui/material/Card";
import styled from "styled-components";
import { getAdminGrnDetails } from "../redux/adminGrnsSlice";
import InfoIcon from "@mui/icons-material/Info";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import moment from "moment";

function AdminGrnsViewContent() {
  const { adminGrnDetails } = useSelector((state) => state.adminGrns);
  console.log("SUP GRN Details", adminGrnDetails);

  const item_list = adminGrnDetails.item_list;

  const params = useParams();
  const dispatch = useDispatch();

  const grn_id = params.grnid;
  const viewOrderDetails = (grn_id) => {
    dispatch(getAdminGrnDetails(grn_id));
  };

  useEffect(() => {
    viewOrderDetails(grn_id);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Goods Received Note#
            {adminGrnDetails.grn_number
              ? adminGrnDetails.grn_number
              : "Not Applicable"}
          </Grid>
          {adminGrnDetails?.status !== "Accepted" ? (
            ""
          ) : (
            <Grid item xs={6}>
              <Chip
                style={{
                  /*  height: "40px", */
                  width: "100px",
                  borderRadius: "25px",
                  backgroundColor: "var(--button-color)",
                  color: "white",
                  float: "right",
                }}
                label="Accepted"
              />
            </Grid>
          )}
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">VENDOR</Typography>
              <VendorCardLabel>
                {adminGrnDetails.VendorCompanyName
                  ? adminGrnDetails.VendorCompanyName
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">ASN NUMBER</Typography>
              <VendorCardLabel>
                {adminGrnDetails.asn_number
                  ? adminGrnDetails.asn_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">ORDER NUMBER</Typography>
              <VendorCardLabel>
                {adminGrnDetails.order_number
                  ? adminGrnDetails.order_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">VENDOR EMAIL</Typography>
              <VendorCardLabel>
                {adminGrnDetails.vendor_email
                  ? adminGrnDetails.vendor_email
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">GRN NUMBER</Typography>
              <VendorCardLabel>
                {adminGrnDetails.grn_number
                  ? adminGrnDetails.grn_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">DATE OF RECEIPT</Typography>
              <VendorCardLabel>
                {adminGrnDetails.date_of_receipt
                  ? moment(adminGrnDetails.date_of_receipt).format("DD MMM YY")
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <br />
          <VendorCardTitle>Item Details</VendorCardTitle>
          <CustomTableContainer component={Card}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#e1ebfa",
                  }}
                >
                  <TableCell width="10%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Sr. No.</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Item Name</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Quantity</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Unit Price</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Total Price</TableHeaderFont>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item_list ? (
                  item_list.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          width="10%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.item_name !== null
                            ? row.item_name
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.quantity !== null
                            ? row.quantity
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.unit_price !== null
                            ? row.unit_price
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.quantity * row.unit_price}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell>Fetching Items...</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>
          <br />
          <br />
          <VendorCardTitle>Documents</VendorCardTitle>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Typography className="label">GRN Document</Typography>
              <VendorCardLabel>
                {adminGrnDetails.Grn_document ? (
                  adminGrnDetails.Grn_document ? (
                    <Tooltip title="Download Document">
                      <CustomDocumentLink
                        href={adminGrnDetails.Grn_document}
                        target="_blank"
                      >
                        <InsertDriveFileOutlinedIcon
                          style={{
                            marginTop: "-1px",
                            marginRight: "3px",
                            height: "12px",
                            width: "12px",
                          }}
                        />
                        GRN document
                      </CustomDocumentLink>
                    </Tooltip>
                  ) : (
                    <Typography className="label">
                      <InfoIcon
                        sx={{
                          marginTop: "-1px",
                          marginRight: "1px",
                          fill: "grey",
                          height: "15px",
                          width: "15px",
                        }}
                      />{" "}
                      There is no GRN document
                    </Typography>
                  )
                ) : (
                  <Typography className="label">
                    <InfoIcon
                      sx={{
                        marginTop: "-1px",
                        marginRight: "1px",
                        fill: "grey",
                        height: "15px",
                        width: "15px",
                      }}
                    />{" "}
                    There is no GRN document
                  </Typography>
                )}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Supporting Documents</Typography>
              {adminGrnDetails?.Supporting_document !== undefined &&
              adminGrnDetails?.Supporting_document !== null &&
              adminGrnDetails?.Supporting_document.length > 0 ? (
                adminGrnDetails?.Supporting_document.map((item, index) => (
                  <VendorCardLabel key={index}>
                    <Tooltip title="Download Document">
                      <CustomDocumentLink
                        key={index}
                        href={item}
                        target="_blank"
                      >
                        <InsertDriveFileOutlinedIcon
                          style={{
                            marginTop: "-1px",
                            marginRight: "3px",
                            height: "12px",
                            width: "12px",
                          }}
                        />
                        Supporting document
                      </CustomDocumentLink>
                    </Tooltip>
                  </VendorCardLabel>
                ))
              ) : (
                <Typography className="label">
                  <InfoIcon
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />{" "}
                  There are no supporting documents
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    </>
  );
}
export default AdminGrnsViewContent;

export const StyledCard = materialStyles(Card)`
    box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
    flex-direction: column;
    border: none;
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 1em;
    `;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;
