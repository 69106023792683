import React from "react";
import {
  Routes,
  Route,
  unstable_HistoryRouter as HistoryRouter,
  Navigate,
} from "react-router-dom";
import App from "../app/App";
import { history } from "../app/history";
import CreateNewPassword from "../features/createNewPassword/createNewPassword";
import ForgotPassword from "../features/forgotPassword/forgotPassword";
import Login from "../features/login/login";
import IndianSignupPAN from "../features/signup/components/pan/IndianSignupPAN";
import SignUp from "../features/signup/signup";
import { getLoggedInUser } from "../utilities/helper";
import QualityCheck from "../features/qualityCheck/qualityCheck";

import {
  PrivateSigninRoute,
  PrivateRoute,
  roleBasedNavigation,
} from "./PrivateRoutes";

export default function Router() {
  const { user } = getLoggedInUser();
  let goTo = user ? roleBasedNavigation(user?.roles[0] || "") : "login";
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/" element={<Navigate replace to={goTo} />} />
        <Route
          path="/login"
          exact
          element={
            <PrivateSigninRoute>
              <Login />
            </PrivateSigninRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <PrivateSigninRoute>
              <SignUp />
            </PrivateSigninRoute>
          }
        />
        <Route
          path="signup/pan"
          element={
            <PrivateSigninRoute>
              <IndianSignupPAN />
            </PrivateSigninRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PrivateSigninRoute>
              <ForgotPassword />
            </PrivateSigninRoute>
          }
        />
        <Route
          path="/create-new"
          element={
            <PrivateSigninRoute>
              <CreateNewPassword />
            </PrivateSigninRoute>
          }
        />
        <Route
          path="/inspection"
          exact
          element={
            <PrivateSigninRoute>
              <QualityCheck />
            </PrivateSigninRoute>
          }
        />
        <Route
          path="/app/*"
          element={
            <PrivateRoute>
              <App />
            </PrivateRoute>
          }
        />
      </Routes>
    </HistoryRouter>
  );
}
