import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import ItemContent from "./components/itemContent";
export default function Item() {
  return (
    <div>
      <TitleHolder>Items</TitleHolder>
      <StepperContentHolder>
        <ItemContent />
      </StepperContentHolder>
    </div>
  );
}
