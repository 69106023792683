import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import AsnModuleContent from "./components/asnModuleContent";

function AsnModule() {
  return (
    <div>
      <TitleHolder>Advanced Shipping Notices </TitleHolder>
      <StepperContentHolder>
        <AsnModuleContent />
      </StepperContentHolder>
    </div>
  );
}

export default AsnModule;