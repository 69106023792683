import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import CompanyContent from "./components/companyContent";
import { CompaniesLabel } from "../../CONSTANTS";
import LocalStorage from "../../utilities/localStorage";
export default function Company() {
  const ClientId = LocalStorage.getItem("client")?.id;
  const Companies = CompaniesLabel(ClientId);
  return (
    <div>
      <TitleHolder>{Companies}</TitleHolder>
      <StepperContentHolder>
        <CompanyContent />
      </StepperContentHolder>
    </div>
  );
}
