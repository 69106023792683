import React from "react";
import { StepperContentHolder } from "./adminPrsView";
import AdminPrsPreviewContent from "./components/adminPrsPreviewContent";

const AdminPrsPreview = () => {
  return (
    <div>
      <StepperContentHolder>
        <AdminPrsPreviewContent />
      </StepperContentHolder>
    </div>
  );
};

export default AdminPrsPreview;
