import {
  Box,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Grid,
  Typography,
  TableHead,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AdminTitleHolder } from "../../../common/sharedComponents/styles/card.style";
import { editLot, getLotDetails } from "../redux/userLotsSlice";
import { styled as materialStyles } from "@mui/material/styles";
import Card from "@mui/material/Card";
import styled from "styled-components";
// import { history } from "../../../app/history";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import FormHelperText from "@mui/material/FormHelperText";
import { SelectSpan } from "../../vendorRegistration/components/StepMain";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useForm } from "react-hook-form";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import moment from "moment";
import { Super } from "../../vendorRegistration/components/steps/cardTrial";
import { history } from "../../../app/history";

function UserLotsEditContent() {
  const { lot_details } = useSelector((state) => state.userLots);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();

  console.log("lot_details :", lot_details);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const lot_id = params.lotid;
  const viewLotDetails = (lot_id) => {
    dispatch(getLotDetails(lot_id));
  };

  const handleSave = (data) => {
    setLoading(true);
    const details = {
      lot_id: lot_id,
      // vendor_id: data.vendor_id,
      lot_status: data.lot_status,
    };

    dispatch(editLot(details)).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        setReload(true);
        history.push(
          `/app/user-schedules-list-view/${lot_details.schedule_id}`
        );
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    viewLotDetails(lot_id);
    setReload(false);
    // eslint-disable-next-line
  }, [reload]);

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <AdminTitleHolder>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              Lot#
              {lot_details.lot_number
                ? lot_details.lot_number
                : "Not Applicable"}
            </Grid>
          </Grid>
        </AdminTitleHolder>
        <StyledCard>
          <CardContent>
            <VendorCardTitle>Lot Details</VendorCardTitle>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Typography className="label">LOT NUMBER</Typography>
                <VendorCardLabel>
                  {lot_details.lot_number
                    ? lot_details.lot_number
                    : "Not Applicable"}
                </VendorCardLabel>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="label">EXPECTED ARRIVAL DATE</Typography>
                <VendorCardLabel>
                  {lot_details.expected_arrival_date
                    ? moment(lot_details.expected_arrival_date).format(
                        "DD MMM YY"
                      )
                    : "Not Applicable"}
                </VendorCardLabel>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="label">CARRIER NAME</Typography>
                <VendorCardLabel>
                  {lot_details.carrier_name
                    ? lot_details.carrier_name
                    : "Not Applicable"}
                </VendorCardLabel>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="label">TRACKING NUMBER</Typography>
                <VendorCardLabel>
                  {lot_details.tracking_number
                    ? lot_details.tracking_number
                    : "Not Applicable"}
                </VendorCardLabel>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="label">STATUS</Typography>
                <VendorCardLabel>
                  {lot_details.lot_status ? (
                    <Chip
                      label={
                        lot_details.lot_status
                          ? lot_details.lot_status.toUpperCase()
                          : "Not Applicable"
                      }
                      color={
                        lot_details.lot_status === "Pending"
                          ? "warning"
                          : "success"
                      }
                      size="small"
                      style={{ fontSize: "9px" }}
                    />
                  ) : (
                    "Not Applicable"
                  )}
                </VendorCardLabel>
              </Grid>
            </Grid>
            <br />
            <VendorCardTitle>Items in Lot</VendorCardTitle>
            <CustomTableContainer component={Card}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "#e1ebfa",
                    }}
                  >
                    <TableCell width="10%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Sr. No.</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Item Name</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Packaging Type</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Quantity</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Unit Price</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Total Price</TableHeaderFont>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lot_details?.item_list ? (
                    lot_details.item_list.map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            width="10%"
                            style={{
                              fontFamily: "var(--font-family-reg)",
                              fontSize: "12px",
                              textAlign: "center",
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            width="20%"
                            style={{
                              fontFamily: "var(--font-family-reg)",
                              fontSize: "12px",
                              textAlign: "center",
                            }}
                          >
                            {row.item_name ? row.item_name : "Not Applicable"}
                          </TableCell>
                          <TableCell
                            width="20%"
                            style={{
                              fontFamily: "var(--font-family-reg)",
                              fontSize: "12px",
                              textAlign: "center",
                            }}
                          >
                            {row.packaging_type
                              ? row.packaging_type
                              : "Not Applicable"}
                          </TableCell>
                          <TableCell
                            width="20%"
                            style={{
                              fontFamily: "var(--font-family-reg)",
                              fontSize: "12px",
                              textAlign: "center",
                            }}
                          >
                            {row.quantity !== null
                              ? row.quantity
                              : "Not Applicable"}
                          </TableCell>
                          <TableCell
                            width="20%"
                            style={{
                              fontFamily: "var(--font-family-reg)",
                              fontSize: "12px",
                              textAlign: "center",
                            }}
                          >
                            {row.unit_price !== null
                              ? row.unit_price
                              : "Not Applicable"}
                          </TableCell>
                          <TableCell
                            width="20%"
                            style={{
                              fontFamily: "var(--font-family-reg)",
                              fontSize: "12px",
                              textAlign: "center",
                            }}
                          >
                            {parseFloat(row.quantity * row.unit_price).toFixed(
                              2
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow></TableRow>
                  )}
                </TableBody>
              </Table>
            </CustomTableContainer>
            <br />
            <br />
            <Grid container spacing={2}>
              <Grid item xs={6} md={12}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardTitle>Lot Status</VendorCardTitle>
                  <VendorCardLabel>
                    Update Lot Status<Super>*</Super>
                  </VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="lot_status"
                    name="lot_status"
                    {...register("lot_status", {
                      required: "Please select lot status",
                    })}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select status
                      </span>
                    </MenuItem>
                    <MenuItem value="pending">
                      <SelectSpan>Pending</SelectSpan>
                    </MenuItem>
                    <MenuItem value="in-progress">
                      <SelectSpan>In-Progress</SelectSpan>
                    </MenuItem>
                    <MenuItem value="rolled">
                      <SelectSpan>Rolled</SelectSpan>
                    </MenuItem>
                    <MenuItem value="vendor-inspected">
                      <SelectSpan>Vendor Inspected</SelectSpan>
                    </MenuItem>
                    <MenuItem value="dispatched">
                      <SelectSpan>Dispatched</SelectSpan>
                    </MenuItem>
                  </Select>
                  <FormHelperText error>
                    {errors.lot_status?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />

          {/* <LoadingButton
                size="small"
                color="secondary"
                onClick={handleClick}
                loading={loading}
                loadingPosition="start"
                variant="contained"
              >
                Validate
              </LoadingButton> */}
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "200px",
              height: "40px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            Update Status
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}

export default UserLotsEditContent;

export const StyledCard = materialStyles(Card)`
        box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
        flex-direction: column;
        border: none;
        width: 100%;
        height: auto;
        border-radius: 10px;
        margin-bottom: 1em;
        `;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;
