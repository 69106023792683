import React from "react";

import ConfigurationListItems from "./components/ConfigurationListItems";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";

function ConfigurationList() {
  return (
    <div>
      <TitleHolder>Configurations </TitleHolder>
      <StepperContentHolder>
        <ConfigurationListItems />
      </StepperContentHolder>
    </div>
  );
}

export default ConfigurationList;
