import React from "react";
import AdminLotsViewContent from "./components/adminLotsViewContent";
import styled from "styled-components";

function AdminLotsView() {
  return (
    <div>
      <StepperContentHolder>
        <AdminLotsViewContent />
      </StepperContentHolder>
    </div>
  );
}

export default AdminLotsView;

export const StepperContentHolder = styled.div`
  position: relative;
  padding: 1em 0em 1em 0em;
  margin: 0 0 0 22%;
  color: #3d4576;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: auto;
  @media screen and (max-width: 500px) {
    margin: 0 0 0 5%;
    width: 90%;
  }
`;
