import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";
// import LocalStorage from "../../../utilities/localStorage";

export const company = (payload) => {
  return apiClient.post(`${apiClient.Urls.company}`, payload, true);
};

export const getCompany = (page, per_page, search) => {
  return apiClient.get(
    `${apiClient.Urls.company_list}?page=${page}&per_page=${per_page}&search=${
      search || ""
    }`,
    "",
    true
  );
};

export const getCompanyDetails = (payload) => {
  return apiClient.get(`${apiClient.Urls.company}/${payload}`, "", true);
};
export const getSubCompaniesDropdown = (payload) => {
  // var user_role = LocalStorage.getItem("tata_login_user").roles[0];
  return apiClient.get(
    `${apiClient.Urls.subCompanyDropdown}?role_name=${payload}`,
    "",
    true
  );
};

export const get_purchase_points = (payload) => {
  var user_role = LocalStorage.getItem("tata_login_user").roles[0];
  return apiClient.get(
    user_role !== "officer"
      ? `${apiClient.Urls.get_purchase_points}/${payload}`
      : `${apiClient.Urls.officer_get_purchase_points}/${payload}`,
    "",
    true
  );
};

export const getCompanyOfficers = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.company_officer}/${payload}`,
    "",
    true
  );
};

export const ActiveInactiveCompanies = (payload) => {
  return apiClient.patch(
    `${apiClient.Urls.activeInactiveCompany}`,
    payload,
    true
  );
};
