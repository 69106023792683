import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useDispatch } from "react-redux";
import TableRow from "@mui/material/TableRow";
// import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Pagination,
  Tooltip,
  useTheme,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import {
  CustomTableContainer,
  CustomTableHead,
  TableContentFont,
  // TableContentVendorFont,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import {
  Edit,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { getWorkflows } from "../redux/userWorkflowsSlice";
import { Link } from "react-router-dom";
import moment from "moment";
import { EmptyContainer, capitalizeString } from "../../../utilities/helper";
import { Company } from "../../../CONSTANTS";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  console.log("jdhdhdhd");
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <>
      <Box sx={{ flexShrink: 0 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function WorkflowTableViewContent({ search, clicked, setClicked }) {
  const [page, setPage] = React.useState(1);
  const [per_page, setPer_page] = React.useState(10);
  const { workflowsList, loading, pagination } = useSelector(
    (state) => state.userWorkflows
  );

  const dispatch = useDispatch();

  const workflows = () => {
    let payload = { search, page, per_page };
    dispatch(getWorkflows(payload));
    setClicked(false);
  };

  useEffect(() => {
    workflows();
    // eslint-disable-next-line
  }, [clicked, page, per_page]);

  const client = JSON.parse(localStorage.getItem("client"));
  const clientId = client.id;

  const handleChangeRowsPerPage = (event) => {
    setPer_page(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <CustomTableContainer component={Card}>
      <Table aria-label="simple table">
        <CustomTableHead>
          <TableRow>
            <TableCell align="left">
              <TableHeaderFont>Workflow</TableHeaderFont>
            </TableCell>
            <TableCell align="left">
              <TableHeaderFont>{Company}</TableHeaderFont>
            </TableCell>
            <TableCell align="left">
              <TableHeaderFont>Category</TableHeaderFont>
            </TableCell>
            {clientId === 5 ? (
              <>
                <TableCell align="left">
                  <TableHeaderFont>Purchase Point</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Range</TableHeaderFont>
                </TableCell>
              </>
            ) : (
              ""
            )}
            <TableCell align="left">
              <TableHeaderFont>Description</TableHeaderFont>
            </TableCell>
            <TableCell align="left">
              <TableHeaderFont>Creation Date</TableHeaderFont>
            </TableCell>
            <TableCell align="center">
              <TableHeaderFont>Action</TableHeaderFont>
            </TableCell>
          </TableRow>
        </CustomTableHead>
        <TableBody>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : workflowsList.length !== 0 ? (
            workflowsList.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <TableContentFont>
                    {row.name !== null
                      ? capitalizeString(row.name)
                      : "Not Applicable"}
                  </TableContentFont>
                </TableCell>
                <TableCell component="th" scope="row">
                  <TableContentFont>
                    {row.company_name !== null
                      ? row.company_name
                      : "Not Applicable"}
                  </TableContentFont>
                </TableCell>
                <TableCell component="th" scope="row">
                  <TableContentFont>
                    {row.workflow_category !== null
                      ? capitalizeString(row.workflow_category)
                      : "Not Applicable"}
                  </TableContentFont>
                </TableCell>
                {clientId === 5 ? (
                  <>
                    <TableCell component="th" scope="row">
                      <TableContentFont>
                        {row.purchase_point !== null
                          ? row.purchase_point
                          : "Not Applicable"}
                      </TableContentFont>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <TableContentFont>
                        {row.range !== null ? row.range : "Not Applicable"}
                      </TableContentFont>
                    </TableCell>
                  </>
                ) : (
                  ""
                )}
                <TableCell style={{ textAlign: "center" }}>
                  <TableContentFont>
                    {row.description !== null ? row.description : "-"}
                  </TableContentFont>
                </TableCell>
                <TableCell>
                  <TableContentFont>
                    {row.createdAt !== null
                      ? moment(row.createdAt).format("DD MMM YY")
                      : "Not Applicable"}
                  </TableContentFont>
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {row.are_steps_defined === false ? (
                    <Tooltip title="Add steps">
                      <Link
                        style={{ textDecoration: "none" }}
                        // eslint-disable-next-line
                        to={"/app/add-workflows-steps/" + `${row.id}`}
                      >
                        <AddBoxRoundedIcon
                          style={{
                            fill: "royalblue",
                            height: "18px",
                            width: "18px",
                          }}
                        />
                      </Link>
                    </Tooltip>
                  ) : (
                    <Box>
                      <Tooltip title="Edit steps">
                        <Link
                          style={{ textDecoration: "none" }}
                          // eslint-disable-next-line
                          to={"/app/add-workflows-steps/" + `${row.id}`}
                        >
                          <Edit
                            style={{
                              fill: "royalblue",
                              height: "18px",
                              width: "18px",
                            }}
                          />
                        </Link>
                      </Tooltip>
                      <Tooltip title="View steps">
                        <Link
                          style={{ textDecoration: "none" }}
                          // eslint-disable-next-line
                          to={"/app/user-workflows-list-view/" + `${row.id}`}
                        >
                          <RemoveRedEyeIcon
                            style={{
                              fill: "royalblue",
                              height: "18px",
                              width: "18px",
                            }}
                          />
                        </Link>
                      </Tooltip>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6}>
                  <CustomTableContainer>
                    <EmptyContainer text={"There is no data to display."} />
                  </CustomTableContainer>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </TableBody>
      </Table>
      <Grid
        container
        className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
        sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
      >
        <Grid className="d-flex align-items-center gap-2">
          <span>Rows per page: </span>
          <select
            value={per_page}
            onChange={(e) => setPer_page(e.target.value)}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </Grid>
        <Pagination
          sx={{
            width: "max-content",
          }}
          color="primary"
          variant="text"
          onChange={(_, newValue) => setPage(newValue)}
          count={pagination?.total_page}
          page={page}
          shape="rounded"
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </Grid>
    </CustomTableContainer>
  );
}

export default WorkflowTableViewContent;
