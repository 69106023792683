import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import DepartmentContentView from "./components/DepartmentContentView";

function DepartmentContent() {
  return (
    <div>
      <TitleHolder>Departments </TitleHolder>
      <StepperContentHolder>
        <DepartmentContentView />
      </StepperContentHolder>
    </div>
  );
}

export default DepartmentContent;
