import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import ItemTableContent from "./components/itemTableContent";
import React, { useState } from "react";
import {
  GetAppRounded,
  Search,
  UploadRounded,
  Add,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import LocalStorage from "../../utilities/localStorage";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import item_excel from "../../assets/ItemMasterFormat.csv";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { item_upload_excel } from "../item/redux/itemSlice";

function ItemTable() {
  const userData = LocalStorage.getItem("tata_login_user");
  const [search, setSearch] = useState();
  const [clicked, setClicked] = useState(false);
  const [fileOpen, setFileOpen] = React.useState(false);
  const [data, setData] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  const handleClose = () => {
    setFileOpen(false);
  };

  const handleUpload = () => {
    setLoading(true);
    var formData = new FormData();
    formData.append("file", data);
    dispatch(item_upload_excel(formData)).then((response) => {
      setLoading(false);
      setFileOpen(false);
    });
    setLoading(false);
  };
  return (
    <div>
      <TitleHolder style={{ paddingBottom: "0" }}>
        <Grid container spacing={1}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Items
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: "0.5rem",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
            >
              <TextField
                variant="outlined"
                size="small"
                label="Search field"
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                variant="contained"
                size="large"
                onClick={() => setClicked(true)}
              >
                <Search />
              </Button>
            </Grid>

            {userData.roles[0] === "super admin" ? (
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "2rem",
                        height: "2.5rem",
                        width: "4.3rem",
                      }}
                      {...bindTrigger(popupState)}
                    >
                      <KeyboardArrowDown />
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem onClick={popupState.close}>
                        <a
                          href={item_excel}
                          download
                          style={{
                            fontSize: "0.8rem",
                            textDecoration: "none",
                            color: "black",
                          }}
                        >
                          {<GetAppRounded color="primary" />} Download Excel
                        </a>
                      </MenuItem>
                      <MenuItem onClick={() => setFileOpen(true)}>
                        <span
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {<UploadRounded color="primary" />} Upload Excel
                        </span>
                      </MenuItem>
                      <MenuItem>
                        <Link
                          to="/app/add-item"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <Tooltip title="Add new item">
                            <span
                              style={{
                                fontSize: "0.8rem",
                              }}
                            >
                              {<Add color="primary" />} Add Item
                            </span>
                          </Tooltip>
                        </Link>
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </TitleHolder>
      <br />
      <StepperContentHolder>
        <ItemTableContent
          search={search}
          clicked={clicked}
          setClicked={setClicked}
        />
      </StepperContentHolder>

      {/* add file dialog */}
      <Dialog
        open={fileOpen}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: "move", fontWeight: "Bold" }}
          id="draggable-dialog-title"
        >
          Add Excel File
        </DialogTitle>

        <DialogContent>
          <DialogContentText>Add an excel file to upload</DialogContentText>

          <DialogContentText>
            <input
              type="file"
              fullWidth
              accept=".xlsx .xls .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => setData(e.target.files[0])}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ alignItems: "right" }}>
          <Button
            autoFocus
            onClick={(handleClose, () => setData(""))}
            style={{ border: "#4059e5 solid 1px", height: "30px" }}
          >
            Cancel
          </Button>
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "70px",
              height: "30px",
            }}
            color="primary"
            variant="contained"
            onClick={handleUpload}
            loading={loading}
            loadingPosition="start"
          >
            Upload
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ItemTable;
