import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { approve_asn, get_AdminLots, view_LotDetails } from "./adminLotsAPI";

const initialState = {
  loading: false,
  adminLotsList: [],
  lot_details: [],
};
const actions = {
  GETADMINLOTS: "getAdminLots/GETADMINLOTS",
  VIEWLOTDETAILS: "getLotDetails/VIEWLOTDETAILS",
  APPROVEASN: "approveAsn/APPROVEASN",
};

export const getAdminLots = createAsyncThunk(
  actions.GETADMINLOTS,
  async (payload) => {
    const response = await get_AdminLots(payload);
    return response;
  }
);

export const getLotDetails = createAsyncThunk(
  actions.VIEWLOTDETAILS,
  async (payload) => {
    const response = await view_LotDetails(payload);
    return response;
  }
);

export const approveAsn = createAsyncThunk(
  actions.APPROVEASN,
  async (payload) => {
    const response = await approve_asn(payload);
    return response;
  }
);
export const adminLotsSlice = createSlice({
  name: "adminLots",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminLots.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdminLots.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.adminLotsList = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getAdminLots.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getLotDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLotDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.lot_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getLotDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(approveAsn.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveAsn.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(approveAsn.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const adminLotsActions = adminLotsSlice.actions;
export const { resetadminLotsDetails } = adminLotsSlice.actions;

export default adminLotsSlice.reducer;