import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl, getLocalToken } from "../apiClient";
import { support_role_endpoint } from "./endpoint";

export const api = createApi({
  reducerPath: "common_reducer",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiUrl}/api`,
    prepareHeaders: (headers) => {
      let token = getLocalToken();
      if (token) {
        headers.set("Authorization", token);
      }
      headers.set = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      return headers;
    },
  }),
  tagTypes: ["Tenant", "User", "Company"],
  endpoints: (builder) => ({
    getTenantDetails: builder.query({
      query: (id) => `${support_role_endpoint?.tenant}/${id}`,
      providesTags: ["Tenant"],
    }),
    editTenantDetails: builder.mutation({
      query: (data) => ({
        url: support_role_endpoint.tenant,
        method: "PATCH",
        body: data,
        headers: {
          Authorization: getLocalToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }),
      invalidatesTags: ["Tenant"],
    }),
    getUsersList: builder.query({
      query: ({ page, per_page, search }) =>
        `${
          support_role_endpoint?.user
        }?page=${page}&per_page=${per_page}&search=${search || ""}`,
      providesTags: ["User"],
    }),
    getUserDetails: builder.query({
      query: (id) => `${support_role_endpoint?.user}/${id}`,
      providesTags: ["User"],
    }),
    updateStatus: builder.mutation({
      query: (id) => ({
        url: `${support_role_endpoint?.user}`,
        method: "PATCH",
        body: { id },
        headers: {
          Authorization: getLocalToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }),
      invalidatesTags: ["User"],
    }),
    addUser: builder.mutation({
      query: ({ data }) => ({
        url: `${support_role_endpoint?.user}`,
        method: "POST",
        body: data,
        headers: {
          Authorization: getLocalToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }),
      invalidatesTags: ["User"],
    }),
    updateUserDetails: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `${support_role_endpoint?.user}/${id}`,
        method: "PATCH",
        body: data,
        headers: {
          Authorization: getLocalToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }),
      invalidatesTags: ["User"],
    }),
    getCompanyListForDropdown: builder.query({
      query: (uid) => `${support_role_endpoint?.company}/${uid}`,
      providesTags: ["Company"],
    }),
  }),
});

export const {
  useGetTenantDetailsQuery,
  useEditTenantDetailsMutation,
  useGetUsersListQuery,
  useGetUserDetailsQuery,
  useUpdateStatusMutation,
  useUpdateUserDetailsMutation,
  useGetCompanyListForDropdownQuery,
  useAddUserMutation,
} = api;
