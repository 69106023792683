/* eslint-disable react-hooks/rules-of-hooks */
import { AdminTitleHolder } from "../../../common/sharedComponents/styles/card.style";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CardContent, Grid, Typography } from "@mui/material";
import {
  StyledCard,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { usersDetails } from "../redux/companiesUserSlice";
import { Company } from "../../../CONSTANTS";
function CompaniesUsersViewContent() {
  const params = useParams();
  const { userDetails } = useSelector((state) => state.companiesUser);
  const dispatch = useDispatch();
  const viewUserDetails = (user_id) => {
    dispatch(usersDetails(user_id));
  };

  useEffect(() => {
    viewUserDetails(params.user);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            User#{params.user}
          </Grid>
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">NAME</Typography>
              <VendorCardLabel>
                {userDetails?.first_name
                  ? userDetails?.first_name
                  : "Not Applicable"}{" "}
                {userDetails?.last_name ? userDetails?.last_name : ""}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">{Company} NAME</Typography>
              <VendorCardLabel>
                {userDetails?.company_name
                  ? userDetails?.company_name
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">{Company} EMAIL</Typography>
              <VendorCardLabel>
                {userDetails?.company_email
                  ? userDetails?.company_email
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">CONTACT EMAIL</Typography>
              <VendorCardLabel>
                {userDetails?.contact_person_email
                  ? userDetails?.contact_person_email
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">PAN NUMBER</Typography>
              <VendorCardLabel>
                {userDetails?.permanent_account_number
                  ? userDetails?.permanent_account_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">ROLE</Typography>
              <VendorCardLabel>
                {userDetails?.roles ? userDetails?.roles[0] : "Not Applicable"}
              </VendorCardLabel>
            </Grid>

            <Grid item xs={6} md={6}>
              <Typography className="label">DATE</Typography>
              <VendorCardLabel>
                {userDetails?.createdAt
                  ? moment(userDetails?.createdAt).format("DD MMM YYYY")
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">TIME</Typography>
              <VendorCardLabel>
                {userDetails?.createdAt
                  ? moment(userDetails?.createdAt).format("hh:mm:ss a")
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    </>
  );
}
export default CompaniesUsersViewContent;
