import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const get_banks = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_id = user.user_id;
  return apiClient.get(
    `${apiClient.Urls.bankinformation}/${vendor_id}`,
    payload,
    true
  );
};

export const get_grn_numbers = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_id = user.vendor_id;
  return apiClient.get(
    `${apiClient.Urls.grn_accept_numbers}?vendor_id=${vendor_id}`,
    payload,
    true
  );
};

export const invoices = (payload) => {
  return apiClient.post(`${apiClient.Urls.invoices}`, payload, true, "file");
};

export const get_invoices = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_id = user.vendor_id;
  return apiClient.get(
    `${apiClient.Urls.invoices_list}?vendor_id=${vendor_id}`,
    payload,
    true
  );
};

export const get_gst = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_id = user.vendor_id;
  return apiClient.get(
    `${apiClient.Urls.gst_info}?vendor_id=${vendor_id}`,
    payload,
    true
  );
};

export const get_location = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_id = user.vendor_id;
  return apiClient.get(
    `${apiClient.Urls.location_info}?vendor_id=${vendor_id}`,
    "",
    true
  );
};

export const view_UserInvoiceDetails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.view_user_invoice_details}/${payload}`,
    "",
    true
  );
};

export const get_items = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.get_grn_items}/?grn_id=${payload}`,
    payload,
    true
  );
};
