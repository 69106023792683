import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const get_grns = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_id = user.vendor_id;
  return apiClient.get(
    `${apiClient.Urls.grn_numbers}?vendor_id=${vendor_id}`,
    payload,
    true
  );
};

export const view_GrnDetails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.view_grn_details}/${payload}`,
    "",
    true
  );
};

export const approve_grn = (payload) => {
  return apiClient.patch(
    `${apiClient.Urls.grn_approve}/${payload.grn_id}`,
    payload,
    true
  );
};
