import { Box, CardContent, Grid, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState } from "react";
import {
  StyledCard,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import { Super } from "../../vendorRegistration/components/steps/cardTrial";
import { VendorCardTitle } from "../../vendorRegistration/components/steps/vendorReg";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { history } from "../../../app/history";
import { createDepartment } from "../redux/departmentSlice";

function DepartmentContentView() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSave = (data) => {
    setLoading(true);
    let department_details = {
      department_name: data.department_name,
      department_code: data.department_code,
    };

    dispatch(createDepartment(department_details)).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        history.push("/app/departments");
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Create New Department
                </VendorCardTitle>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
              <Grid item md={6}>
                <VendorCardLabel>
                  Department Name<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter department name"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="department_name"
                  {...register("department_name", {
                    required: "Please enter department name",
                  })}
                  error={Boolean(errors?.department_name)}
                  helperText={errors?.department_name?.message}
                />
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>
                  Department Code<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter department code"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="department_code"
                  {...register("department_code", {
                    required: "Please enter department code",
                  })}
                  error={Boolean(errors?.department_code)}
                  helperText={errors?.department_code?.message}
                />
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "200px",
              height: "40px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            Create
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}

export default DepartmentContentView;
