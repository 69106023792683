import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import { logout } from "../../features/login/redux/loginSlice";
import navitas_logo from "../../assets/navitas-logo.png";
import vishaka_logo from "../../assets/vishakha-logo.png";
import {
  A,
  LinksContainer,
  SideBarContainer,
} from "../sharedComponents/styles/sideBar.style";
import sblogo from "../../assets/SBLogo.png";
import "../sharedComponents/styles/sideBar.css";
import { Link, NavLink } from "react-router-dom";
import { Avatar } from "antd";
/* import org from "../../assets/Stroke 1.png"; */
import settings from "../../assets/shape.png";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import LocalStorage from "../../utilities/localStorage";
import { Badge, IconButton } from "@mui/material";
import { NotificationsActive } from "@mui/icons-material";
import { history } from "../../app/history";
import { getNotification } from "../../features/notification/redux/notificationSlice";
import { enable_po_app, enable_pr_app } from "../../utilities/helper";

import { CompaniesLabel } from "../../CONSTANTS";
import { support_role_endpoint } from "../../utilities/redux-api/endpoint";

function SideBar() {
  const { vendorDetails } = useSelector((state) => state.dashboarddetails);
  const { notify_count } = useSelector((state) => state.notification);
  const [toggleMenu, setToggleMenu] = useState(false);
  const dispatch = useDispatch();
  const clientId = LocalStorage.getItem("client")?.id;
  const value = JSON.parse(localStorage.getItem("tata_login_user"));
  const Companies = CompaniesLabel(clientId);
  const Menu = () => (
    <>
      {(value.roles[0] === "officer" && value.roles[1] === "acting_sa") ||
      (value.roles[0] === "acting_sa" && value.roles[1] === "officer") ? (
        <>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/orders-list"
              end={true}
            >
              Orders
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/invoices-list"
              end={true}
            >
              Invoices
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/vendor"
              end={true}
            >
              Vendors
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/company"
              end={true}
            >
              {Companies}
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/users"
              end={true}
            >
              Users
            </NavLink>
          </li>
          <li>
            <A to="/login" onClick={() => dispatch(logout())}>
              Logout
            </A>
          </li>
        </>
      ) : value.roles[0] === "super admin" ? (
        <>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/recomm-vcc"
              end={true}
            >
              Vendors
            </NavLink>
          </li>

          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/company"
              end={true}
            >
              {Companies}
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/users"
              end={true}
            >
              Users
            </NavLink>
          </li>
          {enable_pr_app ? (
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "SBactive" : "SBinactive"
                }
                to="/app/prs-list"
                end={true}
              >
                Purchase Requisitions
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {clientId === 3 || clientId === 1 || clientId === 5 ? (
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "SBactive" : "SBinactive"
                }
                to="/app/authorities"
                end={true}
              >
                Authorities
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {clientId === 3 || clientId === 5 ? (
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "SBactive" : "SBinactive"
                }
                to="/app/configurations"
                end={true}
              >
                Configurations
              </NavLink>
            </li>
          ) : (
            ""
          )}
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/item"
              end={true}
            >
              Items
            </NavLink>
          </li>
          <li>
            <A to="/login" onClick={() => dispatch(logout())}>
              Logout
            </A>
          </li>
        </>
      ) : value.roles[0] === "officer" ? (
        <>
          {enable_pr_app ? (
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "SBactive" : "SBinactive"
                }
                to="/app/prs-list"
                end={true}
              >
                Purchase Requisitions
              </NavLink>
            </li>
          ) : (
            ""
          )}
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/orders-list"
              end={true}
            >
              Orders
            </NavLink>
          </li>
          {enable_po_app ? (
            <>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "SBactive" : "SBinactive"
                  }
                  to="/app/schedules-list"
                  end={true}
                >
                  Schedules
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "SBactive" : "SBinactive"
                  }
                  to="/app/asns-list"
                  end={true}
                >
                  ASNs
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "SBactive" : "SBinactive"
                  }
                  to="/app/grns-list"
                  end={true}
                >
                  GRNs
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "SBactive" : "SBinactive"
                  }
                  to="/app/user-inspections-list"
                >
                  Inspections
                </NavLink>
              </li>
            </>
          ) : (
            ""
          )}
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/invoices-list"
              end={true}
            >
              Invoices
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/vendor"
              end={true}
            >
              Vendors
            </NavLink>
          </li>
          <li>
            <A to="/login" onClick={() => dispatch(logout())}>
              Logout
            </A>
          </li>
        </>
      ) : value.roles[0] === "Admin" || value.roles[0] === "finance" ? (
        <>
          {enable_pr_app ? (
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "SBactive" : "SBinactive"
                }
                to="/app/prs-list"
                end={true}
              >
                Purchase Requisitions
              </NavLink>
            </li>
          ) : (
            ""
          )}
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/recomm-vcc"
              end={true}
            >
              Vendors
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/orders-list"
              end={true}
            >
              Orders
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/invoices-list"
              end={true}
            >
              Invoices
            </NavLink>
          </li>
          <li>
            <A to="/login" onClick={() => dispatch(logout())}>
              Logout
            </A>
          </li>
        </>
      ) : value.roles.includes("support") ? (
        <>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/support/tenant"
            >
              Tenant
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to={`/app/${support_role_endpoint.user}/list`}
            >
              Users
            </NavLink>
          </li>
          <li>
            <A to="/login" onClick={() => dispatch(logout())}>
              Logout
            </A>
          </li>
        </>
      ) : (
        <>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app"
              end={true}
            >
              My Dashboard
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/vendor-registration"
            >
              Vendor Registration
            </NavLink>
          </li> */}
          {(clientId === 2 && vendorDetails) ||
          vendorDetails?.vendor_code ||
          (clientId === 5 && vendorDetails) ? (
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "SBactive" : "SBinactive"
                }
                to="/app/bank-details"
              >
                Bank Details
              </NavLink>
            </li>
          ) : (
            <span
              className="SBinactive"
              onClick={() =>
                alert("New vendors should complete vendor registration first")
              }
            >
              Bank Details
            </span>
          )}

          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/user-orders-list"
            >
              Orders
            </NavLink>
          </li>
          <li>
            {enable_po_app ? (
              <>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "SBactive" : "SBinactive"
                    }
                    to="/app/user-schedules-list"
                  >
                    Schedules
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "SBactive" : "SBinactive"
                    }
                    to="/app/user-asns-list"
                  >
                    ASNs
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "SBactive" : "SBinactive"
                    }
                    to="/app/user-grns-list"
                  >
                    GRNs
                  </NavLink>
                </li>
              </>
            ) : (
              ""
            )}
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/user-invoices-list"
            >
              Invoices
            </NavLink>
          </li>
          {enable_po_app ? (
            <>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "SBactive" : "SBinactive"
                  }
                  to="/app/user-inspections-list"
                >
                  Inspections
                </NavLink>
              </li>
            </>
          ) : (
            ""
          )}
          <li>
            <A to="/login" onClick={() => dispatch(logout())}>
              Logout
            </A>
          </li>
        </>
      )}
    </>
  );

  function notificationsLabel(count) {
    if (count === 0) {
      return "no notifications";
    }
    if (count > 99) {
      return "more than 99 notifications";
    }
    return `${count} notifications`;
  }
  useEffect(() => {
    if (!value.roles.includes("support")) dispatch(getNotification());
  }, [dispatch]);
  return (
    <>
      <SideBarContainer>
        <LogoContainer>
          <img
            src={
              clientId === 1
                ? navitas_logo
                : clientId === 2
                ? vishaka_logo
                : sblogo
            }
            alt="logo"
          />
        </LogoContainer>
        <div>
          <WideBarHolder>
            <UserFamily className="d-flex">
              <UserIcon>
                <Avatar
                  style={{ color: "#D483BB", backgroundColor: "#F2C1E2" }}
                >
                  {value.first_name[0].toUpperCase()}
                </Avatar>
              </UserIcon>
              <UserInfo className="w-100 ">
                <UserNameDisplay className="d-flex justify-content-between w-100 ">
                  <UserName style={{ textTransform: "capitalize" }}>
                    {value.first_name}
                  </UserName>

                  <IconButton
                    aria-label={notificationsLabel(100)}
                    sx={{ paddingRight: "1rem" }}
                    onClick={() => {
                      history.push("/app/notification");
                    }}
                  >
                    <Badge badgeContent={notify_count} color="warning">
                      <NotificationsActive
                        className="text-light"
                        fontSize="small"
                      />
                    </Badge>
                  </IconButton>
                </UserNameDisplay>
              </UserInfo>
            </UserFamily>

            {value.roles[0] === "user" ? (
              <>
                <Divider />
                <NavLink
                  style={{ padding: "5px" }}
                  className={({ isActive }) =>
                    isActive ? "SBactive" : "SBinactive"
                  }
                  to="/app/account-settings"
                >
                  <span style={{ paddingRight: "5px" }}>
                    <UserSettingsIcon src={settings} />
                  </span>
                  Account Settings
                </NavLink>
              </>
            ) : (
              <>
                <br />
              </>
            )}
          </WideBarHolder>
        </div>

        <LinksContainer>
          <Menu />
        </LinksContainer>
      </SideBarContainer>
      <LexysNavBarMenu>
        {toggleMenu ? (
          <CloseIcon
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <MenuIcon
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <LexysNavBarMenuContainer>
            <LexysNavBarContainerLinks>
              <ScaleUpCenter>
                {value.roles[0] === "super admin" ||
                value.roles[0] === "finance" ? (
                  <>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "SBactive" : "SBinactive"
                      }
                      to="/app/recomm-vcc"
                      end={true}
                    >
                      Vendors
                    </NavLink>

                    <A to="/login" onClick={() => dispatch(logout())}>
                      Logout
                    </A>
                  </>
                ) : (
                  <>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "SBactive" : "SBinactive"
                      }
                      to="/app"
                      end={true}
                    >
                      My Dashboard
                    </NavLink>

                    {/* <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "SBactive" : "SBinactive"
              }
              to="/app/vendor-registration"
            >
              Vendor Registration
            </NavLink>
          </li> */}

                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "SBactive" : "SBinactive"
                      }
                      to="/app/bank-details"
                    >
                      Bank Details
                    </NavLink>

                    <A to="/login" onClick={() => dispatch(logout())}>
                      Logout
                    </A>
                  </>
                )}
              </ScaleUpCenter>
            </LexysNavBarContainerLinks>
          </LexysNavBarMenuContainer>
        )}
      </LexysNavBarMenu>
    </>
  );
}

export default SideBar;
export const LexysNavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 6rem;

  @media screen and (max-width: 700px) {
    padding: 2rem 4rem;
  }

  @media screen and (max-width: 550px) {
    padding: 2rem;
  }
`;

export const LexysNavBarLinks = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const LexysLogo = styled.div`
  margin-right: 0.5rem;

  > img {
  }
`;
export const LexysNavBarLinksContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1050px) {
    display: none;
  }
`;

export const LexysNavBarSign = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 550px) {
    display: none;
  }
`;

export const Ani = keyframes`
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  
`;

export const ScaleUpCenter = styled.div`
  -webkit-animation: ${Ani} 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: ${Ani} 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`;

export const NavBarP = styled.p`
  color: #fff;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0rem 1rem 0rem 2rem;
  cursor: pointer;

  > button {
    background-color: transparent;
    color: #fff;
    padding: 0.5rem 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    border-radius: 5px;
    line-height: 25px;
    font-size: 18px;
    border: 0;
    outline: solid 1px white;
    cursor: pointer;
    :hover {
      background-color: #fff;
      color: var(--color-bg);
      outline: none;
      border: 0;
    }
    @media screen and (max-width: 550px) {
      margin-top: 8px;
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

export const NavBarA = styled.a`
  position: relative;
  padding-bottom: 0.5rem;
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;

    @media screen and (max-width: 550px) {
      margin-top: 5px;
    }
  }
  :hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
    @media screen and (max-width: 550px) {
    }
  }

  :focus {
    content: "";
    position: relative;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: transparent;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  :focus::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  @media screen and (max-width: 550px) {
    padding-bottom: 0.1rem;
  }
`;

export const LexysNavBarMenu = styled.div`
  margin-left: 1rem;
  padding: 2em 0em 0 0em;
  display: none;
  position: absolute;

  > svg {
    cursor: pointer;
  }
  @media screen and (max-width: 1050px) {
    display: flex;
  }
`;

export const LexysNavBarMenuContainer = styled.div`
  position: fixed; /* Sit on top of the page content */

  width: 30%; /* Full width (cover the whole page) */
  height: 90%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 3em 0em 0em 1em;
  padding: 2em 0em 0 0em;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /*  display: flex; */
  /* justify-content: flex-end;
  align-items: flex-end; */
  flex-direction: column;
  /*  text-align: end; */
  background: #4059e5;
  /* padding: 2rem; */
  /*  position: relative;
  margin-top: 1rem;
  min-width: 210px; */
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
  > p {
    margin: 1rem 0;
  }

  @media screen and (max-width: 550px) {
    top: 20px;
  }
`;

export const LexysNavBarContainerLinks = styled.div``;

export const LexysNavBarMenuContainerLinks = styled.div`
  display: none;
  @media screen and (max-width: 550px) {
    display: block;
  }
`;

export const LogoContainer = styled.div`
  padding: 1em 1em 1em 1em;
  display: flex;

  img {
    display: flex;
    height: 100%;
    width: 100%;
    @media screen and (max-width: 900px) {
      max-width: 100%;
      height: auto;
      display: none;
    }
  }
`;

export const LogoutButton = styled.button`
  font-family: var(--font-family-med);
  font-size: 14px;
  color: white;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

export const WideSection = styled.section`
  margin-top: 10px;
`;

export const NewLink = styled(NavLink)`
  margin-left: 5px;
  text-decoration: none;
  font-family: var(--font-family-medium);
  font-size: 14px;
  color: white;
  font-weight: 500;

  :hover {
    color: white;
  }
`;

export const WideBarHolder = styled.div`
  width: 90%;
  margin: 10px;
  background-color: #324de4;
  right: auto;
  float: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 0.75rem;
  min-height: 1px;
  margin-right: auto;
  margin-bottom: 20px;
  border-radius: 10px;
`;

export const UserFamily = styled.div`
  padding: 24px 0px 5px 0px;
  overflow: hidden;
  ::after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const Divider = styled.hr`
  opacity: 0.1;
  margin: 10px 20px 10px 20px;
  border: 1px solid #5a70eb;
  height: 1px;
  background-color: #5a70eb;
`;

export const UserIcon = styled.div`
  float: left;
  box-sizing: border-box;
  padding: 0 0.75rem 0rem 0.75rem;
  min-height: 1px;
  ::after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const OrgLink = styled(Link)`
  font-family: var(--font-family-reg);
  text-decoration: none;
  color: white;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  :hover {
    color: white;
  }
`;

export const AccountSettings = styled.div`
  text-decoration: none;
  font-family: var(--font-family-medium);
  font-size: 14px;
  color: white;
  font-weight: 500;
  width: 100%;

  float: left;
  box-sizing: border-box;
  padding: 0 0.75rem 1em 0.75rem;
  min-height: 1px;
  ::after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const UserIconImg = styled.img`
  width: 60px;
`;

export const UserInfo = styled.div`
  margin-left: auto;

  float: left;
  box-sizing: border-box;
  padding: 0;

  ::after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const UserNameDisplay = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 3px 0px;
  box-sizing: inherit;
  white-space: nowrap;
`;

export const UserName = styled.h5`
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const UserSettings = styled.p`
  font-size: 12px;
  color: #fff;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
`;

export const UserSettingsIcon = styled.img`
  top: -1px;
  position: relative;
  /*  width: 10px;
  height: 10px; */
`;
