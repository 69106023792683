import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const get_lots = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_id = user.vendor_id;
  return apiClient.get(
    `${apiClient.Urls.lot_numbers}?vendor_id=${vendor_id}`,
    payload,
    true
  );
};

export const view_LotDetails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.view_lot_details}/${payload}`,
    "",
    true
  );
};

export const edit_lot = (payload) => {
  return apiClient.patch(
    `${apiClient.Urls.edit_lot}/${payload.lot_id}`,
    payload,
    true
  );
};
