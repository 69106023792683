import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const get_order_numbers = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_id = user.vendor_id;
  return apiClient.get(
    `${apiClient.Urls.order_accept_numbers}?vendor_id=${vendor_id}`,
    payload,
    true
  );
};

export const schedules = (payload) => {
  return apiClient.post(`${apiClient.Urls.schedules}`, payload, true, "file");
};

export const get_schedules = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_id = user.vendor_id;
  return apiClient.get(
    `${apiClient.Urls.schedules_list}?vendor_id=${vendor_id}`,
    payload,
    true
  );
};

export const get_packaging_types = (payload) => {
  // var user = LocalStorage.getItem("tata_login_user");
  // const vendor_id = user.vendor_id;
  return apiClient.get(`${apiClient.Urls.packaging_type_info}`, payload, true);
};

export const view_UserScheduleDetails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.view_user_schedule_details}/${payload}`,
    "",
    true
  );
};

export const get_items = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.get_items}/?order_id=${payload}`,
    payload,
    true
  );
};
