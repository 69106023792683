import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TableFooter,
  TablePagination,
  // Tooltip,
  useTheme,
} from "@mui/material";

import {
  CustomTableContainer,
  CustomTableHead,
  TableContentFont,
  TableHeaderFont,
} from "../../../../styles/tableContent.style";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import PropTypes from "prop-types";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
// import moment from "moment";
// import { Link } from "react-router-dom";
import { ApproveButton } from "../../../vendorRegistration/components/ViewvendorDetails";
import {
  getPrGrantedUsers,
  getPrPendingUsers,
  getPrRevokedUsers,
  grantPRPrivilege,
} from "../../redux/userPrivilegesSlice";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <Box sx={{ flexShrink: 0 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function PrRevokedContent() {
  const { prRevokedList } = useSelector((state) => state.userPrivileges);
  const [checkedItems, setCheckedItems] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (prRevokedList.length > 0) {
      // Create an object with record IDs as keys and true as values
      const initialCheckedItems = prRevokedList.reduce((acc, record) => {
        acc[record.id] = true;
        return acc;
      }, {});
      setCheckedItems(initialCheckedItems);
    }
  }, [prRevokedList, setCheckedItems]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - prRevokedList.length) : 0;

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event, id) => {
    setCheckedItems({ ...checkedItems, [id]: event.target.checked });
  };

  const handleGrant = () => {
    const selectedUserIds = Object.entries(checkedItems)
      .filter(([id, isChecked]) => isChecked)
      .map(([id]) => parseInt(id));

    if (selectedUserIds.length === 0) {
      // Handle case when no users are selected
      return;
    }

    const payload = { userIds: selectedUserIds };
    dispatch(grantPRPrivilege(payload)).then((response) => {
      setOpen(false);
      if (response.payload.success === true) {
        dispatch(getPrPendingUsers());
        dispatch(getPrGrantedUsers());
        dispatch(getPrRevokedUsers());
      }
    });
  };

  return (
    <>
      <CustomTableContainer component={Card}>
        <Table /*  sx={{ minWidth: 650 }} */ aria-label="simple table">
          <CustomTableHead>
            <TableRow>
              <TableCell align="left">
                <TableHeaderFont>Select</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Name</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Email</TableHeaderFont>
              </TableCell>
              {/* <TableCell align="center">
                <TableHeaderFont>Action</TableHeaderFont>
              </TableCell> */}
            </TableRow>
          </CustomTableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? prRevokedList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : prRevokedList
            ).map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Checkbox
                    checked={checkedItems[row.id] || false}
                    onChange={(event) => handleChange(event, row.id)}
                  />
                </TableCell>
                <TableCell align="left">
                  <TableContentFont>
                    {typeof row.first_name !== undefined &&
                    row.first_name !== null
                      ? `${row.first_name + " " + row.last_name}`
                      : `Not Applicable`}
                  </TableContentFont>
                </TableCell>
                <TableCell align="left">
                  <TableContentFont>
                    {typeof row.contact_person_email !== undefined &&
                    row.contact_person_email !== null
                      ? `${row.contact_person_email}`
                      : `Not Applicable`}
                  </TableContentFont>
                </TableCell>
                {/* <TableCell style={{ textAlign: "center" }}>
                  <Tooltip title="View User">
                    <Link
                      style={{ textDecoration: "none" }}
                      // eslint-disable-next-line
                      to={"/app/users-list-view/" + `${row.id}`}
                    >
                      <RemoveRedEyeIcon
                        style={{
                          fill: "royalblue",
                          height: "18px",
                          width: "18px",
                        }}
                      />
                    </Link>
                  </Tooltip>
                </TableCell> */}
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 0 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter style={{ borderTop: "1px solid lightgray" }}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={8}
                count={prRevokedList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </CustomTableContainer>
      <br></br>
      <ApproveButton
        onClick={handleClickOpen}
        variant="outlined"
        style={{
          height: "30px",
          width: "100px",
          float: "right",
        }}
      >
        Grant
      </ApproveButton>
      {/* add file dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please Confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to grant PR creation privileges to the
            selected users?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            style={{
              borderColor: "var(--button-color)",
              color: "black",
            }}
            size="small"
          >
            No
          </Button>
          <Button
            variant="contained"
            style={{
              float: "right",
              backgroundColor: "var(--button-color)",
              color: "white",
            }}
            color="success"
            onClick={handleGrant}
            autoFocus
            size="small"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default PrRevokedContent;
