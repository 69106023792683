import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import WorkflowStepsContent from "./components/WorkflowStepsContent";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";

function WorkflowSteps() {
  return (
    <div>
      <TitleHolder>Workflow Steps</TitleHolder>
      <StepperContentHolder>
        <WorkflowStepsContent />
      </StepperContentHolder>
    </div>
  );
}

export default WorkflowSteps;
