import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import PRCreationTableViewContent from "./components/PRCreationTableViewContent";
import { Grid } from "@mui/material";

function AdminTable() {
  return (
    <div>
      <TitleHolder style={{ paddingBottom: "5px" }}>
        <Grid container spacing={2}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Purchase Requisition Creation Privileges
          </Grid>
        </Grid>
      </TitleHolder>

      <StepperContentHolder style={{ paddingTop: "5px" }}>
        <PRCreationTableViewContent />
      </StepperContentHolder>
    </div>
  );
}

export default AdminTable;
