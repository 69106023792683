import {
  Box,
  CardContent,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  TableHead,
  Table,
  Card,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { useEffect, useState } from "react";
import {
  CustomButton,
  DocumentLabels,
  SelectSpan,
  StyledCard,
  StyledOutlineButton,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import {
  Super,
  VendorCardSubTitle,
} from "../../vendorRegistration/components/steps/cardTrial";
import { VendorCardTitle } from "../../vendorRegistration/components/steps/vendorReg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalStorage from "../../../utilities/localStorage";
import { useForm } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getBanks,
  getGST,
  getLocation,
  getGrns,
  getItems,
} from "../../invoiceModule/redux/invoiceModuleSlice";
import { history } from "../../../app/history";
import { createInvoice } from "../redux/invoiceModuleSlice";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";

const Input = styled("input")({
  display: "none",
});

function InvoiceModuleContent() {
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);

  const [invoiceDoc, setInvoiceDoc] = useState("");
  const { grnNumbers, banks, gsts, itemList, locations } = useSelector(
    (state) => state.invoiceModule
  );

  console.log("grnNumbers :", grnNumbers);
  console.log("itemList :", itemList);

  const [newDocs, setNewDocs] = useState([1]);
  const [orderId, setOrderId] = useState("");
  const [date, setDate] = useState(Date);
  const [supportingDoc, setSupportingDoc] = useState([]);
  const [invoiceamount, setInvoiceamount] = useState("");
  const [gstamount, setGstamount] = useState("");
  const [cgst, setCgst] = useState("");
  const [sgst, setSgst] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [datevalue, setDatevalue] = useState(Date);
  const [totalInvamount, setTotalInvamount] = useState(0);
  const [gstType, setGstType] = useState("igst");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setTotalInvamount(null);
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const bankinfo = (payload) => {
    dispatch(getBanks(payload));
  };

  const grnInfo = (payload) => {
    dispatch(getGrns(payload));
  };
  const getInfo = (payload) => {
    dispatch(getGST(payload));
  };
  const locationsInfo = (payload) => {
    dispatch(getLocation(payload));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(
    () => {
      locationsInfo();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    bankinfo();
    grnInfo();
    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setItems(itemList);
  }, [itemList]);

  const handleOnClick = () => {
    let docsCopy = [...newDocs];

    docsCopy.push({
      supporting_docs: "",
    });

    setNewDocs(docsCopy);
  };

  const handleRemove = (e, i) => {
    let uploadeddocs = [...newDocs];
    uploadeddocs.splice(i, 1);
    setNewDocs(uploadeddocs);
  };

  const handlechangeDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: e.target.files[0] };

    setSupportingDoc(newDocValues);
  };

  const handleinvdate = (e) => {
    setDatevalue(e.target.value);
  };
  const handleclearDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: "" };

    setSupportingDoc(newDocValues);
  };

  // const handleOrderamount = (e) => {
  //   let id = e.target.value;
  //   var total_inv_amt = orderNumbers.filter(
  //     (orderNumbers) => orderNumbers.id === id
  //   );
  //   setCompanyId(total_inv_amt[0]?.CompanyId);
  //   setTotalInvamount(
  //     total_inv_amt
  //       ? parseInt(total_inv_amt[0]?.order_amount) -
  //           parseInt(total_inv_amt[0]?.Total_invoice_amount)
  //       : 0
  //   );
  // };

  const handleGrnChange = (e) => {
    let id = e.target.value;
    var total_inv_amt = grnNumbers.filter((grnNumbers) => grnNumbers.id === id);
    grnNumbers.forEach((grn) => {
      if (grn.id === id) {
        setOrderId(grn?.order_id);
      }
    });
    setCompanyId(total_inv_amt[0]?.CompanyId);
    dispatch(getItems(id));
    setTotalInvamount(
      total_inv_amt
        ? parseFloat(total_inv_amt[0]?.grn_amount) -
            parseInt(total_inv_amt[0]?.Total_invoice_amount)
        : 0
    );
    setDate(total_inv_amt[0]?.date_of_receipt);
  };

  const allowOnlyNumber = (e, param) => {
    let str = e.target.value;
    const regExp = /^[+-]?\d*(?:[.,]\d*)?$/;

    let status = regExp.test(str) ? "valid" : "invalid";
    if (param === "inv_amt") {
      status === "valid" && e.target.value <= totalInvamount
        ? setInvoiceamount(e.target.value)
        : setInvoiceamount("");
    }
    if (param === "igst") {
      status === "valid" ? setGstamount(e.target.value) : setGstamount("");
    }
    if (param === "cgst") {
      status === "valid" ? setCgst(e.target.value) : setCgst("");
    }
    if (param === "sgst") {
      status === "valid" ? setSgst(e.target.value) : setSgst("");
    }
  };

  const handleSave = (data) => {
    setLoading(true);
    var user = LocalStorage.getItem("tata_login_user");
    const vendor_id = user.vendor_id;
    let invoice_details = [
      {
        vendor_id: vendor_id,
        company_id: companyId,
        OrderId: orderId,
        grn_id: data.grn_number,
        invoice_number: data.invoice_number,
        location: data.location,
        invoice_date: data.invoice_date,
        invoice_amount: data.invoice_amount,
        GSTIN: data.gstin,
        igst_amount: data.igst,
        cgst_amount: data.cgst,
        sgst_amount: data.sgst,
        gst_type: data.gstType,
        bank: data.bank,
        invoice_description: data.invoice_description,
      },
    ];

    var formData = new FormData();
    formData.append("data", JSON.stringify(invoice_details));
    formData.append("invoice_document", invoiceDoc);
    for (let i = 0; i < newDocs.length; i++) {
      formData.append("supporting_documents", supportingDoc[i]);
    }
    dispatch(createInvoice(formData)).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        history.push("/app/user-invoices-list");
      }
      setLoading(false);
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Create New Invoice
                </VendorCardTitle>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <VendorCardSubTitle style={{ marginTop: "0" }}>
                  Select the Goods Received Note and enter invoice details
                </VendorCardSubTitle>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>GRN number</VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="grn_number"
                    name="grn_number"
                    {...register("grn_number", {
                      required: "Please select GRN number",
                    })}
                    onChange={handleGrnChange}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select GRN number
                      </span>
                    </MenuItem>
                    {grnNumbers
                      ? grnNumbers.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            <SelectSpan>{item.grn_number}</SelectSpan>
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>
                  Invoice number<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter invoice number"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="invoice_number"
                  {...register("invoice_number", {
                    required: "Please enter invoice number",
                  })}
                  error={Boolean(errors?.invoice_number)}
                  helperText={errors?.invoice_number?.message}
                />
              </Grid>
              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>
                    Invoice date<Super>*</Super>
                  </VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    InputProps={{
                      disableUnderline: false,
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    type="date"
                    style={{
                      width: "100%",
                      paddingTop: "3px",
                      borderBottom: errors?.invoice_date
                        ? "0"
                        : "1px solid lightgray",
                    }}
                    name="invoice_date"
                    placeholder="select invoice date"
                    {...register("invoice_date", {
                      required: "Please select invoice date",
                    })}
                    value={datevalue || ""}
                    onChange={(e) => handleinvdate(e)}
                    inputProps={{
                      min: new Date(date).toISOString().split("T")[0], // Set the minimum date
                    }}
                    error={Boolean(errors?.invoice_date)}
                    helperText={errors?.invoice_date?.message}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>
                  Invoice Amount(Excluding GST){" "}
                  {totalInvamount ? (
                    <span style={{ fontSize: "9px", color: "red" }}>
                      Max amount allowed &#8377;{totalInvamount}
                    </span>
                  ) : (
                    ""
                  )}
                  <Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter invoice amount"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="invoice_amount"
                  error={Boolean(errors?.invoice_amount)}
                  {...register("invoice_amount", {
                    max: { value: totalInvamount, message: "Limit exceeded" },
                    required: "Please enter invoice amount",
                  })}
                  value={invoiceamount || ""}
                  onChange={(e) => allowOnlyNumber(e, "inv_amt")}
                  helperText={errors?.invoice_amount?.message}
                />
              </Grid>
              {gsts?.gst_type ? (
                ""
              ) : (
                <>
                  <Grid item md={6}>
                    <FormControl variant="standard" style={{ width: "100%" }}>
                      <VendorCardLabel>GSTIN</VendorCardLabel>
                      <Select
                        sx={{
                          fontFamily: "var(--font-family-reg)",
                          fontSize: "14px",
                        }}
                        displayEmpty
                        defaultValue=""
                        IconComponent={() => (
                          <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                        )}
                        labelId="demo-simple-select-standard-label"
                        id="gstin"
                        name="gstin"
                        {...register("gstin", {
                          required: false,
                        })}
                      >
                        <MenuItem value="">
                          <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                            Select GSTIN
                          </span>
                        </MenuItem>
                        {gsts
                          ? gsts.map((item, index) => (
                              <MenuItem value={item.gst_id} key={index}>
                                <SelectSpan>{item.gst_id}</SelectSpan>
                              </MenuItem>
                            ))
                          : ""}
                      </Select>
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl variant="standard" style={{ width: "100%" }}>
                      <VendorCardLabel>GST Type</VendorCardLabel>
                      <Select
                        sx={{
                          fontFamily: "var(--font-family-reg)",
                          fontSize: "14px",
                        }}
                        displayEmpty
                        defaultValue={gstType}
                        IconComponent={() => (
                          <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                        )}
                        labelId="demo-simple-select-standard-label"
                        id="gstin"
                        name="gstType"
                        {...register("gstType", {
                          required: "Please select gst type",
                          value: gstType,
                        })}
                        onChange={(e) => setGstType(e.target.value)}
                      >
                        <MenuItem value={"igst"}>
                          <SelectSpan>IGST</SelectSpan>
                        </MenuItem>
                        <MenuItem value={"sgst&cgst"}>
                          <SelectSpan>SGST&CGST</SelectSpan>
                        </MenuItem>
                      </Select>
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Grid>
                  {gstType === "sgst&cgst" ? (
                    <>
                      <Grid item md={6}>
                        <VendorCardLabel>
                          CGST<Super>*</Super>
                        </VendorCardLabel>
                        <TextField
                          sx={{
                            input: {
                              fontSize: "14px",
                              fontFamily: "var(--font-family-reg)",
                            },
                          }}
                          placeholder="Enter CGST amount"
                          size="small"
                          variant="standard"
                          style={{ width: "100%", paddingTop: "3px" }}
                          name="cgst"
                          {...register("cgst", {
                            required: "Please enter CGST ",
                          })}
                          onChange={(e) => allowOnlyNumber(e, "cgst")}
                          value={cgst}
                          error={Boolean(errors?.cgst)}
                          helperText={errors?.cgst?.message}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <VendorCardLabel>
                          SGST<Super>*</Super>
                        </VendorCardLabel>
                        <TextField
                          sx={{
                            input: {
                              fontSize: "14px",
                              fontFamily: "var(--font-family-reg)",
                            },
                          }}
                          placeholder="Enter SGST amount"
                          size="small"
                          variant="standard"
                          style={{ width: "100%", paddingTop: "3px" }}
                          name="sgst"
                          {...register("sgst", {
                            required: "Please enter SGST",
                          })}
                          onChange={(e) => allowOnlyNumber(e, "sgst")}
                          value={sgst}
                          error={Boolean(errors?.sgst)}
                          helperText={errors?.sgst?.message}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item md={6}>
                      <VendorCardLabel>
                        IGST<Super>*</Super>
                      </VendorCardLabel>

                      <TextField
                        sx={{
                          input: {
                            fontSize: "14px",
                            fontFamily: "var(--font-family-reg)",
                          },
                        }}
                        placeholder="Enter IGST amount"
                        size="small"
                        variant="standard"
                        style={{ width: "100%", paddingTop: "3px" }}
                        name="igst"
                        {...register("igst", {
                          required: "Please enter IGST",
                        })}
                        onChange={(e) => allowOnlyNumber(e, "igst")}
                        value={gstamount}
                        error={Boolean(errors?.igst)}
                        helperText={errors?.igst?.message}
                      />
                    </Grid>
                  )}
                </>
              )}

              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>Location</VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="locations"
                    name="location"
                    {...register("location", {
                      required: false,
                    })}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select Location
                      </span>
                    </MenuItem>
                    {locations
                      ? locations.map((item, index) => (
                          <MenuItem value={item.location} key={index}>
                            <SelectSpan>{item.location}</SelectSpan>
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>
                    Bank <Super>*</Super>
                  </VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="bank"
                    name="bank"
                    {...register("bank", {
                      required: "Please select bank",
                    })}
                    error={Boolean(errors?.bank)}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select bank
                      </span>
                    </MenuItem>
                    {banks
                      ? banks.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            <SelectSpan>{item.bank_name}</SelectSpan>
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                  <FormHelperText
                    style={{ color: "#d32f2f", margin: "0", paddingTop: "5px" }}
                  >
                    {errors.bank?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={6}>
                <VendorCardLabel>Invoice description</VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter invoice description"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="invoice_description"
                  {...register("invoice_description", {
                    required: false,
                  })}
                />
              </Grid>
            </Grid>
            <br />
            <br />
            <VendorCardTitle>Item Details</VendorCardTitle>
            <CustomTableContainer component={Card}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "#e1ebfa",
                    }}
                  >
                    <TableCell width="10%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Sr. No.</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Item Name</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Quantity</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Unit Price</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Total Price</TableHeaderFont>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.length
                    ? items.map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              width="10%"
                              style={{
                                fontFamily: "var(--font-family-reg)",
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              width="20%"
                              style={{
                                fontFamily: "var(--font-family-reg)",
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              {row.item_name !== null
                                ? row.item_name
                                : "Not Applicable"}
                            </TableCell>
                            <TableCell
                              width="20%"
                              style={{
                                fontFamily: "var(--font-family-reg)",
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              {row.quantity !== null
                                ? row.quantity
                                : "Not Applicable"}
                            </TableCell>
                            <TableCell
                              width="20%"
                              style={{
                                fontFamily: "var(--font-family-reg)",
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              {row.unit_price !== null
                                ? row.unit_price
                                : "Not Applicable"}
                            </TableCell>
                            <TableCell
                              width="20%"
                              style={{
                                fontFamily: "var(--font-family-reg)",
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              {row.quantity * row.unit_price}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : ""}
                </TableBody>
              </Table>
            </CustomTableContainer>
            <br />
            <br />
            <VendorCardTitle>Documents</VendorCardTitle>
            <Grid container spacing={3}>
              <>
                <Grid item xs={4}>
                  <span
                    variant="outlined"
                    component="span"
                    style={{ width: "50%" }}
                  >
                    <DocumentLabels style={{ marginTop: "10px" }}>
                      Invoice Document <Super>*</Super>
                    </DocumentLabels>
                  </span>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    variant="standard"
                    style={{ width: "100%" }}
                    error={Boolean(errors?.invoice_document)}
                  >
                    {invoiceDoc !== "" ? (
                      <>
                        <span
                          style={{
                            color: "#4945FF",
                            fontSize: "13px",
                            fontFamily: "var(--font-family-reg)",
                          }}
                        >
                          <Chip
                            style={{
                              width: "150px",
                              color: "#4945FF",
                              margin: "5px",
                              borderRadius: "5px",
                              border: "1px solid #D9D8FF",
                              background: "#F0F0FF",
                            }}
                            label={invoiceDoc.name}
                            value={invoiceDoc.name}
                            name="chips"
                          />
                          <HighlightOffIcon
                            sx={{ color: "#4945FF", paddingBottom: "2px" }}
                            fontSize="medium"
                            onClick={(e) => setInvoiceDoc("")}
                          />
                        </span>
                      </>
                    ) : (
                      <label htmlFor="invoice_document">
                        <Input
                          {...register("invoice_document", {
                            required: "Please upload invoice document",
                          })}
                          accept="*"
                          id="invoice_document"
                          onChange={(e) => setInvoiceDoc(e.target.files[0])}
                          type="file"
                        />
                        <StyledOutlineButton
                          variant="outlined"
                          component="span"
                        >
                          Upload
                        </StyledOutlineButton>
                      </label>
                    )}
                    {invoiceDoc === "" ? (
                      <FormHelperText error>
                        {errors.invoice_document?.message}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
              </>
            </Grid>
            {newDocs.map((item, index) => (
              <>
                <br />
                <Grid container spacing={3} key={index}>
                  <>
                    <Grid item xs={4} key={index}>
                      <span
                        variant="outlined"
                        component="span"
                        style={{ width: "50%" }}
                      >
                        <DocumentLabels style={{ marginTop: "10px" }}>
                          Supporting Document
                        </DocumentLabels>
                      </span>
                    </Grid>
                    {supportingDoc[index] !== "" &&
                    supportingDoc[index] !== undefined ? (
                      <>
                        <Grid item xs={3} key={index}>
                          <FormControl
                            variant="standard"
                            style={{ width: "100%" }}
                          >
                            <span
                              style={{
                                color: "#4945FF",
                                fontSize: "13px",
                                fontFamily: "var(--font-family-reg)",
                              }}
                            >
                              <Chip
                                style={{
                                  width: "150px",
                                  color: "#4945FF",
                                  margin: "5px",
                                  borderRadius: "5px",
                                  border: "1px solid #D9D8FF",
                                  background: "#F0F0FF",
                                }}
                                label={supportingDoc[index].name}
                                value={supportingDoc[index].name}
                                name="chips"
                              />
                              <HighlightOffIcon
                                sx={{ color: "#4945FF", paddingBottom: "2px" }}
                                fontSize="medium"
                                onClick={(e) => handleclearDoc(e, index)}
                              />
                            </span>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          {index === 0 ? (
                            <CustomButton
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={handleOnClick}
                              className="invoiceErrbutn"
                            >
                              Add Document
                            </CustomButton>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={2}>
                          <FormControl
                            variant="standard"
                            style={{ width: "100%" }}
                          >
                            <label>
                              <Input
                                accept="*"
                                id={`supporting_doc_${index}`}
                                onChange={(e) => handlechangeDoc(e, index)}
                                type="file"
                              />
                              <StyledOutlineButton
                                variant="outlined"
                                component="span"
                              >
                                Upload
                              </StyledOutlineButton>
                            </label>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          {index === 0 ? (
                            <CustomButton
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={handleOnClick}
                              className="invoiceErrbutn"
                              style={{ marginTop: "10px " }}
                            >
                              Add Document
                            </CustomButton>
                          ) : (
                            <CustomButton
                              className="invoiceErrbutn"
                              color="error"
                              variant="outlined"
                              size="small"
                              onClick={(e) => handleRemove(e, index)}
                              style={{ marginTop: "10px " }}
                            >
                              Remove
                            </CustomButton>
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                </Grid>
              </>
            ))}
          </CardContent>
        </StyledCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "200px",
              height: "40px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            Submit
          </LoadingButton>
        </Box>
      </form>

      {
        <Dialog
          open={open}
          onClose={handleClose}
          // PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Upload Excel Data
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure add excel data?</DialogContentText>
          </DialogContent>
          <DialogActions style={{ alignItems: "right" }}>
            <Button
              autoFocus
              onClick={handleClose}
              style={{ border: "#4059e5 solid 1px", height: "30px" }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
}

export default InvoiceModuleContent;
