import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TableHead,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AdminTitleHolder,
  CustomDocumentLink,
} from "../../../common/sharedComponents/styles/card.style";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { approveAsn, getLotDetails } from "../redux/adminLotsSlice";
import { styled as materialStyles } from "@mui/material/styles";
import Card from "@mui/material/Card";
import styled from "styled-components";
import { history } from "../../../app/history";
import moment from "moment";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import InfoIcon from "@mui/icons-material/Info";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CachedIcon from "@mui/icons-material/Cached";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

function AdminLotsViewContent() {
  const { lot_details } = useSelector((state) => state.adminLots);
  const [open, setOpen] = React.useState(false);
  const [numberOfStatuses, setNumberOfStatuses] = useState(0);

  // Define your timeline items
  const timelineItems = [
    {
      status: "Pending",
      icon: <ScheduleIcon />,
      date: "",
      completed: false,
    },
    {
      status: "In Progress",
      icon: <CachedIcon />,
      date: "",
      completed: false,
    },
    {
      status: "Rolled",
      icon: <AccountTreeIcon />,
      date: "",
      completed: false,
    },
    {
      status: "Vendor Inspected",
      icon: <CheckIcon />,
      date: "",
      completed: false,
    },
    {
      status: "Dispatched",
      icon: <LocalShippingIcon />,
      date: "",
      completed: false,
    },
  ];

  const updatedTimelineItems = timelineItems.map((item, index) => {
    if (index < numberOfStatuses) {
      return {
        ...item,
        completed: true,
        date: lot_details?.lot_statuses[index]
          ? moment(lot_details?.lot_statuses[index].date).format("DD MMM YY")
          : "",
      };
    } else {
      return {
        ...item,
        completed: false,
      };
    }
  });

  const params = useParams();
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = (asn_id) => {
    const payload = { asn_id: asn_id, accept_asn: true };
    dispatch(approveAsn(payload)).then((response) => {
      setOpen(false);
      if (response.payload.success === true) {
        history.push("/app/lots-list");
      }
    });
  };

  const lot_id = params.lotid;
  const viewLotDetails = (lot_id) => {
    dispatch(getLotDetails(lot_id));
  };

  useEffect(() => {
    viewLotDetails(lot_id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (lot_details?.lot_statuses) {
      setNumberOfStatuses(lot_details.lot_statuses.length);
    }
    // eslint-disable-next-line
  }, [lot_details]);
  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            Lot#
            {lot_details.lot_number ? lot_details.lot_number : "Not Applicable"}
          </Grid>
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">VENDOR</Typography>
              <VendorCardLabel>
                {lot_details?.VendorCompanyName
                  ? lot_details?.VendorCompanyName
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">ORDER NUMBER</Typography>
              <VendorCardLabel>
                {lot_details?.order_number
                  ? lot_details?.order_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">SCHEDULE NUMBER</Typography>
              <VendorCardLabel>
                {lot_details.schedule_number
                  ? lot_details.schedule_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">LOT NUMBER</Typography>
              <VendorCardLabel>
                {lot_details.lot_number
                  ? lot_details.lot_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">EXPECTED ARRIVAL DATE</Typography>
              <VendorCardLabel>
                {lot_details.expected_arrival_date
                  ? moment(lot_details.expected_arrival_date).format(
                      "DD MMM YY"
                    )
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">CARRIER NAME</Typography>
              <VendorCardLabel>
                {lot_details.carrier_name
                  ? lot_details.carrier_name
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">TRACKING NUMBER</Typography>
              <VendorCardLabel>
                {lot_details.tracking_number
                  ? lot_details.tracking_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <VendorCardTitle>Item Details</VendorCardTitle>
              <CustomTableContainer component={Card}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: "#e1ebfa",
                      }}
                    >
                      <TableCell width="10%" style={{ textAlign: "center" }}>
                        <TableHeaderFont>Sr. No.</TableHeaderFont>
                      </TableCell>
                      <TableCell width="20%" style={{ textAlign: "center" }}>
                        <TableHeaderFont>Item Name</TableHeaderFont>
                      </TableCell>
                      <TableCell width="20%" style={{ textAlign: "center" }}>
                        <TableHeaderFont>Packaging Type</TableHeaderFont>
                      </TableCell>
                      <TableCell width="20%" style={{ textAlign: "center" }}>
                        <TableHeaderFont>Quantity</TableHeaderFont>
                      </TableCell>
                      <TableCell width="20%" style={{ textAlign: "center" }}>
                        <TableHeaderFont>Unit Price</TableHeaderFont>
                      </TableCell>
                      <TableCell width="20%" style={{ textAlign: "center" }}>
                        <TableHeaderFont>Total Price</TableHeaderFont>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lot_details?.item_list ? (
                      lot_details.item_list.map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              width="10%"
                              style={{
                                fontFamily: "var(--font-family-reg)",
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              width="20%"
                              style={{
                                fontFamily: "var(--font-family-reg)",
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              {row.item_name !== null
                                ? row.item_name
                                : "Not Applicable"}
                            </TableCell>
                            <TableCell
                              width="20%"
                              style={{
                                fontFamily: "var(--font-family-reg)",
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              {row.packaging_type
                                ? row.packaging_type
                                : "Not Applicable"}
                            </TableCell>
                            <TableCell
                              width="20%"
                              style={{
                                fontFamily: "var(--font-family-reg)",
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              {row.quantity !== null
                                ? row.quantity
                                : "Not Applicable"}
                            </TableCell>
                            <TableCell
                              width="20%"
                              style={{
                                fontFamily: "var(--font-family-reg)",
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              {row.unit_price !== null
                                ? row.unit_price
                                : "Not Applicable"}
                            </TableCell>
                            <TableCell
                              width="20%"
                              style={{
                                fontFamily: "var(--font-family-reg)",
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              {parseFloat(
                                row.quantity * row.unit_price
                              ).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>Fetching Items...</TableRow>
                    )}
                  </TableBody>
                </Table>
              </CustomTableContainer>
            </Grid>
            <Grid item xs={6} md={6}>
              <VendorCardTitle style={{ textAlign: "center" }}>
                Lot Status
              </VendorCardTitle>
              <Timeline align="left" style={{ backgroundColor: "#f0f5f2" }}>
                {updatedTimelineItems.map((item, index) => (
                  <TimelineItem
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <TimelineOppositeContent
                      color="text.secondary"
                      style={{
                        fontWeight: item.completed ? "bold" : "normal",
                        fontFamily: "var(--font-family-reg)",
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      {item.date}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot
                        color={item.completed ? "success" : "primary"}
                      >
                        {item.icon}
                      </TimelineDot>
                      {index < updatedTimelineItems.length - 1 && (
                        <TimelineConnector />
                      )}
                    </TimelineSeparator>
                    <TimelineContent>
                      <strong
                        style={{
                          fontWeight: item.completed ? "bold" : "normal",
                          fontFamily: "var(--font-family-reg)",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        {item.status}
                      </strong>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Grid>
          </Grid>
          <hr />
          <VendorCardTitle>Documents</VendorCardTitle>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Lot Document</Typography>
              <VendorCardLabel>
                <Tooltip title="Download Document">
                  <CustomDocumentLink
                    href={lot_details?.lot_document}
                    target="_blank"
                  >
                    <InsertDriveFileOutlinedIcon
                      style={{
                        marginTop: "-1px",
                        marginRight: "3px",
                        height: "12px",
                        width: "12px",
                      }}
                    />
                    Lot document
                  </CustomDocumentLink>
                </Tooltip>
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Supporting Documents</Typography>
              {lot_details?.supporting_document !== undefined &&
              lot_details?.supporting_document !== null &&
              lot_details?.supporting_document.length > 0 ? (
                lot_details?.supporting_document.map((item, index) => (
                  <VendorCardLabel key={index}>
                    <Tooltip title="Download Document">
                      <CustomDocumentLink href={item} target="_blank">
                        <InsertDriveFileOutlinedIcon
                          style={{
                            marginTop: "-1px",
                            marginRight: "3px",
                            height: "12px",
                            width: "12px",
                          }}
                        />
                        Supporting document
                      </CustomDocumentLink>
                    </Tooltip>
                  </VendorCardLabel>
                ))
              ) : (
                <Typography className="label">
                  <InfoIcon
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />{" "}
                  There are no supporting documents
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please Confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to accept this ASN?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            style={{
              borderColor: "var(--button-color)",
              color: "black",
            }}
            size="small"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              float: "right",
              backgroundColor: "var(--button-color)",
              color: "white",
            }}
            color="success"
            onClick={(e) => handleApprove(lot_id)}
            autoFocus
            size="small"
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AdminLotsViewContent;

export const StyledCard = materialStyles(Card)`
    box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
    flex-direction: column;
    border: none;
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 1em;
    `;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;