import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import AdminGrnsContent from "./components/adminGrnsContent";

function AdminGrns() {
  return (
    <div>
      <TitleHolder>Goods Received Notes </TitleHolder>
      <StepperContentHolder>
        <AdminGrnsContent />
      </StepperContentHolder>
    </div>
  );
}

export default AdminGrns;