import apiClient from "../../../utilities/apiClient";

export const get_AdminAsns = (payload) => {
  return apiClient.get(`${apiClient.Urls.admin_asns_list}`, payload, true);
};

export const view_AsnDetails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.view_asn_details}/${payload}`,
    "",
    true
  );
};

export const approve_asn = (payload) => {
  // console.log(apiClient.Urls.asn_approve);
  // console.log(payload);
  return apiClient.post(
    `${apiClient.Urls.asn_approve}/${payload.asn_id}`,
    payload,
    true
  );
};
