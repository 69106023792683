import React, { useState } from "react";
import {
  StyledCard,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import { Box, CardContent, Grid, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import { VendorCardTitle } from "../../userOrders/components/userOrdersViewContent";
import { useDispatch } from "react-redux";
import { history } from "../../../app/history";
import { Super } from "../../vendorRegistration/components/steps/cardTrial";
import { addFinanceGloble } from "../redux/AdminAuthoritiesSlice";
const AdminAuthoritieContent = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleSave = (data) => {
    setLoading(true);
    var userDetails = {
      first_name: data.first_name,
      last_name: data.last_name,
      contact_person_email: data.contact_person_email,
    };

    dispatch(addFinanceGloble(userDetails)).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        history.push("/app/authorities");
      } else {
        setLoading(false);
      }
    });
  };
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Add Global in Finance
                </VendorCardTitle>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
              <Grid item md={6}>
                <VendorCardLabel>
                  First Name<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter first Name"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="first_name"
                  {...register("first_name", {
                    required: "Please enter first name",
                  })}
                  error={Boolean(errors?.first_name)}
                  helperText={errors?.first_name?.message}
                />
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>
                  Last Name<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter Last Name"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="last_name"
                  {...register("last_name", {
                    required: "Please enter last name",
                  })}
                  error={Boolean(errors?.last_name)}
                  helperText={errors?.last_name?.message}
                />
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>
                  Contact Email<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter conatct email"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="contact_person_email"
                  {...register("contact_person_email", {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "enter valid email",
                    },
                    required: "please add email",
                  })}
                  error={Boolean(errors?.contact_person_email)}
                  helperText={errors?.contact_person_email?.message}
                />
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "200px",
              height: "40px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            Add User
          </LoadingButton>
        </Box>
      </form>
    </>
  );
};

export default AdminAuthoritieContent;
