import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CustomButton,
  StyledCard,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import { VendorCardSubTitle } from "../../vendorRegistration/components/steps/cardTrial";
import { VendorCardTitle } from "../../vendorRegistration/components/steps/vendorReg";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { Add, Delete } from "@mui/icons-material";
import {
  getApprovers,
  createWorkflowSteps,
  getPersons,
  getWorkflowDetails,
  updateWorkflowSteps,
} from "../redux/userWorkflowsSlice";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../app/history";
import { useParams } from "react-router-dom";
import { capitalizeString } from "../../../utilities/helper";
import LocalStorage from "../../../utilities/localStorage";

// const emails = [
//   "email1@example.com",
//   "email2@example.com",
//   "email3@example.com",
// ];
// const roles = ["Admin", "User", "Guest"];

const HybridTable = ({ rows, setRows, emails, roles }) => {
  const handleAddRow = () => {
    const newRow = {
      id: rows.length + 1,
      column1: { type: "email", value: emails[0] },
      additionalColumns: [],
      remarks: "",
    };

    setRows([...rows, newRow]);
  };

  const handleRemoveRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleDropdownTypeChange = (column, rowId) => {
    setRows(
      rows.map((row) => {
        if (row.id === rowId) {
          if (column === "column1") {
            return {
              ...row,
              column1: {
                type: row.column1.type === "email" ? "role" : "email",
                value: row.column1.type === "email" ? roles[0] : emails[0],
              },
            };
          } else {
            const updatedColumns = row.additionalColumns.map((col) => {
              if (col.id === column) {
                return {
                  ...col,
                  type: col.type === "email" ? "role" : "email",
                  value: col.type === "email" ? roles[0] : emails[0],
                };
              }
              return col;
            });
            return {
              ...row,
              additionalColumns: updatedColumns,
            };
          }
        }
        return row;
      })
    );
  };

  const handleDropdownValueChange = (column, rowId, value) => {
    setRows(
      rows.map((row) => {
        if (row.id === rowId) {
          if (column === "column1") {
            return {
              ...row,
              column1: {
                ...row.column1,
                value: value,
              },
            };
          } else {
            const updatedColumns = row.additionalColumns.map((col) => {
              if (col.id === column) {
                return {
                  ...col,
                  value: value,
                };
              }
              return col;
            });
            return {
              ...row,
              additionalColumns: updatedColumns,
            };
          }
        }
        return row;
      })
    );
  };

  const handleRemarksChange = (rowId, value) => {
    setRows(
      rows.map((row) =>
        row.id === rowId
          ? {
              ...row,
              remarks: value,
            }
          : row
      )
    );
  };

  const handleAddColumn = (rowId) => {
    setRows(
      rows.map((row) => {
        if (row.id === rowId) {
          const newColumnId = `col${row.additionalColumns.length + 2}`;
          const newColumn = {
            id: newColumnId,
            type: "email",
            value: emails[0],
          };

          const updatedAdditionalColumns = [
            ...row.additionalColumns,
            newColumn,
          ];

          return {
            ...row,
            additionalColumns: updatedAdditionalColumns,
          };
        }
        return row;
      })
    );
  };

  const handleRemoveColumn = (rowId, columnId) => {
    setRows(
      rows.map((row) => {
        if (row.id === rowId) {
          const updatedAdditionalColumns = row.additionalColumns.filter(
            (col) => col.id !== columnId
          );

          return {
            ...row,
            additionalColumns: updatedAdditionalColumns,
          };
        }
        return row;
      })
    );
  };

  const renderDropdown = (type, value, column, rowId) => {
    const options = type === "email" ? emails : roles;
    return (
      <Select
        value={value}
        onChange={(e) =>
          handleDropdownValueChange(column, rowId, e.target.value)
        }
        style={{
          border: "1px solid #ccc",
          borderRadius: "5px",
          fontFamily: "var(--font-family-reg)",
          fontSize: "12px",
          textAlign: "left",
          height: "28px",
          width: "40%",
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option}
            style={{
              fontSize: "12px",
              fontFamily: "var(--font-family-reg)",
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderToggleButton = (type, column, rowId) => {
    return (
      <Tooltip
        title={type === "email" ? "Switch to roles" : "Switch to emails"}
      >
        <CustomButton
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => handleDropdownTypeChange(column, rowId)}
          className="invoiceErrbutn"
          style={{ height: "28px " }}
        >
          {type === "email" ? "Role" : "Email"}
        </CustomButton>
      </Tooltip>
    );
  };

  const getHeaders = () => {
    const headers = ["Steps", "Remarks", "Approvers"];
    rows.forEach((row) => {
      row.additionalColumns.forEach((col) => {
        const colNumber = parseInt(col.id.replace("col", ""), 10);
        if (!headers.includes(colNumber.toString())) {
          headers.push(colNumber.toString());
        }
      });
    });
    return headers.sort((a, b) => (isNaN(a) ? -1 : a - b));
  };

  const renderTableHeaders = () => {
    return (
      <TableRow
        style={{
          backgroundColor: "#e1ebfa",
        }}
      >
        {getHeaders().map((header, index) => (
          <TableCell key={index} align="center">
            <TableHeaderFont>{header}</TableHeaderFont>
          </TableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <CustomTableContainer component={Card}>
      <Table aria-label="simple table">
        <TableHead>{renderTableHeaders()}</TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell
                style={{
                  fontFamily: "var(--font-family-reg)",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    {index === rows?.length - 1 && (
                      <Tooltip title="Remove Step">
                        <Delete
                          color="error"
                          variant="outlined"
                          onClick={() => handleRemoveRow(row.id)}
                        >
                          Remove
                        </Delete>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item md={6} style={{ marginTop: "3px " }}>
                    {index + 1}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <TextField
                  placeholder="Enter Remarks"
                  value={row.remarks}
                  onChange={(e) => handleRemarksChange(row.id, e.target.value)}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    style: {
                      height: "28px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "12px",
                      textAlign: "left",
                    },
                  }}
                />
              </TableCell>
              <TableCell>
                {renderDropdown(
                  row.column1.type,
                  row.column1.value,
                  "column1",
                  row.id
                )}
                {renderToggleButton(row.column1.type, "column1", row.id)}
                <Tooltip title="Add new Approver">
                  <IconButton onClick={() => handleAddColumn(row.id)}>
                    <Add />
                  </IconButton>
                </Tooltip>
              </TableCell>
              {row.additionalColumns.map((col) => (
                <TableCell key={col.id}>
                  {renderDropdown(col.type, col.value, col.id, row.id)}
                  {renderToggleButton(col.type, col.id, row.id)}
                  <Tooltip title="Remove Approver">
                    <IconButton
                      onClick={() => handleRemoveColumn(row.id, col.id)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <CustomButton
        size="small"
        color="primary"
        variant="outlined"
        onClick={handleAddRow}
        className="invoiceErrbutn"
        style={{ marginTop: "10px " }}
      >
        Add Row
      </CustomButton>
    </CustomTableContainer>
  );
};

function WorkflowStepsContent() {
  const dispatch = useDispatch();
  const { approvers, persons, workflow_details } = useSelector(
    (state) => state.userWorkflows
  );
  const [loading, setLoading] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selection, setSelection] = useState("hybrid");
  const [itemswf, setItemswf] = useState([]);

  const ClientId = LocalStorage.getItem("client").id;

  const emails = persons.map((person) => {
    return person.contact_person_email;
  });
  const roles = approvers.map((approver) => {
    return approver.role_name;
  });

  const [rows, setRows] = useState([]);

  const item_list_wf = selection === "role" ? approvers : persons;

  const { handleSubmit } = useForm();

  const Vendordetails = () => {
    dispatch(getApprovers());
    dispatch(getPersons());
  };

  useEffect(() => {
    Vendordetails();
    // eslint-disable-next-line
  }, []);

  const handleSelectionChange = (event) => {
    setSelection(event.target.value);
  };

  const handleAddItemWF = () => {
    let newItem;
    if (selection === "role") {
      newItem = {
        role_name: "",
      };
    } else {
      newItem = {
        contact_person_email: "",
      };
    }

    setItemswf([...itemswf, newItem]);
  };

  const handleRemoveItemWF = (index) => {
    const updatedItems = [...itemswf];
    updatedItems.splice(index, 1);

    // Remove the corresponding email from the selectedEmails array
    setSelectedEmails((prevSelectedEmails) => {
      const newSelectedEmails = [...prevSelectedEmails];
      newSelectedEmails.splice(index, 1); // Remove the email at the same index
      return newSelectedEmails;
    });

    setItemswf(updatedItems);
  };

  const handleInputChangewf = (index, field, value) => {
    const updatedItems = [...itemswf];
    updatedItems[index][field] = value;

    // Update selected emails array
    setSelectedEmails((prevSelectedEmails) => {
      const newSelectedEmails = [...prevSelectedEmails];
      newSelectedEmails[index] = value;
      return newSelectedEmails;
    });

    setItemswf(updatedItems);
  };

  const params = useParams();
  const workflow_id = params.workflowid;
  const viewWorkflowDetails = (workflow_id) => {
    dispatch(getWorkflowDetails(workflow_id)).then((res) => {
      const { data } = res.payload;
      if (data.are_steps_defined) {
        // const step = data.WorkflowSteps.map((data) => {
        //   const { step, remarks, contact_person_email } = data;
        //   return {
        //     id: step,
        //     column1: { type: "email", value: contact_person_email },
        //     additionalColumns: [],
        //     remarks: remarks,
        //   };
        // });
        const stepMap = {};

        data.WorkflowSteps.forEach(
          ({ step, remarks, contact_person_email }) => {
            if (!stepMap[step]) {
              // If this step doesn't exist in the map, create a new entry
              stepMap[step] = {
                id: step,
                column1: { type: "email", value: contact_person_email },
                additionalColumns: [],
                remarks: remarks,
              };
            } else {
              // If this step already exists, add the contact_person_email to additionalColumns
              stepMap[step].additionalColumns.push({
                type: "email",
                value: contact_person_email,
                id: `col${stepMap[step].additionalColumns.length + 1}`,
              });
            }
          }
        );
        const step = Object.values(stepMap);
        setRows([...rows, ...step]);
      } else {
        setRows([
          {
            id: 1,
            column1: { type: "email", value: emails[0] },
            additionalColumns: [],
            remarks: "",
          },
        ]);
      }
    });
  };
  console.log("rows: ", rows);

  useEffect(() => {
    viewWorkflowDetails(workflow_id);
    // eslint-disable-next-line
  }, []);

  const handleSave = (data) => {
    setLoading(true);
    let workflow_steps_details = {
      workflow_id: parseInt(workflow_id),
      items: itemswf,
      selection: selection,
      steps: rows.map((row) => ({
        step: row.id,
        column1: {
          id: "col1",
          ...row.column1,
        },
        additionalColumns: row.additionalColumns.map((col) => {
          return {
            id: col.id,
            type: col.type,
            value: col.value,
          };
        }),
        remarks: row.remarks,
      })),
    };

    const API = workflow_details?.are_steps_defined
      ? updateWorkflowSteps(workflow_steps_details)
      : createWorkflowSteps(workflow_steps_details);

    dispatch(API).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        history.push(`/app/user-workflows-list-view/${workflow_id}`);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <>
          <StyledCard>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Typography className="label">NAME</Typography>

                  <VendorCardLabel>
                    {workflow_details?.name
                      ? capitalizeString(workflow_details?.name)
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
                {ClientId === 5 ? (
                  <>
                    <Grid item xs={6} md={6}>
                      <Typography className="label">PURCHASE POINT</Typography>
                      <VendorCardLabel>
                        {workflow_details?.purchase_point
                          ? capitalizeString(workflow_details?.purchase_point)
                          : "Not Applicable"}
                      </VendorCardLabel>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Typography className="label">RANGE</Typography>
                      <VendorCardLabel>
                        {workflow_details?.range
                          ? workflow_details?.range
                          : "Not Applicable"}
                      </VendorCardLabel>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </CardContent>
          </StyledCard>
          <StyledCard>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <VendorCardTitle style={{ paddingBottom: "0" }}>
                    Create Pre-defined Workflow Steps
                  </VendorCardTitle>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <VendorCardSubTitle style={{ marginTop: "0" }}>
                    Select the sequence of the approvers for the workflow
                  </VendorCardSubTitle>
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <RadioGroup
                    value={selection}
                    onChange={handleSelectionChange}
                    row
                  >
                    {/* <FormControlLabel
                      value="role"
                      control={<Radio />}
                      label="Role-wise"
                    />
                    <FormControlLabel
                      value="person"
                      control={<Radio />}
                      label="Person-wise"
                    /> */}
                    <FormControlLabel
                      value="hybrid"
                      control={<Radio />}
                      label="Hybrid"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <br />
              <>
                {selection === "person" || selection === "role" ? (
                  <CustomTableContainer component={Card}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow
                          style={{
                            backgroundColor: "#e1ebfa",
                          }}
                        >
                          <TableCell
                            width="10%"
                            style={{ textAlign: "center" }}
                          >
                            <TableHeaderFont>Action</TableHeaderFont>
                          </TableCell>
                          <TableCell
                            width="10%"
                            style={{ textAlign: "center" }}
                          >
                            <TableHeaderFont>Step No.</TableHeaderFont>
                          </TableCell>
                          <TableCell
                            width="20%"
                            style={{ textAlign: "center" }}
                          >
                            <TableHeaderFont>
                              {selection === "person" ? "Approver" : "Role"}
                            </TableHeaderFont>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {itemswf.map((item, index) => (
                          <TableRow
                            key={index}
                            style={{
                              backgroundColor: "#fafcff",
                            }}
                          >
                            <TableCell width="10%">
                              <CustomButton
                                className="invoiceErrbutn"
                                color="error"
                                variant="outlined"
                                size="small"
                                onClick={() => handleRemoveItemWF(index)}
                                style={{
                                  marginLeft: "3px ",
                                  textAlign: "center",
                                }}
                              >
                                Remove
                              </CustomButton>
                            </TableCell>
                            <TableCell
                              style={{
                                fontFamily: "var(--font-family-reg)",
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell width="10%">
                              <select
                                value={
                                  selection === "role"
                                    ? item.role_name
                                    : item.contact_person_email
                                }
                                onChange={(e) =>
                                  handleInputChangewf(
                                    index,
                                    selection === "role"
                                      ? "role_name"
                                      : "contact_person_email",
                                    e.target.value
                                  )
                                }
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  fontFamily: "var(--font-family-reg)",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  width: "380px",
                                }}
                                required
                              >
                                <option
                                  value=""
                                  style={{ fontSize: "12px", color: "#A5A5BA" }}
                                >
                                  Select approver
                                </option>
                                {item_list_wf.map((item) => (
                                  <option
                                    key={
                                      selection === "role"
                                        ? item.role_name
                                        : item.contact_person_email
                                    }
                                    value={
                                      selection === "role"
                                        ? item.role_name
                                        : item.contact_person_email
                                    }
                                    style={{ fontSize: "12px" }}
                                    disabled={selectedEmails.includes(
                                      selection === "role"
                                        ? item.role_name
                                        : item.contact_person_email
                                    )}
                                  >
                                    {selection === "role"
                                      ? item.role_name
                                      : item.contact_person_email}
                                  </option>
                                ))}
                              </select>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      {selection !== "hybrid" ? (
                        <CustomButton
                          size="small"
                          color="primary"
                          variant="outlined"
                          onClick={handleAddItemWF}
                          className="invoiceErrbutn"
                          style={{ marginTop: "10px " }}
                        >
                          Add Row
                        </CustomButton>
                      ) : (
                        ""
                      )}
                    </Table>
                  </CustomTableContainer>
                ) : (
                  <HybridTable
                    rows={rows}
                    setRows={setRows}
                    // approvers={approvers}
                    // persons={persons}
                    emails={emails}
                    roles={roles}
                  />
                )}

                <br />
                <br />
                <br />
              </>
            </CardContent>
          </StyledCard>
        </>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />

          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "200px",
              height: "40px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
            disabled={selection !== "hybrid" ? !itemswf.length : !rows.length}
          >
            Add Sequence
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}

export default WorkflowStepsContent;
