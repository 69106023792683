import {
  Box,
  CardContent,
  Card,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  TableBody,
  TableHead,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { useEffect, useState } from "react";
import {
  CustomButton,
  DocumentLabels,
  SelectSpan,
  StyledCard,
  StyledOutlineButton,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import {
  Super,
  VendorCardSubTitle,
} from "../../vendorRegistration/components/steps/cardTrial";
import { VendorCardTitle } from "../../vendorRegistration/components/steps/vendorReg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalStorage from "../../../utilities/localStorage";
import { useForm } from "react-hook-form";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  getLots,
  getPackagingType,
  getItems,
} from "../../asnModule/redux/asnModuleSlice";
import { history } from "../../../app/history";
import { createAsn } from "../redux/asnModuleSlice";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const Input = styled("input")({
  display: "none",
});

function AsnModuleContent() {
  const dispatch = useDispatch();

  const { lotNumbers, itemList } = useSelector((state) => state.asnModule);

  const [asnDoc, setAsnDoc] = useState("");
  const [items, setItems] = useState([]);
  const [newDocs, setNewDocs] = useState([1]);
  const [supportingDoc, setSupportingDoc] = useState([]);
  const [companyId, setCompanyId] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const lotInfo = (payload) => {
    dispatch(getLots(payload));
  };
  const getPackagingTypeInfo = (payload) => {
    dispatch(getPackagingType(payload));
  };

  useEffect(() => {
    lotInfo();
    getPackagingTypeInfo();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setItems(itemList);
  }, [itemList]);

  const handleLotNumberChange = (e) => {
    const lot_id = e.target.value;
    dispatch(getItems(lot_id));
    var temp = lotNumbers.filter((lotNumbers) => lotNumbers.id === lot_id);
    setCompanyId(temp[0]?.CompanyId);
  };

  const handleOnClick = () => {
    let docsCopy = [...newDocs];
    docsCopy.push({
      supporting_docs: "",
    });
    setNewDocs(docsCopy);
  };

  const handleRemove = (e, i) => {
    let uploadeddocs = [...newDocs];
    uploadeddocs.splice(i, 1);
    setNewDocs(uploadeddocs);
  };

  const handlechangeDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: e.target.files[0] };
    setSupportingDoc(newDocValues);
  };

  const handleclearDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: "" };

    setSupportingDoc(newDocValues);
  };

  const handleSave = (data) => {
    console.log("handleSave");
    var user = LocalStorage.getItem("tata_login_user");
    const vendor_id = user.vendor_id;
    let asn_details = [
      {
        vendor_id: vendor_id,
        company_id: companyId,
        LotId: data.lot_number,
        asn_number: data.asn_number,
        expected_arrival_date: data.expected_arrival_date,
        carrier_name: data.carrier_name,
        handling_instructions: data.handling_instructions,
        items: items,
      },
    ];
    var formData = new FormData();
    formData.append("data", JSON.stringify(asn_details));
    formData.append("asn_document", asnDoc);
    for (let i = 0; i < newDocs.length; i++) {
      formData.append("supporting_documents", supportingDoc[i]);
    }
    dispatch(createAsn(formData)).then((response) => {
      if (response?.payload?.success) {
        history.push("/app/user-asns-list");
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Create New Advanced Shipping Notice
                </VendorCardTitle>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <VendorCardSubTitle style={{ marginTop: "0" }}>
                  Select the lot and enter ASN details
                </VendorCardSubTitle>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>Lot number</VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="lot_number"
                    name="lot_number"
                    {...register("lot_number", {
                      required: false,
                    })}
                    onChange={handleLotNumberChange}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select lot number
                      </span>
                    </MenuItem>
                    {lotNumbers
                      ? lotNumbers.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            <SelectSpan>{item.lot_number}</SelectSpan>
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>
                  ASN number<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter ASN number"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="asn_number"
                  {...register("asn_number", {
                    required: "Please enter ASN number",
                  })}
                  error={Boolean(errors?.asn_number)}
                  helperText={errors?.asn_number?.message}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={1}>
              <Grid item md={12}>
                <VendorCardLabel>Handling Instructions</VendorCardLabel>
                <TextField
                  multiline
                  rows={4}
                  placeholder="Enter handling instructions"
                  style={{
                    width: "100%",
                    paddingTop: "3px",
                    fontSize: "8px",
                    fontFamily: "var(--font-family-reg)",
                  }}
                  name="handling_instructions"
                  {...register("handling_instructions", {
                    required: false,
                  })}
                />
              </Grid>
            </Grid>

            <br />
            <VendorCardTitle style={{ paddingBottom: "20px" }}>
              Items in Lot
            </VendorCardTitle>
            <CustomTableContainer component={Card}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "#e1ebfa",
                    }}
                  >
                    <TableCell width="10%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Sr. No.</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Item Name</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Packaging Type</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Quantity</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Unit Price</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Total Price</TableHeaderFont>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          width="10%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.item_name ? row.item_name : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.packaging_type
                            ? row.packaging_type
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.quantity ? row.quantity : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.unit_price ? row.unit_price : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {parseFloat(row.unit_price * row.quantity).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </CustomTableContainer>
            <br />
            <br />
            <VendorCardTitle style={{ paddingBottom: "20px" }}>
              Documents
            </VendorCardTitle>
            <Grid container spacing={3}>
              <>
                <Grid item xs={4}>
                  <span
                    variant="outlined"
                    component="span"
                    style={{ width: "50%" }}
                  >
                    <DocumentLabels style={{ marginTop: "10px" }}>
                      ASN Document <Super>*</Super>
                    </DocumentLabels>
                  </span>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    variant="standard"
                    style={{ width: "100%" }}
                    error={Boolean(errors?.asn_document)}
                  >
                    {asnDoc !== "" ? (
                      <>
                        <span
                          style={{
                            color: "#4945FF",
                            fontSize: "13px",
                            fontFamily: "var(--font-family-reg)",
                          }}
                        >
                          <Chip
                            style={{
                              width: "150px",
                              color: "#4945FF",
                              margin: "5px",
                              borderRadius: "5px",
                              border: "1px solid #D9D8FF",
                              background: "#F0F0FF",
                            }}
                            label={asnDoc.name}
                            value={asnDoc.name}
                            name="chips"
                          />
                          <HighlightOffIcon
                            sx={{ color: "#4945FF", paddingBottom: "2px" }}
                            fontSize="medium"
                            onClick={(e) => setAsnDoc("")}
                          />
                        </span>
                      </>
                    ) : (
                      <label htmlFor="asn_document">
                        <Input
                          {...register("asn_document", {
                            required: "Please upload ASN document",
                          })}
                          accept="*"
                          id="asn_document"
                          onChange={(e) => setAsnDoc(e.target.files[0])}
                          type="file"
                        />
                        <StyledOutlineButton
                          variant="outlined"
                          component="span"
                        >
                          Upload
                        </StyledOutlineButton>
                      </label>
                    )}
                    {asnDoc === "" ? (
                      <FormHelperText error>
                        {errors.asn_document?.message}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
              </>
            </Grid>
            {newDocs.map((item, index) => (
              <>
                <br />
                <Grid container spacing={3} key={index}>
                  <>
                    <Grid item xs={4} key={index}>
                      <span
                        variant="outlined"
                        component="span"
                        style={{ width: "50%" }}
                      >
                        <DocumentLabels style={{ marginTop: "10px" }}>
                          Supporting Document
                        </DocumentLabels>
                      </span>
                    </Grid>
                    {supportingDoc[index] !== "" &&
                    supportingDoc[index] !== undefined ? (
                      <>
                        <Grid item xs={3} key={index}>
                          <FormControl
                            variant="standard"
                            style={{ width: "100%" }}
                          >
                            <span
                              style={{
                                color: "#4945FF",
                                fontSize: "13px",
                                fontFamily: "var(--font-family-reg)",
                              }}
                            >
                              <Chip
                                style={{
                                  width: "150px",
                                  color: "#4945FF",
                                  margin: "5px",
                                  borderRadius: "5px",
                                  border: "1px solid #D9D8FF",
                                  background: "#F0F0FF",
                                }}
                                label={supportingDoc[index].name}
                                value={supportingDoc[index].name}
                                name="chips"
                              />
                              <HighlightOffIcon
                                sx={{ color: "#4945FF", paddingBottom: "2px" }}
                                fontSize="medium"
                                onClick={(e) => handleclearDoc(e, index)}
                              />
                            </span>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          {index === 0 ? (
                            <CustomButton
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={handleOnClick}
                              className="invoiceErrbutn"
                            >
                              Add Document
                            </CustomButton>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={2}>
                          <FormControl
                            variant="standard"
                            style={{ width: "100%" }}
                          >
                            <label>
                              <Input
                                accept="*"
                                id={`supporting_doc_${index}`}
                                onChange={(e) => handlechangeDoc(e, index)}
                                type="file"
                              />
                              <StyledOutlineButton
                                variant="outlined"
                                component="span"
                              >
                                Upload
                              </StyledOutlineButton>
                            </label>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          {index === 0 ? (
                            <CustomButton
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={handleOnClick}
                              className="invoiceErrbutn"
                              style={{ marginTop: "10px " }}
                            >
                              Add Document
                            </CustomButton>
                          ) : (
                            <CustomButton
                              className="invoiceErrbutn"
                              color="error"
                              variant="outlined"
                              size="small"
                              onClick={(e) => handleRemove(e, index)}
                              style={{ marginTop: "10px " }}
                            >
                              Remove
                            </CustomButton>
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                </Grid>
              </>
            ))}
          </CardContent>
        </StyledCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <CustomButton
            sx={{
              textTransform: "none",
              background: "var(--button-color)",
              width: "200px",
              height: "40px",
            }}
            color="success"
            variant="contained"
            type="submit"
          >
            Submit
          </CustomButton>
        </Box>
      </form>
    </>
  );
}

export default AsnModuleContent;