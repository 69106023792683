import React from "react";
import moment from "moment";
import {
  StyledCard,
  VendorCardLabel,
} from "../../features/vendorRegistration/components/StepMain";
import { CardContent, Grid, Typography } from "@mui/material";

const ViewComponent = ({ data, ignoredFields = [] }) => {
  const formatField = (key, value) => {
    if (!value) return "Not Applicable";

    if (["createdAt", "updatedAt"].includes(key)) {
      return moment(value).format("DD MMM YYYY hh:mm:ss a");
    }

    return value.toString();
  };

  return (
    <StyledCard>
      <CardContent>
        <Grid container spacing={3}>
          {Object.entries(data)
            .filter(([key]) => !ignoredFields.includes(key))
            .map(([key, value], index) => (
              <Grid item xs={4} md={4} key={index}>
                <Typography className="label">
                  {key.replace(/_/g, " ").toUpperCase()}
                </Typography>
                <VendorCardLabel>{formatField(key, value)}</VendorCardLabel>
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default ViewComponent;
