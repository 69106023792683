import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

export const Links = styled(Link)`
  padding: 10px 5px 4px 40px;
  text-decoration: none;
  margin-top: 10px;
  color: white;
  display: block;
  :hover {
    background-color: #307fe2 !important;
  }
  @media screen and (max-height: 450px) {
    font-size: 18px;
  }
`;

export const List = styled.li`
  list-style: none;
  padding: 5px 0px;
  :hover {
    left: 0px;
  }
`;

export const IconDiv = styled.div``;

export const Hover = styled.div`
  overflow: hidden;
  float: left;
  position: relative;
  opacity: 0;
  transition: all ease-in-out 0.2s;
`;

export const SideBarContainer = styled.ul`
  overflow: visible;
  width: 18%;
  transform: translateX(0);
  position: fixed;
  padding-left: 0;
  list-style-type: none;
  background-color: #4059e5 !important;
  height: calc(100% + 60px);
  padding-bottom: 60px;
  z-index: 999;
  will-change: transform;
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

export const Div = styled.div`
  position: relative;
  z-index: 9999;
  margin-top: 10px;
`;

export const LI = styled.li`
  width: 180px;
  transition: all 0.5s ease;
  position: relative;
  left: -100px;
  padding: 5px 0px;
  :hover {
    left: 0px;
    border-left: 4px solid white;
  }
  :active {
    border-left: 4px solid white;
  }
  :focus {
    border-left: 4px solid white;
  }
`;

export const A = styled(NavLink)`
  font-family: var(--font-family-med);
  font-weight: 500;
  border-radius: 5px;
  margin: 10px;
  overflow: hidden;
  color: #fff;
  display: block;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 20px;
  text-decoration: none;
  background-color: transparent;
  text-align: left;
  border-bottom: none;

  :hover {
    color: #32324d;
    margin: 10px;
    background-color: #fff !important;
  }

  @media screen and (max-width: 500px) {
    padding: inherit;
    text-align: center;
    font-size: 10px;
  }
`;
export const LinksContainer = styled.div`
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  width: 100%; /* Ensure the container takes full width */
  overflow-y: scroll; /* Enable vertical scrolling */
  height: calc(100vh - 280px); /* Set a maximum height to trigger scrolling */
  /* Style the scrollbar */
  ::-webkit-scrollbar {
    width: 5px; /* Set the width of the scrollbar */
  }
  /* Track style */
  ::-webkit-scrollbar-track {
    background: transparent; /* Set a transparent background */
  }
  /* Handle style */
  ::-webkit-scrollbar-thumb {
    background: #d3d3d3; /* Set the color of the scrollbar thumb */
    border-radius: 10px; /* Set the border radius */
  }
`;
