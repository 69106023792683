import {
  CardContent,
  Grid,
  Chip,
  Typography,
  TableHead,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  AdminTitleHolder,
  CustomDocumentLink,
} from "../../../common/sharedComponents/styles/card.style";
import { getScheduleDetails } from "../redux/userSchedulesSlice";
import { styled as materialStyles } from "@mui/material/styles";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Card from "@mui/material/Card";
import styled from "styled-components";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import InfoIcon from "@mui/icons-material/Info";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import UpdateIcon from "@mui/icons-material/Update";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { AddButton } from "../../bankDetails/components/bankAccountsContent";
import moment from "moment";

function UserSchedulesViewContent() {
  const { schedule_details } = useSelector((state) => state.userSchedules);
  const params = useParams();
  const dispatch = useDispatch();

  console.log("schedule_details :", schedule_details);

  const order_id = params.orderid;
  const viewScheduleDetails = (order_id) => {
    dispatch(getScheduleDetails(order_id));
  };

  // const lots_summary = {
  // item_list: [
  //   {
  //     id: 1,
  //     lot_number: "1234",
  //     expected_delivery_date: "03 Jan 2024",
  //     status: "Rolled",
  //   },
  //   {
  //     id: 2,
  //     lot_number: "5678",
  //     expected_delivery_date: "05 Jan 2024",
  //     status: "Pending",
  //   },
  // ],
  // };

  useEffect(() => {
    viewScheduleDetails(order_id);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Schedule#
            {schedule_details.schedule_number
              ? schedule_details.schedule_number
              : "Not Applicable"}
          </Grid>
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <VendorCardTitle>Order Details</VendorCardTitle>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label"> COMPANY NAME</Typography>
              <VendorCardLabel>
                {schedule_details.CompanyName
                  ? schedule_details.CompanyName
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label"> TYPE</Typography>
              <VendorCardLabel>
                {schedule_details.type
                  ? schedule_details.type
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">DEPARTMENT</Typography>
              <VendorCardLabel>
                {schedule_details.organizational_unit
                  ? schedule_details.organizational_unit
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">ORDER NUMBER</Typography>
              <VendorCardLabel>
                {schedule_details.order_number
                  ? schedule_details.order_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">SCHEDULE NUMBER</Typography>
              <VendorCardLabel>
                {schedule_details.schedule_number
                  ? schedule_details.schedule_number
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">ORDER AMOUNT</Typography>
              <VendorCardLabel>
                {schedule_details.order_amount
                  ? schedule_details.order_amount
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <VendorCardTitle>Items in Order</VendorCardTitle>
          <CustomTableContainer component={Card}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#e1ebfa",
                  }}
                >
                  <TableCell width="10%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Sr. No.</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Item Name</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Quantity</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Unit Price</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Total Price</TableHeaderFont>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {schedule_details?.item_list ? (
                  schedule_details.item_list.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          width="10%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.item_name !== null
                            ? row.item_name
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.quantity !== null
                            ? row.quantity
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.unit_price !== null
                            ? row.unit_price
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {parseFloat(row.quantity * row.unit_price).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>
          <br />
          <br />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <div style={{ textAlign: "left" }}>
                <VendorCardTitle>Lots Summary</VendorCardTitle>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  textAlign: "right",
                  paddingBottom: "5px",
                  paddingRight: "10px",
                }}
              >
                <Tooltip title="Create a Lot">
                  <AddButton replace to="/app/lots"></AddButton>
                </Tooltip>
              </div>
            </Grid>
          </Grid>
          <br />
          <CustomTableContainer component={Card}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#e1ebfa",
                  }}
                >
                  <TableCell width="10%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Sr. No.</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Lot Number</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Expected Arrival Date</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Status</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Action</TableHeaderFont>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {schedule_details?.lot_list ? (
                  schedule_details.lot_list.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          width="10%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          <Link
                            style={{ textDecoration: "none" }}
                            // eslint-disable-next-line
                            to={`/app/user-lots-list-view/${row.id}`}
                          >
                            {row.lot_number !== null
                              ? row.lot_number
                              : "Not Applicable"}
                          </Link>
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.expected_arrival_date
                            ? moment(row.expected_arrival_date).format(
                                "DD MMM YY"
                              )
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {row.status !== null ? (
                            <Chip
                              label={
                                row.status
                                  ? row.status.toUpperCase()
                                  : "Not Applicable"
                              }
                              color={
                                row.status === "Pending" ? "warning" : "success"
                              }
                              size="small"
                              style={{ fontSize: "9px" }}
                            />
                          ) : (
                            "Not Applicable"
                          )}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <Tooltip title="View Lot Details">
                            <Link
                              style={{ textDecoration: "none" }}
                              // eslint-disable-next-line
                              to={`/app/user-lots-list-view/${row.id}`}
                            >
                              <RemoveRedEyeIcon
                                style={{
                                  fill: "royalblue",
                                  height: "18px",
                                  width: "18px",
                                }}
                              />
                            </Link>
                          </Tooltip>
                          <Tooltip title="Update Lot Status">
                            <Link
                              style={{ textDecoration: "none" }}
                              // eslint-disable-next-line
                              to={`/app/user-lots-list-edit/${row.id}`}
                            >
                              <UpdateIcon
                                style={{
                                  fill: "royalblue",
                                  height: "17px",
                                  width: "18px",
                                }}
                              />
                            </Link>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>
          <br />
          <br />
          <VendorCardTitle>Documents</VendorCardTitle>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Schedule Document</Typography>
              <VendorCardLabel>
                <Tooltip title="Download Document">
                  <CustomDocumentLink
                    href={schedule_details?.schedule_document}
                    target="_blank"
                  >
                    <InsertDriveFileOutlinedIcon
                      style={{
                        marginTop: "-1px",
                        marginRight: "3px",
                        height: "12px",
                        width: "12px",
                      }}
                    />
                    Schedule document
                  </CustomDocumentLink>
                </Tooltip>
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Supporting Documents</Typography>
              {schedule_details?.supporting_document !== undefined &&
              schedule_details?.supporting_document !== null &&
              schedule_details?.supporting_document.length > 0 ? (
                schedule_details?.supporting_document.map((item, index) => (
                  <VendorCardLabel key={index}>
                    <Tooltip title="Download Document">
                      <CustomDocumentLink href={item} target="_blank">
                        <InsertDriveFileOutlinedIcon
                          style={{
                            marginTop: "-1px",
                            marginRight: "3px",
                            height: "12px",
                            width: "12px",
                          }}
                        />
                        Supporting document
                      </CustomDocumentLink>
                    </Tooltip>
                  </VendorCardLabel>
                ))
              ) : (
                <Typography className="label">
                  <InfoIcon
                    sx={{
                      marginTop: "-1px",
                      marginRight: "1px",
                      fill: "grey",
                      height: "15px",
                      width: "15px",
                    }}
                  />{" "}
                  There are no supporting documents
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    </>
  );
}

export default UserSchedulesViewContent;

export const StyledCard = materialStyles(Card)`
      box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
      flex-direction: column;
      border: none;
      width: 100%;
      height: auto;
      border-radius: 10px;
      margin-bottom: 1em;
      `;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;
