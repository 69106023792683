import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import UserPrivilegesContent from "./components/userPrivilegesContent";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";

function UserPrivileges() {
  return (
    <div>
      <TitleHolder>User Privileges</TitleHolder>
      <StepperContentHolder>
        <UserPrivilegesContent />
      </StepperContentHolder>
    </div>
  );
}

export default UserPrivileges;
