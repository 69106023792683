import apiClient from "../../../utilities/apiClient";

export const get_AdminSchedules = (payload) => {
  return apiClient.get(`${apiClient.Urls.admin_schedules_list}`, payload, true);
};

export const view_ScheduleDetails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.view_admin_schedule_details}/${payload}`,
    "",
    true
  );
};

export const approve_asn = (payload) => {
  // console.log(apiClient.Urls.asn_approve);
  // console.log(payload);
  return apiClient.post(
    `${apiClient.Urls.asn_approve}/${payload.asn_id}`,
    payload,
    true
  );
};