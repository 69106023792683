import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  schedules,
  get_schedules,
  get_order_numbers,
  // view_UserScheduleDetails,
  get_items,
} from "./scheduleModuleAPI";

const initialState = {
  loading: false,
  orderNumbers: [],
  schedulesList: [],
  user_schedule_details: [],
  itemList: [],
};
const actions = {
  GETITEMS: "getItems/GETITEMS",
  CREATESCHEDULE: "createSchedule/CREATESCHEDULE",
  GETSCHEDULES: "getSchedules/GETSCHEDULES",
  GETORDERNUMBERS: "getOrders/GETORDERNUMBERS",
  GETPACKAGINGTYPES: "getPackagingTypes/GETPACKAGINGTYPES",
  VIEWUSERSCHEDULEDETAILS: "getUserScheduleDetails/VIEWUSERSCHEDULEDETAILS",
};

export const createSchedule = createAsyncThunk(
  actions.CREATESCHEDULE,
  async (payload) => {
    const response = await schedules(payload);
    return response;
  }
);

// export const getUserScheduleDetails = createAsyncThunk(
//   actions.VIEWUSERASNDETAILS,
//   async (payload) => {
//     const response = await view_UserScheduleDetails(payload);
//     return response;
//   }
// );
export const getOrders = createAsyncThunk(
  actions.GETORDERNUMBERS,
  async (payload) => {
    const response = await get_order_numbers(payload);
    return response;
  }
);

export const getSchedules = createAsyncThunk(
  actions.GETASNS,
  async (payload) => {
    const response = await get_schedules(payload);
    return response;
  }
);

export const getItems = createAsyncThunk(actions.GETITEMS, async (payload) => {
  const response = await get_items(payload);
  return response;
});

export const scheduleModuleSlice = createSlice({
  name: "scheduleModule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder
    //   .addCase(getUserScheduleDetails.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(getUserScheduleDetails.fulfilled, (state, action) => {
    //     const { success, message: msg, data } = action.payload;
    //     state.loading = false;
    //     state.user_schedule_details = data;
    //     if (success) {
    //       /*  message.success(msg); */
    //     } else {
    //       message.error(msg);
    //     }
    //   })
    //   .addCase(getUserScheduleDetails.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.loading = false;

    //     message.error(msg);
    //   });

    builder
      .addCase(createSchedule.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSchedule.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createSchedule.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.orderNumbers = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getOrders.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getSchedules.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSchedules.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.schedulesList = data;
        console.log(data);
        if (success) {
        } else {
          message.error(msg);
        }
      })
      .addCase(getSchedules.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.itemList = data;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
        console.log(data);
      })
      .addCase(getItems.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const schedulesModuleActions = scheduleModuleSlice.actions;
export const { resetSchedulesModuleDetails } = scheduleModuleSlice.actions;

export default scheduleModuleSlice.reducer;
