import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  asns,
  get_asns,
  get_lot_numbers,
  get_packaging_types,
  view_UserAsnDetails,
  get_lot_items,
} from "./asnModuleAPI";

const initialState = {
  loading: false,
  lotNumbers: [],
  asnsList: [],
  packagingTypes: [],
  user_asn_details: [],
  itemList: [],
};
const actions = {
  GETITEMS: "getItems/GETITEMS",
  CREATEASN: "createAsn/CREATEASN",
  GETASNS: "getAsns/GETASNS",
  GETLOTNUMBERS: "getLots/GETLOTNUMBERS",
  GETPACKAGINGTYPES: "getPackagingTypes/GETPACKAGINGTYPES",
  VIEWUSERASNDETAILS: "getUserAsnDetails/VIEWUSERASNDETAILS",
};

export const createAsn = createAsyncThunk(
  actions.CREATEASN,
  async (payload) => {
    const response = await asns(payload);
    return response;
  }
);

export const getUserAsnDetails = createAsyncThunk(
  actions.VIEWUSERASNDETAILS,
  async (payload) => {
    const response = await view_UserAsnDetails(payload);
    return response;
  }
);
export const getLots = createAsyncThunk(
  actions.GETLOTNUMBERS,
  async (payload) => {
    const response = await get_lot_numbers(payload);
    return response;
  }
);

export const getAsns = createAsyncThunk(actions.GETASNS, async (payload) => {
  const response = await get_asns(payload);
  return response;
});

export const getPackagingType = createAsyncThunk(
  actions.GETPACKAGINGTYPES,
  async (payload) => {
    const response = await get_packaging_types(payload);
    return response;
  }
);

export const getItems = createAsyncThunk(actions.GETITEMS, async (payload) => {
  const response = await get_lot_items(payload);
  return response;
});

export const asnModuleSlice = createSlice({
  name: "asnModule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserAsnDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserAsnDetails.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.user_asn_details = data;
        if (success) {
          /*  message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getUserAsnDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createAsn.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAsn.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        console.log("dddd");
        state.loading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createAsn.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getLots.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLots.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.lotNumbers = data;
        if (success) {
          /* message.success(msg); */
        } else {
          message.error(msg);
        }
      })
      .addCase(getLots.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAsns.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAsns.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.asnsList = data;
        console.log(data);
        if (success) {
        } else {
          message.error(msg);
        }
      })
      .addCase(getAsns.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getPackagingType.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPackagingType.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.packagingTypes = data;
      })
      .addCase(getPackagingType.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.loading = false;
        state.itemList = data;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
        console.log(data);
      })
      .addCase(getItems.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const asnsModuleActions = asnModuleSlice.actions;
export const { resetAsnsModuleDetails } = asnModuleSlice.actions;

export default asnModuleSlice.reducer;