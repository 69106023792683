import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  StyledCard,
  VendorCardLabel,
} from "../../features/vendorRegistration/components/StepMain";
import { Box, CardContent, Grid, TextField } from "@mui/material";
import { VendorCardTitle } from "../../features/adminOrders/components/adminOrdersViewContent";
import { Super } from "../../features/vendorRegistration/components/steps/cardTrial";
import { LoadingButton } from "@mui/lab";
import GlobalAutocomplete from "./AutoComplate";

export const TextBoxStyle = {
  input: {
    fontSize: "14px",
    fontFamily: "var(--font-family-reg)",
  },
};
const FormComponent = ({
  fields,
  initialValues,
  onSubmit,
  heading,
  isLoading,
}) => {
  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: initialValues || {},
  });

  React.useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <VendorCardTitle style={{ paddingBottom: "0" }}>
                {heading}
              </VendorCardTitle>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {fields.map((field) =>
              field?.type !== "dropdown" ? (
                <Grid item xs={12} sm={6} md={4} key={field.name}>
                  <VendorCardLabel key={field.name}>
                    {field.label}
                    {field.required && <Super>*</Super>}
                  </VendorCardLabel>
                  <Controller
                    name={field.name}
                    control={control}
                    rules={{
                      required: field.required ? field.requiredMessage : false,
                      pattern: field.pattern
                        ? {
                            value: field.pattern,
                            message: field.patternMessage || "Invalid format",
                          }
                        : undefined,
                    }}
                    placeholder={field.placeholder}
                    render={({
                      field: controllerField,
                      fieldState: { error },
                    }) => {
                      return (
                        <TextField
                          {...controllerField}
                          type={field.type}
                          sx={TextBoxStyle}
                          placeholder={field.placeholder}
                          value={watch(field.name)}
                          style={{
                            width: "100%",
                            paddingTop: "3px",
                            borderBottom: "1px solid lightgray",
                          }}
                          size="small"
                          variant="standard"
                          error={!!error}
                          helperText={error ? error.message : null}
                          InputProps={{
                            disableUnderline: false,
                            disableInjectingGlobalStyles: true,
                          }}
                          InputLabelProps={{
                            disableAnimation: true,
                            disableInjectingGlobalStyles: true,
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} md={4} key={field.name}>
                  <VendorCardLabel>{field.lable}</VendorCardLabel>
                  <GlobalAutocomplete
                    name={field?.name}
                    control={control}
                    label={field.label}
                    options={field.options?.flatMap((el) => {
                      return {
                        key: el.id,
                        value: el[field?.name],
                      };
                    })}
                    placeholder={field.placeholder}
                    isMultiple={field?.isMultiple}
                    rules={{ required: field?.requiredMessage }}
                  />
                </Grid>
              )
            )}
          </Grid>
        </CardContent>
      </StyledCard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ flex: "1 1 auto" }} />
        <LoadingButton
          sx={{
            textTransform: "none",
            background: "#4059e5",
            width: "200px",
            height: "40px",
          }}
          color="primary"
          variant="contained"
          type="submit"
          loading={isLoading}
          loadingPosition="start"
        >
          Submit
        </LoadingButton>
      </Box>
    </form>
  );
};

export default FormComponent;
