import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StyledCard } from "../../vendorRegistration/components/StepMain";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getBanks, removeBank } from "../redux/bankDetailsSlice";
import empty from "../../../assets/illo.png";
import { getLoggedInUser } from "../../../utilities/helper";
import LocalStorage from "../../../utilities/localStorage";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { Super } from "../../vendorRegistration/components/steps/cardTrial";

const Empty = () => (
  <>
    <Parent>
      <Child>
        <img src={empty} alt="empty" />
      </Child>
    </Parent>
    <div style={{ textAlign: "center", padding: "15px" }}>
      <DisplayText> There are no bank accounts to display</DisplayText>
    </div>
    <div style={{ textAlign: "center", marginLeft: "5px" }}>
      <ButtonLink to="/app/bank-details/add-bank">+ Add Bank</ButtonLink>
    </div>
  </>
);

export const BankCard = ({ item, is_domestic }) => {
  const {
    id,
    bank_name,
    city,
    branch_name,
    account_name,
    account_number,
    is_primary_account,
    currency,
  } = item;
  const { loading } = useSelector((state) => state.bankdetails);
  const [openDialog, setOpenDialog] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const vid = LocalStorage.getItem("tata_login_user")?.vendor_id;
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    const payload = {
      bank_id: id,
      vid,
      reason: data.reason,
    };
    dispatch(removeBank(payload));
    setOpenDialog(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;
  return (
    <>
      <StyledCard sx={{ /*  maxWidth: 400, */ border: "1px solid #EAEAEF" }}>
        <CardHeader
          action={
            getLoggedInUser().roles[0] !== "super admin" ||
            getLoggedInUser().roles[0] !== "finance" ? (
              <IconButton aria-label="settings">
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch",
                    },
                  }}
                >
                  <MenuItem onClick={(handleClose, () => setOpenDialog(true))}>
                    {"Delete"}
                  </MenuItem>
                </Menu>
                <MoreVertIcon onClick={handleClick} />
              </IconButton>
            ) : (
              ""
            )
          }
          title={
            <BankName style={{ textAlign: "left", padding: "0", margin: "0" }}>
              {bank_name}
              {is_primary_account ? (
                <span
                  style={{
                    fontSize: "14px",
                    borderRadius: "25px",
                    background: "#D55EE1",
                    padding: "1px 20px",
                    width: "93px",
                    height: "22px",
                    color: "white",
                    marginLeft: "10px",
                  }}
                >
                  Primary
                </span>
              ) : (
                ""
              )}
            </BankName>
          }
        />
        <hr
          style={{
            padding: "0",
            margin: "0",
            border: "1px solid #DCDCE4",
            backgroundColor: "#DCDCE4",
            height: "0.1px",
          }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LeftDetailsBankCard>City:</LeftDetailsBankCard>
            </Grid>
            <Grid sx={{ textAlign: "right" }} item xs={6}>
              <RightDetailsBankCard>{city}</RightDetailsBankCard>
            </Grid>
            <Grid item xs={6}>
              <LeftDetailsBankCard>Branch:</LeftDetailsBankCard>
            </Grid>
            <Grid sx={{ textAlign: "right" }} item xs={6}>
              <RightDetailsBankCard>{branch_name}</RightDetailsBankCard>
            </Grid>
            <Grid item xs={6}>
              <LeftDetailsBankCard>A/C name:</LeftDetailsBankCard>
            </Grid>
            <Grid sx={{ textAlign: "right" }} item xs={6}>
              <RightDetailsBankCard>{account_name}</RightDetailsBankCard>
            </Grid>
            <Grid item xs={6}>
              <LeftDetailsBankCard>
                {is_domestic ? "A/C number:" : "IBA Number"}
              </LeftDetailsBankCard>
            </Grid>
            <Grid sx={{ textAlign: "right" }} item xs={6}>
              <RightDetailsBankCard>{account_number}</RightDetailsBankCard>
            </Grid>
            <Grid item xs={6}>
              <LeftDetailsBankCard>Currency:</LeftDetailsBankCard>
            </Grid>
            <Grid sx={{ textAlign: "right" }} item xs={6}>
              <RightDetailsBankCard style={{ textTransform: "uppercase" }}>
                {currency}
              </RightDetailsBankCard>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
      {/* dialog  box*/}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move", fontWeight: "Bold" }}>
          Reason for Deleting Bank Information
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Reason for deletion.<Super>*</Super>
          </DialogContentText>
          <DialogContentText>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl
                variant="standard"
                style={{ width: "100%" }}
                error={Boolean(errors?.reason)}
              >
                <TextField
                  style={{ width: "100%" }}
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter Reason"
                  variant="standard"
                  name="reason"
                  {...register("reason", {
                    required: "please enter reason",
                  })}
                  error={Boolean(errors?.reason)}
                  helperText={errors.reason?.message}
                />
              </FormControl>
              <Grid
                sx={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  autoFocus
                  onClick={handleClose}
                  style={{ height: "40px", border: "#4059e5 solid 1px" }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    textTransform: "none",
                    background: "#4059e5",
                    width: "200px",
                    height: "40px",
                  }}
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                >
                  DELETE
                </LoadingButton>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ alignItems: "right" }}></DialogActions>
      </Dialog>
    </>
  );
};

function BankAccountsContent() {
  const { states, runApi } = useSelector((state) => state.bankdetails);
  const is_domestic = LocalStorage.getItem("tata_login_user");
  const dispatch = useDispatch();

  const bankinfo = (payload) => {
    dispatch(getBanks(payload));
  };
  useEffect(() => {
    bankinfo();
    //eslint-disable-next-line
  }, [runApi]);
  return (
    <>
      {states.length === 0 ? (
        <Empty />
      ) : (
        <Grid container spacing={3}>
          {states.map((item, index) => (
            <Grid key={index} item xs={12} md={5}>
              <BankCard item={item} is_domestic={is_domestic} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}

export default BankAccountsContent;

export const AddButton = styled(Link)`
  float: right;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  position: relative;
  background: #4dc4aa;
  box-shadow: 0px 0px 25px 5px rgba(51, 51, 51, 0.2);
  ::after {
    content: " ";
    position: absolute;
    display: block;
    background-color: #fff;
    height: 2px;
    margin-top: -1px;
    top: 50%;
    left: 13px;
    right: 13px;
    z-index: 9;
  }
  ::before {
    content: " ";
    position: absolute;
    display: block;
    background-color: #fff;
    width: 2px;
    margin-left: -1px;
    left: 50%;
    top: 13px;
    bottom: 13px;
    z-index: 9;
  }
`;
export const BankName = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */

  /* Neutral/900 */

  color: #212134;
`;

export const LeftDetailsBankCard = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Neutral/600 */

  color: #666687;
`;

export const DisplayText = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 16x;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Neutral/600 */

  color: #666687;
`;

export const RightDetailsBankCard = styled.div`
  font-family: var(--font-family-med);

  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Neutral/900 */

  color: #212134;
  /* identical to box height, or 150% */

  /* Neutral/600 */
`;

export const Parent = styled.div`
  padding-top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Child = styled.div`
  > img {
    margin-bottom: "15px";
  }
`;

export const ButtonLink = styled(Link)`
  border: 1px solid var(--button-color);
  background-color: #ffff;
  color: black;
  border-radius: 4px;
  font-size: 14px;

  height: 36px;
  padding: 7px 18px 7px 18px;
  font-weight: 700;
  font-family: var(--font-family-reg);
  text-decoration: none;
  :hover {
    color: black;
  }
`;
