import {
  Box,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  TableHead,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  SelectSpan,
  StyledCard,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import Card from "@mui/material/Card";
import {
  Super,
  VendorCardSubTitle,
} from "../../vendorRegistration/components/steps/cardTrial";
import { VendorCardTitle } from "../../vendorRegistration/components/steps/vendorReg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalStorage from "../../../utilities/localStorage";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getLots,
  getItems,
} from "../../inspectionModule/redux/inspectionModuleSlice";
import { history } from "../../../app/history";
import { createInspection } from "../redux/inspectionModuleSlice";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {
  RadioColumn,
  Row,
} from "../../../common/sharedComponents/styles/authRight.style";

function InspectionModuleContent() {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const { lotNumbers, itemList } = useSelector(
    (state) => state.inspectionModule
  );

  const [companyId, setCompanyId] = useState("");
  const [loading, setLoading] = useState(false);
  const [internal, setInternal] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const lotInfo = (payload) => {
    dispatch(getLots(payload));
  };

  useEffect(() => {
    lotInfo();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setItems(itemList);
  }, [itemList]);

  const handleLotChange = (e) => {
    let id = e.target.value;
    var total_inv_amt = lotNumbers.filter((lotNumbers) => lotNumbers.id === id);
    setCompanyId(total_inv_amt[0]?.CompanyId);
    dispatch(getItems(id));
  };

  const makeInternal = () => {
    setInternal(true);
  };
  const makeExternal = () => {
    setInternal(false);
  };

  const handleSave = (data) => {
    setLoading(true);
    var user = LocalStorage.getItem("tata_login_user");
    const user_id = user.user_id;
    dispatch(
      createInspection(
        internal
          ? {
              lot_id: data.lot_number,
              inspection_number: data.inspection_number,
              inspection_type: "internal",
              email: data.email,
              full_name: data.full_name,
              designation: data.designation,
              phone_number: data.phone_number,
              directives: data.directives,
              company_id: companyId,
              user_id: user_id,
            }
          : {
              lot_id: data.lot_number,
              inspection_number: data.inspection_number,
              inspection_type: "external",
              email: data.email,
              agency_name: data.agency_name,
              directives: data.directives,
              company_id: companyId,
              user_id: user_id,
            }
      )
    ).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        history.push("/app/user-inspections-list");
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Request an Inspection
                </VendorCardTitle>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <VendorCardSubTitle style={{ marginTop: "0" }}>
                  Select the type of inspection, lot to be inspected and enter
                  inspection number
                </VendorCardSubTitle>
              </Grid>
            </Grid>
            <br />
            <Row>
              <RadioColumn>
                <Radio>
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      defaultChecked
                      onClick={makeInternal}
                    />
                    <span>Internal Inspection</span>
                  </label>
                </Radio>
              </RadioColumn>
              <RadioColumn>
                <Radio>
                  <label>
                    <input type="radio" name="radio" onClick={makeExternal} />
                    <span>External Inspection</span>
                  </label>
                </Radio>
              </RadioColumn>
            </Row>
            <br />
            <Grid container spacing={2}>
              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>Lot number</VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="lot_number"
                    name="lot_number"
                    {...register("lot_number", {
                      required: false,
                    })}
                    onChange={handleLotChange}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select lot number
                      </span>
                    </MenuItem>
                    {lotNumbers
                      ? lotNumbers.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            <SelectSpan>{item.lot_number}</SelectSpan>
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>
                  Inspection Number<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter inspection number"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="inspection_number"
                  {...register("inspection_number", {
                    required: "Please enter inspection number",
                  })}
                  error={Boolean(errors?.inspection_number)}
                  helperText={errors?.inspection_number?.message}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item md={8}>
                <VendorCardSubTitle style={{ marginTop: "0" }}>
                  Enter details of the invitee(inspector) below:
                </VendorCardSubTitle>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardLabel>
                  Email<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter email"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="email"
                  {...register("email", {
                    required: "Please enter email",
                  })}
                  error={Boolean(errors?.email)}
                  helperText={errors?.email?.message}
                />
              </Grid>
              {internal ? (
                <Grid item md={6}>
                  <VendorCardLabel>
                    Full Name<Super>*</Super>
                  </VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    placeholder="Enter full name"
                    size="small"
                    variant="standard"
                    style={{ width: "100%", paddingTop: "3px" }}
                    name="full_name"
                    {...register("full_name", {
                      required: "Please enter full name",
                    })}
                    error={Boolean(errors?.full_name)}
                    helperText={errors?.full_name?.message}
                  />
                </Grid>
              ) : (
                <Grid item md={6}>
                  <VendorCardLabel>
                    Agency Name<Super>*</Super>
                  </VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    placeholder="Enter agency name"
                    size="small"
                    variant="standard"
                    style={{ width: "100%", paddingTop: "3px" }}
                    name="agency_name"
                    {...register("agency_name", {
                      required: "Please enter agency name",
                    })}
                    error={Boolean(errors?.agency_name)}
                    helperText={errors?.agency_name?.message}
                  />
                </Grid>
              )}
              {internal ? (
                <Grid item md={6}>
                  <VendorCardLabel>
                    Designation<Super>*</Super>
                  </VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    placeholder="Enter designation"
                    size="small"
                    variant="standard"
                    style={{ width: "100%", paddingTop: "3px" }}
                    name="designation"
                    {...register("designation", {
                      required: "Please enter designation",
                    })}
                    error={Boolean(errors?.designation)}
                    helperText={errors?.designation?.message}
                  />
                </Grid>
              ) : (
                ""
              )}
              {internal ? (
                <Grid item md={6}>
                  <VendorCardLabel>
                    Phone Number<Super>*</Super>
                  </VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    type="number"
                    placeholder="Enter phone number"
                    size="small"
                    variant="standard"
                    style={{ width: "100%", paddingTop: "3px" }}
                    name="phone_number"
                    {...register("phone_number", {
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Enter 10 digit number only",
                      },
                      required: "Please enter phone number",
                    })}
                    error={Boolean(errors?.phone_number)}
                    helperText={errors?.phone_number?.message}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <br />
            <br />
            <VendorCardTitle>Item Details</VendorCardTitle>
            <CustomTableContainer component={Card}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "#e1ebfa",
                    }}
                  >
                    <TableCell width="10%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Sr. No.</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Item Name</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Quantity</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Unit Price</TableHeaderFont>
                    </TableCell>
                    <TableCell width="20%" style={{ textAlign: "center" }}>
                      <TableHeaderFont>Total Price</TableHeaderFont>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          width="10%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.item_name !== null
                            ? row.item_name
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.quantity !== null
                            ? row.quantity
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.unit_price !== null
                            ? row.unit_price
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.quantity * row.unit_price}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </CustomTableContainer>
            <br />
            <br />
            <Grid container spacing={1}>
              <Grid item md={12}>
                <VendorCardLabel>Directives (Optional)</VendorCardLabel>
                <TextField
                  multiline
                  rows={4}
                  placeholder="Provide details on what checks to perform, precautions to take, and what items to focus more.."
                  style={{
                    width: "100%",
                    paddingTop: "3px",
                    fontSize: "8px",
                    fontFamily: "var(--font-family-reg)",
                  }}
                  name="directives"
                  {...register("directives", {
                    required: false,
                  })}
                />
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "200px",
              height: "40px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            Send Invite
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}

export default InspectionModuleContent;

export const Radio = styled.div`
  float: left;
  width: 50%;
  font-family: var(--font-family-reg);
  font-size: 14px;
  color: #32324d;
  label input {
    position: absolute;
    left: -9999px;
  }

  label input:checked + span {
    background-color: #d9d8ff;
    white-space: nowrap;
    border: 1px solid #4945ff;
  }

  label input:checked + span:before {
    border: 1.5px solid white;
    box-shadow: 0 0 0 1px #4945ff, inset 0 0 0 0.5em #4945ff;
  }

  label span {
    white-space: nowrap;
    width: 160px;
    height: 30px;
    border: 1px solid #cfd7df;
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
  }

  label span:hover {
    background-color: #d6d6e5;
  }

  label span:before {
    border: 1px solid #cfd7df;
    display: flex;
    flex-shrink: 0;
    content: "";
    background-color: #fff;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin-right: 0.375em;
    transition: 0.25s ease;
  }
`;