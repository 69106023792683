import apiClient from "../../../utilities/apiClient";

export const get_AdminLots = (payload) => {
  return apiClient.get(`${apiClient.Urls.admin_lots_list}`, payload, true);
};

export const view_LotDetails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.view_admin_lot_details}/${payload}`,
    "",
    true
  );
};

export const approve_asn = (payload) => {
  // console.log(apiClient.Urls.asn_approve);
  // console.log(payload);
  return apiClient.post(
    `${apiClient.Urls.asn_approve}/${payload.asn_id}`,
    payload,
    true
  );
};